import { createAction } from '@reduxjs/toolkit'
import { GroupAttributes, GroupRedux } from './types'

export const updateGroup = createAction<GroupRedux>('group/updateGroup')
export const addGroups = createAction<GroupRedux>('group/addGroups')
export const updateGroupWallets = createAction<{
  walletId: string
  group: GroupAttributes
}>('group/updateGroupsWallets')
export const updateSelectedGroup = createAction<string>(
  'group/updateSelectedGroup',
)
export const updateLoadingGroups = createAction<boolean>(
  'group/updateLoadingGroups',
)
export const clearWalletIds = createAction<string>('group/clearWalletIds')
