export const fieldList = [
  {
    name: 'date',
    description: 'Shows the date and time the transaction was confirmed',
  },
  {
    name: 'reference',
    description: 'Can be used to uniquely identify a transaction',
  },
  {
    name: 'purpose',
    description: 'Adds custom context to a transaction',
  },
  {
    name: 'txHash',
    description: 'Shows the transaction hash linked to a block explorer',
  },
  {
    name: 'address',
    description: 'Shows the address that took part in the transaction',
  },
  {
    name: 'type',
    description: 'Classifies the type of transaction',
  },
  {
    name: 'credit',
    description: 'Shows a credit accounting line for the transaction',
  },
  {
    name: 'debit',
    description: 'Shows a debit accounting line for the transaction',
  },
  {
    name: 'balance',
    description:
      'Shows the wallet balance at the point the transaction was confirmed.',
  },
]
