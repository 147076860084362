import { createContext, useState, useContext } from 'react'

// An array of switchable features (i.e ['basic_wallet']).
// Enforcing this on the type interface to ensure strict typing and single source of truth
export type SwitchableFeatures = [] // active in DEV and PROD
export type SwitchableDevFeatures = [] // active only in DEV

type Props = {
  children: any
  enabledFeatures: string[]
}

type FeatureToggleContextType = [string[], (enabledFeatures: string[]) => void]
const defaultValue: FeatureToggleContextType = [[], () => {}]

const FeatureToggleContext =
  createContext<FeatureToggleContextType>(defaultValue)

export const FeatureToggle = ({ children, enabledFeatures }: Props) => {
  const enabledFeaturesData = useState(enabledFeatures)
  return (
    <FeatureToggleContext.Provider value={enabledFeaturesData}>
      {children}
    </FeatureToggleContext.Provider>
  )
}

export const useFeatureToggle = () => {
  const [enabledFeatures, setEnabledFeatures] = useContext(FeatureToggleContext)

  const isFeatureEnabled = (featureName: string) => {
    return enabledFeatures.includes(featureName)
  }

  const addEnabledFeature = (featureName: string) => {
    const newEnabledFeatures = enabledFeatures
    newEnabledFeatures.push(featureName)
    setEnabledFeatures(newEnabledFeatures)
  }
  return [isFeatureEnabled, addEnabledFeature] as [
    (featureName: string) => boolean,
    () => void,
  ]
}
