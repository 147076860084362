import { useCallback, useMemo } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  fetchRecentTransactions,
  fetchTransactionHistoryCSV,
} from '../../services/GringottsClient'
import { AppState } from '..'
import {
  addTransactions,
  setLoadingTransactions,
  updateFilters,
  updateSelectedAsset,
  updateVisibleColumns,
} from './actions'
import { useGetAccessToken } from '../user/hooks'
import useToast from '../../services/toast'
import FileDownload from 'js-file-download'
import {
  addDayToDate,
  assertType,
  isTimeDifferenceInMinutesGreaterThan,
} from '../../utils'
import { AccountingHistoryRedux } from './types'
import { AccountingAttributes } from '@bitaccess/gringotts-common'
import { useExtendAssetArray } from '../asset/hooks'
import { useSelectSelectedGroup } from '../group/hooks'

const defaultArray: any[] = []

export function useGetRecentTransactions(): ({
  walletId,
  params,
}: {
  walletId: string
  params: {
    fromDate: string
    toDate: string
    page?: number
    limit?: number
    networkType?: string
    purpose?: string
    address?: string
    txHash?: string
    reference?: string
    type?: string
  }
  group?: string
}) => Promise<void> {
  const getAccessToken = useGetAccessToken()
  const selectedGroup = useSelectSelectedGroup()
  const dispatch = useDispatch()
  const toast = useToast()
  return useCallback(
    async ({
      walletId,
      params,
    }: {
      walletId: string
      params: {
        fromDate: string
        toDate: string
        page?: number
        limit?: number
        networkType?: string
        purpose?: string
        address?: string
        txHash?: string
        reference?: string
        type?: string
      }
      group?: string
    }) => {
      if (!walletId && !selectedGroup) return
      dispatch(setLoadingTransactions(true))
      const accessToken = await getAccessToken()
      try {
        const { result, count, total } = await fetchRecentTransactions(
          accessToken,
          walletId,
          {
            params: {
              ...params,
              fromDate: params.fromDate ? new Date(params.fromDate) : undefined,
              toDate: params.toDate
                ? addDayToDate(params.toDate, 1)
                : undefined,
            },
          },
          selectedGroup,
        )
        const typedResult = result.map((tx) =>
          assertType(AccountingAttributes, tx),
        )
        batch(() => {
          const allTxs: AccountingHistoryRedux = {}
          typedResult.forEach((tx) => {
            allTxs[tx.id] = {
              ...tx,
              page: params.page,
              walletId,
            }
          })
          dispatch(
            addTransactions({
              allTxs,
              page: params.page ?? 1,
              walletId,
              count,
              total,
              limit: params.limit ?? 50,
            }),
          )
          dispatch(setLoadingTransactions(false))
        })
      } catch (err) {
        console.log('err', err)
        toast('error', 'Unable to load transaction history.')
        dispatch(setLoadingTransactions(false))
      }
    },
    [dispatch, getAccessToken, toast, selectedGroup],
  )
}

export function useShouldUpdateTransactions(): (
  walletId: string,
  page: number,
  minutes: number,
) => boolean {
  const lastUpdated = useSelectLastUpdated()
  return useCallback(
    (walletId: string, page: number, minutes: number) => {
      const ts = lastUpdated[`${walletId}${page}`]
      if (!ts) return true
      return isTimeDifferenceInMinutesGreaterThan(ts, minutes)
    },
    [lastUpdated],
  )
}

export function useUpdateTableFilters(): (filters: {
  [label: string]: any
}) => void {
  const dispatch = useDispatch()
  return useCallback(
    (filters: { [label: string]: any }) => {
      dispatch(updateFilters(filters))
    },
    [dispatch],
  )
}

export function useGetTransactionCSV(): ({
  walletId,
  fromDate,
  toDate,
}: {
  walletId: string
  fromDate: Date
  toDate: Date
}) => Promise<void> {
  const getAccessToken = useGetAccessToken()
  const selectedGroup = useSelectSelectedGroup()
  const toast = useToast()
  return useCallback(
    async ({
      walletId,
      fromDate,
      toDate,
    }: {
      walletId: string
      fromDate: Date
      toDate: Date
    }) => {
      if (!selectedGroup) return
      const accessToken = await getAccessToken()
      try {
        const csv = await fetchTransactionHistoryCSV(
          accessToken,
          walletId,
          {
            params: {
              fromDate,
              toDate,
            },
          },
          selectedGroup,
        )
        FileDownload(
          csv,
          `wallet:${walletId}:${fromDate}-${toDate}-tx-history.csv`,
        )
      } catch (err: any) {
        console.log(err)
        toast(
          'error',
          'Unable to the download csv file. Please make sure the date range is no longer than 31 days.',
        )
      }
    },
    [getAccessToken, toast, selectedGroup],
  )
}

export function useSelectIsLoadingTransactionHistory(): boolean {
  const isLoading = useSelector(
    (state: AppState) => state.transaction.isLoading,
  )
  return isLoading
}

export function useSelectAccountingFilters(): any {
  const filters = useSelector((state: AppState) => state.transaction.filters)
  return filters
}

export function useSelectTxById(txId: string): AccountingAttributes {
  const tx = useSelector(
    (state: AppState) => state.transaction.recentTransactions[txId],
  )
  return tx
}

export function useSelectTransactionHistoryVisibleColumns(): string[] {
  const defaultColumns = useSelector(
    (state: AppState) => state.transaction.visibleColumns,
  )
  return defaultColumns
}

export function useUpdateVisibleColumns(): (columns: string[]) => void {
  const dispatch = useDispatch()
  return useCallback(
    (columns: string[]) => {
      dispatch(updateVisibleColumns(columns))
    },
    [dispatch],
  )
}

export function useUpdateSelectedAsset(): (asset: any) => void {
  const dispatch = useDispatch()
  return useCallback(
    (asset) => {
      if (!asset) return
      dispatch(updateSelectedAsset(asset))
    },
    [dispatch],
  )
}

export function useSelectLastUpdated(): {
  [pageNumber: string]: number | undefined
} {
  const lastUpdated = useSelector(
    (state: AppState) => state.transaction.lastUpdated,
  )
  return lastUpdated
}

export function useSelectSelectedAsset(): any {
  const selectedAsset = useSelector(
    (state: AppState) => state.transaction.selectedAsset,
  )
  return selectedAsset
}

export function useSelectRecentTransactions(): AccountingHistoryRedux {
  const recentTransactions = useSelector(
    (state: AppState) => state.transaction.recentTransactions,
    shallowEqual,
  )
  return recentTransactions
}

export function useSelectRecentTransactionPagination(): {
  total: number
  count: number
  limit: number
} {
  const total = useSelector((state: AppState) => state.transaction.total)
  const count = useSelector((state: AppState) => state.transaction.count)
  const limit = useSelector((state: AppState) => state.transaction.limit)
  return { total, count, limit }
}

export function useSelectRecentTransactionsArray(): AccountingAttributes[] {
  const recentTransactions = useSelectRecentTransactions()
  return useMemo(
    () =>
      Object.values(recentTransactions).sort(
        (a: any, b: any) => parseInt(b?.sortIndex) - parseInt(a?.sortIndex),
      ) || defaultArray,
    [recentTransactions],
  )
}

export function useSelectRecentTransactionsByPage(
  walletId: string,
  page: number,
): AccountingAttributes[] {
  const recentTransactions = useSelectRecentTransactionsArray()
  const extendAssetArray = useExtendAssetArray()
  return useMemo(
    () =>
      extendAssetArray(
        recentTransactions.filter(
          (tx: any) => tx?.walletId === walletId && tx?.page === page,
        ),
        'assetId',
      ),
    [page, recentTransactions, walletId],
  )
}

export function useSelectRecentTransactionData(
  walletId: string,
  page: number,
): {
  txs: AccountingAttributes[]
  total: number
  limit: number
  count: number
} {
  const txs = useSelectRecentTransactionsByPage(walletId, page)
  const pagination = useSelectRecentTransactionPagination()
  return { txs, ...pagination }
}
