import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { batch, shallowEqual, useSelector } from 'react-redux'
import { addBalances, setLoadingBalances } from './actions'
import { useGetAccessToken } from '../user/hooks'
import { useGetSelectedGroupWalletIds } from '../group/hooks'
import { fetchBalancesByGroup } from '../../services/GringottsClient'
import { AppState, useThunkDispatch } from '../index'
import { useExtendAssetArray } from '../asset/hooks'
import { toBigNumber } from '../../utils/BigNumber'
import { isTimeDifferenceInMinutesGreaterThan } from '../../utils'
import { AccountingBalance, AccountingBalanceRedux } from './types'
import { useSelectNetwork } from '../application/hooks'

const defaultArray: any[] = []

export function useGetWalletBalanceByGroup(): (group: string) => Promise<void> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useThunkDispatch()
  const selectedNetwork = useSelectNetwork()
  return useCallback(
    async (group: string) => {
      try {
        dispatch(setLoadingBalances(true))
        const accessToken = await getAccessToken()
        const balances = await fetchBalancesByGroup(accessToken, group)
        batch(async () => {
          const allBalances: any = {}
          balances.forEach((balance: any) => {
            allBalances[balance.walletId] = balance
          })
          dispatch(addBalances(allBalances))
        })
        dispatch(setLoadingBalances(false))
      } catch (err) {
        dispatch(setLoadingBalances(false))
        console.log('error fetching balances', err)
      }
    },
    [dispatch, getAccessToken, selectedNetwork],
  )
}

export function useSelectBalances(): AccountingBalanceRedux {
  const balances = useSelector<AppState, AppState['balance']['balances']>(
    (state) => state.balance.balances,
    shallowEqual,
  )
  return balances
}

export function useSelectBalanceById(walletId: string): AccountingBalance {
  const balances = useSelectBalances()
  return balances[walletId] ?? {}
}

export function useSelectBalancesLastUpdated(): number | undefined {
  const lastUpdated = useSelector<AppState, AppState['balance']['lastUpdated']>(
    (state) => state.balance.lastUpdated,
  )
  return lastUpdated
}

export function useShouldUpdateBalance(): (minutes: number) => boolean {
  const lastUpdated = useSelectBalancesLastUpdated()
  return useCallback(
    (minutes: number) => {
      if (!lastUpdated) return true
      return isTimeDifferenceInMinutesGreaterThan(lastUpdated, minutes)
    },
    [lastUpdated],
  )
}

export function useSelectIsLoadingBalances(): boolean {
  const isLoading = useSelector<AppState, AppState['balance']['isLoading']>(
    (state) => state.balance.isLoading,
  )
  return isLoading
}

export function useSelectPrimaryAddressOfWallet(walletId: string): string {
  const balances = useSelectBalances()
  return balances[walletId]?.primaryAddress || ''
}

export function useSelectBalancesArray(): AccountingBalance[] {
  const balances = useSelectBalances()
  return Object.values(balances) || defaultArray
}

export function useSelectBalancesByGroup(): AccountingBalanceRedux {
  const balances = useSelectBalances()
  const selectedGroupWalletIds = useGetSelectedGroupWalletIds()
  return _.pick(balances, selectedGroupWalletIds)
}

export function useCombineBalances(): (balance: any) => AccountingBalance {
  return useCallback((balance: any) => {
    const totalBalance = toBigNumber(balance?.confirmedBalance)
      .plus(balance?.unconfirmedBalance)
      .toNumber()
    return {
      totalBalance,
      ...balance,
    }
  }, [])
}

export function useSelectBalancesByGroupArray(): AccountingBalance[] {
  const balancesByGroup = useSelectBalancesByGroup()
  const extendBalancesArray = useExtendAssetArray()
  return useMemo(
    () =>
      extendBalancesArray(
        Object.values(balancesByGroup).sort((a: any, b: any) => {
          const c = toBigNumber(b.confirmedBalance).plus(b.unconfirmedBalance)
          const d = toBigNumber(a.confirmedBalance).plus(a.unconfirmedBalance)
          return c.minus(d).toNumber()
        }),
        'assetSymbol',
      ),
    [extendBalancesArray, balancesByGroup],
  )
}
