import { useCallback, useMemo } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { fetchSherlockReports } from '../../services/GringottsClient'
import useToast from '../../services/toast'
import { renameKeys } from '../../utils'
import { AppState } from '../index'
import { useGetAccessToken } from '../user/hooks'
import { addLogs, loadingActivity } from './actions'
import { useSelectSelectedGroup } from '../group/hooks'

const ACTIVITY_USER_REPLACEMENTS: { [user: string]: string } = {
  'gringotts-signer': 'bitaccess-cosigner',
  quattro: 'bitaccess-btm',
}

const ACTIVITY_TYPE_REPLACEMENTS: { [type: string]: string } = {
  'Submit signed tx': 'Sign transaction',
}

export function useGetSherlockReports(): ({
  walletId,
  query,
  page,
  limit,
}: {
  walletId: string
  query: { jurisdiction: string }
  page?: number
  limit?: number
}) => Promise<any> {
  const getAccessToken = useGetAccessToken()
  const selectedGroup = useSelectSelectedGroup()
  const toast = useToast()
  const dispatch = useDispatch()
  return useCallback(
    async ({
      walletId,
      query,
      page = 1,
      limit = 50,
    }: {
      walletId: string
      query: { jurisdiction: string }
      page?: number
      limit?: number
    }) => {
      if (!selectedGroup) return
      dispatch(loadingActivity(true))
      const accessToken = await getAccessToken()
      try {
        const res = await fetchSherlockReports(
          accessToken,
          query,
          page,
          limit,
          selectedGroup,
        )
        const logs: any = {}
        res.docs.forEach((doc) => {
          const logWithRenamedKeys: any = renameKeys(
            {
              deed: 'type',
              suspect: 'user',
              target: 'identifier',
              weapon: 'interface',
              evidence: 'request',
              motive: 'reasoning',
              scene: 'actionType',
              jurisdiction: 'target',
            },
            doc,
          )
          logWithRenamedKeys.user =
            ACTIVITY_USER_REPLACEMENTS[logWithRenamedKeys.user] ||
            logWithRenamedKeys.user
          logWithRenamedKeys.type =
            ACTIVITY_TYPE_REPLACEMENTS[logWithRenamedKeys.type] ||
            logWithRenamedKeys.type
          logs[doc.id] = {
            ...logWithRenamedKeys,
            page,
            walletId,
          }
        })
        dispatch(
          addLogs({
            walletId,
            logs,
            page,
            limit,
            total: res.total,
          }),
        )
        dispatch(loadingActivity(false))
        return res
      } catch (err: any) {
        console.log(err)
        dispatch(loadingActivity(false))
        toast('error', `Unable to fetch activity log. Please try again.`)
      }
    },
    [getAccessToken, toast, dispatch],
  )
}

export function useSelectLogs(): any {
  const logs = useSelector<AppState, AppState['activity']['logs']>(
    (state) => state.activity.logs,
  )
  return logs
}

export function useSelectLogById(id: string): any {
  const logs = useSelectLogs()
  return logs[id]
}

export function useSelectIsLoadingLogs(): any {
  const isLoading = useSelector<AppState, AppState['activity']['isLoading']>(
    (state) => state.activity.isLoading,
  )
  return isLoading
}

export function useSelectActivityPagination(): {
  total: number
  limit: number
} {
  const total = useSelector((state: AppState) => state.activity.total)
  const limit = useSelector((state: AppState) => state.activity.limit)
  return { total, limit }
}

export function useSelectActivityLogArray(): any[] {
  const logs = useSelectLogs()
  return Object.values(logs) || []
}

export function useSelectLogsByPage(walletId: string, page: number): any[] {
  const logs = useSelectActivityLogArray()
  return useMemo(
    () =>
      logs.filter(
        (log: any) => log?.walletId === walletId && log?.page === page,
      ),
    [page, logs, walletId],
  )
}

export function useSelectRecentTransactionData(
  walletId: string,
  page: number,
): {
  logs: any[]
  total: number
  limit: number
  count: number
} {
  const logs = useSelectLogsByPage(walletId, page)
  const pagination = useSelectActivityPagination()
  return { logs, ...pagination, count: logs.length }
}
