import { CopyText, RowBetween } from "@bitaccess/bitaccess-ui"

interface Rows {
  label: string
  data?: any
}

interface DataRow extends Rows {
  index: number
}

export default function DataRows({
  rows,
  canCopy = true,
}: {
  rows: Rows[]
  canCopy?: boolean
}) {
  const DataRow = ({ label, data, index }: DataRow) => {
    return (
      <RowBetween
        className={`${
          index !== rows.length - 1 && 'border-b'
        } mt-1 pb-1 max-w-full flex text-left flex-wrap border-gray-100 ml-2 border-r-8 border-r-white`}
      >
        <div className="text-gray-900 font-semibold">{label}</div>
        <div className="md:w-9/12 w-screen">
          {canCopy ? (
            <CopyText toCopy={data} id="copy-data-row">
              {data ?? '-'}
            </CopyText>
          ) : (
            data
          )}
        </div>
      </RowBetween>
    )
  }
  return (
    <>
      {rows.map(({ label, data }, index) => (
        <DataRow
          key={`${label}-${index}`}
          index={index}
          label={label}
          data={data}
        />
      ))}
    </>
  )
}
