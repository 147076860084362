import {
  AvailableBalanceResult,
  WalletAvailableBalanceResult,
} from '@bitaccess/gringotts-common'
import * as t from 'io-ts'

export const Address = t.intersection([
  AvailableBalanceResult,
  t.interface({
    address: t.string,
  }),
])
export type Address = t.TypeOf<typeof Address>

export const BalanceBreakdownAddress = t.record(t.string, Address)
export type BalanceBreakdownAddress = t.TypeOf<typeof BalanceBreakdownAddress>

export interface BalanceBreakdownResult
  extends Omit<WalletAvailableBalanceResult, 'addresses'> {
  addresses: BalanceBreakdownAddress
}

export const BalanceBreakdownRedux = t.record(t.string, BalanceBreakdownAddress)
export type BalanceBreakdownRedux = t.TypeOf<typeof BalanceBreakdownRedux>
