import { Suspense, useEffect, useRef } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import LoadingFullscreen from '../components/LoadingFullscreen'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AuthenticatedRoute from '../auth/AuthenticatedRoute'
import { useGetAppData, useIsAppLoadingData } from '../state/application/hooks'
// pages
import TransactionHistory from './TransactionHistory'
import AuthLoading from './AuthLoading'
import Loading from './Loading'
import Wallets from './Wallets'
// import Layout from '../components/Layout'
import { useSelectSelectedGroup } from '../state/group/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import WalletDetail from './WalletDetail'
import Four0Four from './404'
import TransactionDetailModal from './TransactionDetailModal'
import AuthenticatedRenderRoute from '../auth/AuthenticatedRenderRoute'
import ActivityDetailModal from './ActivityDetailModal'
import NewWallet from './NewWallet'
import Logout from '../components/Logout'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: calc(100vh - 56px);
  position: relative;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 10;

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const ROUTES_TO_SKIP_LOAD = ['/logout']

export default function App() {
  const getAppData = useGetAppData()
  const isLoading = useIsAppLoadingData()
  const hasRendered = useRef(false)
  const history = useHistory()
  const selectedGroup = useSelectSelectedGroup()
  const { pathname, search } = useLocation()
  const {
    isLoading: isAuth0Loading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0()

  useEffect(() => {
    const handleGetAppData = async () => {
      await getAppData()
    }
    if (!isAuth0Loading && !isAuthenticated) {
      loginWithRedirect()
    }
    if (!hasRendered.current && isAuthenticated && !isAuth0Loading) {
      handleGetAppData()
      hasRendered.current = true
    }
  }, [isAuthenticated, isAuth0Loading])

  return !ROUTES_TO_SKIP_LOAD.includes(history.location.pathname) &&
    (isLoading || !hasRendered.current) ? (
    <LoadingFullscreen />
  ) : (
    <>
      <Header />
      <Suspense fallback={null}>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <Route path="/auth/callback" component={AuthLoading} />
        <AppWrapper>
          <>
            <BodyWrapper>
              <Switch>
                <Redirect
                  exact
                  strict
                  path="/"
                  to={`/wallets/${selectedGroup}`}
                />
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                {/* <AuthenticatedRoute exact strict path="/" component={Home} /> */}
                <AuthenticatedRoute
                  strict
                  exact
                  path="/transactions/:groupId?"
                  component={TransactionHistory}
                />
                <AuthenticatedRoute path="/logout" component={Logout} />
                <AuthenticatedRoute
                  exact
                  strict
                  path="/wallets/add/:tab?"
                  component={(props: any) => {
                    return <NewWallet {...props} />
                  }}
                />
                <AuthenticatedRenderRoute
                  path="/wallets/:groupId/:walletId/:tab?"
                  render={(props: any) => {
                    const { tab = 'transactions' } = props.match.params
                    return <WalletDetail {...props} key={`${tab}-${search}`} />
                  }}
                />
                <AuthenticatedRoute
                  exact
                  strict
                  path="/wallets/:groupId?"
                  component={Wallets}
                />
                <Route path="*" component={Four0Four} />
              </Switch>
              <AuthenticatedRoute
                strict
                exact
                path="/wallets/:groupId/:walletId/transactions/:txId"
                component={(props: any) => {
                  const { groupId, walletId } = props.match.params
                  return (
                    <TransactionDetailModal
                      {...props}
                      parentRoute={`/wallets/${groupId}/${walletId}/transactions${search}`}
                    />
                  )
                }}
              />
              <AuthenticatedRoute
                strict
                exact
                path="/wallets/:groupId/:walletId/activity/:id"
                component={(props: any) => {
                  const { groupId, walletId } = props.match.params
                  return (
                    <ActivityDetailModal
                      {...props}
                      parentRoute={`/wallets/${groupId}/${walletId}/activity${search}`}
                    />
                  )
                }}
              />
              <Marginer />
            </BodyWrapper>
          </>
        </AppWrapper>
      </Suspense>
    </>
  )
}
