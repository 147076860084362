/* eslint-disable @typescript-eslint/no-empty-function */
import { SearchIcon } from '@heroicons/react/outline'
import { ChangeEvent, useRef, useState } from 'react'
import TableUI from '../TableUI'
import { Pagination, TextField } from "@bitaccess/bitaccess-ui"

export default function Table({
  id,
  columns,
  data,
  showSearch = true,
  onChangePage,
  currentPage,
  totalItems,
  itemsPerPage,
  tableHeader,
  isLoading,
  canSelect = false,
  pagination = true,
  searchPlaceholder,
  hidePaginationTotalsInfo,
  rightTableHeader,
  lastThComponent,
}: {
  id: string
  columns: any[]
  data: Array<any[]>
  onSearch?: any
  tableHeader?: React.ReactNode
  showSearch?: boolean
  onChangePage?: any
  currentPage?: number
  totalItems?: number
  itemsPerPage?: number
  canSelect?: boolean
  pagination?: boolean
  searchPlaceholder?: string
  isLoading: boolean
  hidePaginationTotalsInfo?: boolean
  rightTableHeader?: React.ReactNode
  lastThComponent?: React.ReactNode
}) {
  const tableInstance = useRef<any>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchQuery(value)
    tableInstance?.current?.setGlobalFilter(value)
  }
  return (
    <div className="max-w-full w-full">
      {(tableHeader || showSearch) && (
        <div className="pb-2.5 pt-2.5 px-3 lg:flex lg:items-center lg:justify-between bg-gray-50 bg-opacity-40 border">
          <div className="sm:mr-4">
            <div className="flex flex-wrap-reverse gap-3.5">{tableHeader}</div>
          </div>
          {rightTableHeader && <>{rightTableHeader}</>}
          {showSearch && (
            <div
              style={{ minWidth: 210 }}
              className="relative z-0 inline-flex items-center"
            >
              <div className="relative inline-block text-left w-full">
                <div className="w-full mt-2 lg:mt-0">
                  <TextField
                    onEnter={() => {}}
                    value={searchQuery}
                    id="searchBar"
                    onChange={handleSearch}
                    placeHolder={searchPlaceholder}
                    Icon={<SearchIcon />}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`${
          !tableHeader && !showSearch ? 'border-t' : ''
        } border-r border-l border-b overflow-x-scroll`}
      >
        <TableUI
          id={'recentTransactionTable'}
          isLoading={isLoading}
          columns={columns}
          lastThComponent={lastThComponent}
          data={data}
          useRowSelect={canSelect}
          ref={tableInstance}
        />
      </div>
      {pagination && currentPage && !isLoading && (
        <Pagination
          id={id}
          totalItems={totalItems || 0}
          itemsPerPage={itemsPerPage || 0}
          currentPage={currentPage || 0}
          onChangePage={onChangePage}
          hideTotalInfo={hidePaginationTotalsInfo}
          expandedLayout
        />
      )}
    </div>
  )
}
