import { Switch } from '@headlessui/react'
import {
  useSelectHideWalletBalance,
  useUpdateHideBalances,
} from '../../state/wallet/hooks'
import classNames from 'class-names'

export default function HideBalanceSwtich() {
  const updateHideBalances = useUpdateHideBalances()
  const hideWalletBalance = useSelectHideWalletBalance()

  const handleSwitch = (value: boolean) => {
    updateHideBalances(value)
  }

  return (
    <div>
      <Switch
        checked={hideWalletBalance}
        onChange={handleSwitch}
        className="flex-shrink-0 align-middle group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute bg-white w-full h-full"
        />
        <span
          aria-hidden="true"
          className={classNames(
            hideWalletBalance ? 'bg-green-400' : 'bg-gray-200',
            'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            hideWalletBalance ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          )}
        />
      </Switch>
      <div
        onClick={() => handleSwitch(!hideWalletBalance)}
        className="text-gray-500 cursor-pointer ml-1.5 inline-block align-middle font-medium text-xs"
      >
        Hide Balances
      </div>
    </div>
  )
}
