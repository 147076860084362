import { useEffect } from 'react'
import PageLayout from '../../components/PageLayout'
import {
  useGetWalletBalanceByGroup,
  useSelectBalancesByGroupArray,
} from '../../state/balance/hooks'
import {
  useSelectGroupIds,
  useSelectSelectedGroup,
  useUpdateUserSelectedGroup,
  useIsPersonalSelectedGroup,
} from '../../state/group/hooks'
import { useHistory, useParams } from 'react-router'
import WalletsTable from '../../components/WalletsTable'
import WalletEmpty from '../../components/WalletEmpty'

export default function Wallets() {
  const balances = useSelectBalancesByGroupArray()
  const getBalancesByGroup = useGetWalletBalanceByGroup()
  const selectedGroup = useSelectSelectedGroup()
  const isPersonalGroup = useIsPersonalSelectedGroup()
  const history = useHistory()

  const { groupId } = useParams<{ groupId: string }>()
  const groupIds = useSelectGroupIds()
  const updateSelectedGroup = useUpdateUserSelectedGroup()

  useEffect(() => {
    if (!selectedGroup) return
    const handleGetBalances = async () => {
      await getBalancesByGroup(selectedGroup)
    }
    handleGetBalances()
  }, [selectedGroup])

  useEffect(() => {
    if (groupId !== selectedGroup && groupId && groupIds?.includes(groupId)) {
      updateSelectedGroup(groupId)
    }
  }, [])

  useEffect(() => {
    if (
      !isPersonalGroup &&
      ((!groupId && selectedGroup) ||
        (!groupIds?.includes(groupId) && selectedGroup))
    ) {
      history.replace({
        pathname: `/wallets/${selectedGroup}`,
        search: history.location.search,
      })
    }
  }, [groupId, groupIds, selectedGroup])

  return (
    <>
      {selectedGroup || isPersonalGroup ? (
        <PageLayout pageTitle="Wallets" headingBottomMargin={8}>
          <WalletsTable balances={balances} />
        </PageLayout>
      ) : (
        <PageLayout pageTitle="">
          <WalletEmpty />
        </PageLayout>
      )}
    </>
  )
}
