import { useState, useEffect, useCallback } from 'react'
import { Button } from '@bitaccess/bitaccess-ui'
import Modal from '../../components/Modal'
import { InputGroup } from '@bitaccess/bitaccess-ui'

export default function PolicyAddApprovedAdminModal({
  isOpen,
  setOpen,
}: {
  isOpen: boolean
  setOpen: any
}) {
  const [inviteButtonDisabled, setInviteButtonDisabled] =
    useState<boolean>(true)
  const [email, setEmail] = useState<string>('')

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  useEffect(() => {
    const isEmailValid = validateEmail(email)
    setInviteButtonDisabled(!isEmailValid)
  }, [email])

  const handleAddApprovedAdmin = () => {
    console.log('add admin')
  }

  const handleAddAdminClose = () => {
    console.log('closed')
  }

  const handleOnChangeEmail = useCallback((e: any) => {
    const value = e.target.value
    setEmail(value)
  }, [])

  const AddAdminModalBody = () => {
    return (
      <div>
        <div>
          <p className="my-8 ml-3">Add email below to register an approved admin.</p>
          <InputGroup
            key="approved-admin-email-input"
            id="approved-admin-email-input"
            placeHolder="Enter Email"
            onEnter={() => void 0}
            value={email}
            label={'Email'}
            inputStyles={`rounded mt-2 w-60`}
            onChange={handleOnChangeEmail}
            autoFocus
          />
          <Button
            className="block mt-5 ml-3 h-8 font-medium bg-bitaccess-orange border-0 rounded-none hover:bg-bitaccess-orange-dark"
            id="add-approved-admin-button"
            text={'Invite'}
            type="secondary"
            onClick={() => handleAddApprovedAdmin()}
            disabled={inviteButtonDisabled}
          />
        </div>
      </div>
    )
  }
  return (
    <Modal
      id={'add-admin-modal'}
      title={'Add Admin'}
      modalClassName="max-w-screen-2xl md:w-1/2 w-screen h-10/12 m-0"
      body={
        <div style={{ maxHeight: 450 }} className="mt-8">
          <AddAdminModalBody />
        </div>
      }
      scrollBody={false}
      showCloseButton={true}
      footer={<></>}
      open={isOpen}
      setOpen={setOpen}
      onClose={handleAddAdminClose}
    />
  )
}
