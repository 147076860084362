import React from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import './i18n'
import App from './pages/App'
import store from './state'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ApplicationUpdater from './state/application/updater'
import { ToastContainer } from 'react-toast'
import reportWebVitals from './reportWebVitals'
import { QueryParamProvider } from 'use-query-params'
import './index.css'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import PermissionProvider from './contexts/Permissions'
import {
  FeatureToggle,
  SwitchableFeatures,
  SwitchableDevFeatures,
} from './contexts/FeatureToggle'
import '@bitaccess/bitaccess-ui/dist/index.css'

const GOOGLE_ANALYTICS_ID: string | undefined =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
    anonymizeIp: true,
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', (error) => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true,
  })
})

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
    </>
  )
}

function getFeaturesToEnable() {
  const features: SwitchableFeatures = []
  const devFeatures: SwitchableDevFeatures = []
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV !== 'production'
    ? [...devFeatures, ...features]
    : features
}

const featuresToEnable = getFeaturesToEnable()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Auth0ProviderWithHistory>
          <div style={{ position: 'relative', zIndex: 9999999 }}>
            <ToastContainer position="bottom-center" delay={4000} />
          </div>
          <PermissionProvider>
            <Provider store={store}>
              <Updaters />
              <FeatureToggle enabledFeatures={featuresToEnable}>
                <App />
              </FeatureToggle>
            </Provider>
          </PermissionProvider>
        </Auth0ProviderWithHistory>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorkerRegistration.unregister()
