/* eslint-disable react/display-name */
import { useMemo } from 'react'
import Table from '../../components/Table'
import { CopyText } from '@bitaccess/bitaccess-ui'
import tw from 'twin.macro'

export const DestinationLabel = tw.label`
  block 
  text-xs 
  font-semibold 
  text-gray-800
`

export default function PolicyWhitelistTable({
  whitelistedDestinations,
  isLoading,
}: {
  whitelistedDestinations: string[]
  isLoading: boolean
}) {
  const handleCreateTableRows: any = useMemo((): any => {
    if (isLoading) return []
    return (
      whitelistedDestinations &&
      whitelistedDestinations.map((address: any) => {
        return {
          destination: address.value,
          label: '',
        }
      })
    )
  }, [whitelistedDestinations, isLoading])

  return (
    <Table
      id="whitelist-table"
      columns={[
        {
          Header: 'DESTINATION',
          minWidth: 240,
          accessor: 'destination',
          Cell: (e: any) => (
            <div>
              <CopyText
                id={`destination-${e.value.toString()}`}
                toCopy={e.value.toString()}
              >
                {e.value.toString()}
              </CopyText>
              <DestinationLabel>{e.cell.row.original?.label}</DestinationLabel>
            </div>
          ),
        },
      ]}
      data={handleCreateTableRows}
      searchPlaceholder="Filter destination..."
      isLoading={isLoading}
      pagination={false}
    />
  )
}
