import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { addReport, loadingReports } from './actions'

export interface ReportState {
  reports: {
    [id: string]: any
  }
  isLoading: boolean
}

const initialState: ReportState = {
  reports: {},
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addReport, (state, action) => {
      const report = action.payload
      const newState = set(
        state,
        `reports`,
        {
          ...state.reports,
          [report.id]: {
            ...report,
          },
        },
        {
          safe: true,
        },
      )
      return newState
    })
    .addCase(loadingReports, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    }),
)
