import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
  AUTH0_CALLBACK_URL,
} from '../constants'
import React from 'react'

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const history = useHistory()
  const onRedirectCallback = () => {
    history.push('/auth/callback')
  }
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN ?? ''}
      audience={AUTH0_AUDIENCE}
      clientId={AUTH0_CLIENT_ID ?? ''}
      redirectUri={AUTH0_CALLBACK_URL}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
