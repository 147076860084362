import { createReducer } from '@reduxjs/toolkit'
import { updateMatchesDarkMode, updateUserDarkMode } from './actions'

export interface UserState {
  userDarkMode: boolean | null // the user's choice for dark mode or light mode
  matchesDarkMode: boolean // whether the dark mode media query matches
}

export const initialState: UserState = {
  userDarkMode: null,
  matchesDarkMode: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode
    })
    .addCase(updateMatchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode
    }),
)
