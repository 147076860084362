/* eslint-disable react/display-name */
import { ComponentType } from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const AuthenticatedRoute = ({
  component,
  ...args
}: {
  component: ComponentType<any>
  [x: string]: any
}) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <div>Redirecting you to the login page.</div>,
      returnTo: '/wallets',
    })}
    {...args}
  />
)

export default AuthenticatedRoute
