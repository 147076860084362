import tw from 'twin.macro'

export const DropdownListOption = tw.div`
  py-1
  hover:bg-gray-100 
  hover:cursor-pointer
`

export const DropdownListGroupOption = tw.div`
  py-1
`

export const StyledOption = tw.div`
  text-sm 
  hover:text-gray-900
  hover:bg-gray-100 
  hover:cursor-pointer
  text-gray-700 
  transition-all
  block 
  px-4 
  py-1.5 
`

export const LabelWrapper = tw.div`
  flex 
  justify-between 
  pb-0
  mb-1
`

export const StyledLabel = tw.label`
  block 
  text-xs 
  font-semibold 
  text-gray-800
  -mb-1
`
