import PageLayout from '../../components/PageLayout'
import { Link } from 'react-router-dom'
export default function Four0Four() {
  return (
    <PageLayout pageTitle="">
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-bitaccess-orange sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-800 sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/"
                  className="inline-flex no-underline items-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-bitaccess-orange hover:bg-bitaccess-orange-dark focus:outline-none"
                >
                  Go back home
                </Link>
                <a
                  href="mailto:support@bitaccess.co"
                  className="inline-flex no-underline items-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                >
                  Contact support@bitaccess.co
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </PageLayout>
  )
}
