import { useSelectHideWalletBalance } from '../../state/wallet/hooks'
import { toPrecision } from '../../utils/BigNumber'

export default function BalanceAmount({
  amount,
  symbol = '',
  prefixSymbol = false,
  spaceSymbol = true,
  decimals = 8,
  ...rest
}: {
  amount: any
  symbol?: string
  spaceSymbol?: boolean
  prefixSymbol?: boolean
  decimals?: number
} & React.HTMLAttributes<HTMLDivElement>) {
  const hideBalance = useSelectHideWalletBalance()
  const space = spaceSymbol ? ' ' : ''
  const preciseAmount = toPrecision(amount, decimals)
  const text = prefixSymbol
    ? `${symbol}${space}${preciseAmount}`
    : `${preciseAmount}${space}${symbol}`
  return <span {...rest}>{hideBalance ? '********' : text}</span>
}
