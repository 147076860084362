import { Dropdown, DropdownType } from '../Dropdown'
import { Checkbox, Button } from "@bitaccess/bitaccess-ui"
import { FilterIcon } from '@heroicons/react/solid'
import { useState } from 'react'

export default function FilterDropdown({
  filters,
  onClear,
}: {
  onClear: () => void
  filters: {
    label: string
    component: any
    saveFunc: (checked: boolean) => void
    setInitialValue?: () => void
    defaultSelected?: boolean
  }[]
}) {
  const [selected, setSelected] = useState<string[]>(
    filters.filter((filter) => filter.defaultSelected).map((f) => f.label),
  )

  const handleCheckbox = (label: string, isSelected: boolean) => {
    let currentSelected = [...selected]
    if (isSelected) {
      currentSelected = currentSelected.filter((i) => i !== label)
    } else {
      currentSelected.push(label)
    }
    setSelected(currentSelected)
    const filterLabel = currentSelected[0] ? currentSelected[0] : null
    if (filterLabel) {
      const selectedFilter = filters.find(
        (filter) => filter.label === filterLabel,
      )
      if (selectedFilter?.setInitialValue) {
        selectedFilter.setInitialValue()
      }
    }
  }

  const handleSaveFilters = () => {
    const filtersWithStatus = filters.map((filter) => ({
      ...filter,
      checked: selected.includes(filter.label),
    }))
    filtersWithStatus.forEach((filter) => {
      filter.saveFunc(filter.checked)
    })
  }

  const handleClear = () => {
    setSelected([])
    onClear()
  }

  return (
    <Dropdown
      id="filter-dropdown"
      Icon={<FilterIcon className="w-4 h-4" />}
      text="Filters: "
      secondaryText={selected.length ? String(selected.length) : 'None'}
      extraStyles="items-center shadow-sm"
      dropdownType={DropdownType.DEFAULT}
    >
      <div className="bg-white">
        <div className="bg-gray-50 border-b py-2 px-3 flex flex-row items-center justify-between">
          <Button
            type="primary"
            id="clear-filters"
            text="Clear"
            onClick={handleClear}
          >
            Clear
          </Button>
          <span className="font-semibold text-gray-900">Filters</span>
          <Button
            id="filter-save"
            onClick={handleSaveFilters}
            type="secondary"
            text="Save"
          />
        </div>
        {filters.map((filter, i) => {
          const label = filter.label
          const checked = selected.includes(label)
          return (
            <div
              className="h-full no-underline block cursor-pointer select-none relative py-2 pl-4 pr-4 border-b border-gray-100 transition-[height] duration-300 ease-in-out"
              key={filter.label}
            >
              <div className="flex flex-row items-center ">
                <Checkbox
                  checked={checked}
                  onChange={() => handleCheckbox(label, checked)}
                  name={`table-settings-checkbox-${label}`}
                  id={`table-settings-checkbox-${label}`}
                />

                <label
                  htmlFor={`table-settings-checkbox-${label}`}
                  className={`${
                    checked ? 'text-yellow-800' : 'text-gray-700'
                  } font-semibold cursor-pointer`}
                >
                  {label}
                </label>
              </div>
              {checked && <div>{filter.component}</div>}
            </div>
          )
        })}
      </div>
    </Dropdown>
  )
}
