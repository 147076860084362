import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingFullScreen from '../../components/LoadingFullscreen'

export default function AuthLoading() {
  const history = useHistory()

  useEffect(() => {
    async function handleGetData() {
      if (!history.location.pathname.includes('/wallets')) {
        history.push('/wallets')
      }
    }
    handleGetData()
  }, [])

  return <LoadingFullScreen />
}
