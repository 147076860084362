import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { updateUserDarkMode } from './actions'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE } from '../../constants'
import useToast from '../../services/toast'

export function useIsDarkMode(): boolean {
  const { userDarkMode, matchesDarkMode } = useSelector<
    AppState,
    { userDarkMode: boolean | null; matchesDarkMode: boolean }
  >(
    ({ user: { matchesDarkMode, userDarkMode } }) => ({
      userDarkMode,
      matchesDarkMode,
    }),
    shallowEqual,
  )

  return userDarkMode === null ? matchesDarkMode : userDarkMode
}

export function useDarkModeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const darkMode = useIsDarkMode()

  const toggleSetDarkMode = useCallback(() => {
    dispatch(updateUserDarkMode({ userDarkMode: !darkMode }))
  }, [darkMode, dispatch])

  return [darkMode, toggleSetDarkMode]
}

export function useGetAccessToken(): () => Promise<any> {
  const { getAccessTokenSilently } = useAuth0()
  return useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
      })
      return accessToken
    } catch (err) {
      console.log('unauthenticated')
    }
  }, [getAccessTokenSilently])
}
