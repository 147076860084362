import tw, { styled } from 'twin.macro'

export const ModalContainer = tw.div`
  flex 
  items-end 
  justify-center 
  min-h-screen 
  pt-4 
  px-4 
  pb-20 
  text-center 
  sm:block 
  sm:p-0
`

export const CloseContainer = styled.div<{ showCloseButton: boolean }>`
  ${tw`
    hidden 
    sm:block 
    absolute 
    top-0 
    right-0 
    pt-4 
    pr-4
  `}
  ${({ showCloseButton }) => !showCloseButton && 'display: none !important;'}
`

export const OuterContainer = tw.div`
  inline-block 
  align-bottom 
  bg-white 
  px-4 
  pt-5 
  pb-4 
  text-left 
  overflow-hidden 
  shadow-xl
  transform 
  transition-all 
  sm:my-8 
  sm:align-middle 
  sm:w-5/12
  sm:p-6
  sm:pt-5
`

export const CloseButton = tw.button`
  bg-white 
  text-gray-400 
  hover:text-gray-500 
  focus:outline-none 
`

export const ContentContainer = tw.div``

export const BodyContainer = tw.div`
  sm:flex 
  sm:items-start
`

export const BodyContent = tw.div`
  my-3 
  text-center 
  sm:mt-0 
  sm:text-left
  w-full
`

export const BodySection = styled.div<{ scrollBody: boolean }>`
  ${tw`
  max-h-full
  overflow-auto
`}
  ${({ scrollBody }) => scrollBody && 'max-height: 20rem;'}
`
