import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { multipleSet } from '../../utils'
import { setLoadingBalances, addBalanceBreakdown } from './actions'
import { BalanceBreakdownRedux } from './types'

export interface BalanceBreakdownState {
  balances: BalanceBreakdownRedux
  isLoading: boolean
  lastUpdated: number | undefined
}

const initialState: BalanceBreakdownState = {
  balances: {},
  isLoading: false,
  lastUpdated: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addBalanceBreakdown, (state, action) => {
      const { balances, walletId } = action.payload
      const newState = multipleSet(
        [
          { field: `balances.${walletId}`, value: balances },
          { field: 'lastUpdated', value: Date.now() },
        ],
        state,
      )
      return newState
    })
    .addCase(setLoadingBalances, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    }),
)
