import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { multipleSet } from '../../utils'
import {
  addTransactions,
  setLoadingTransactions,
  updateSelectedAsset,
  updateVisibleColumns,
  updateFilters,
} from './actions'
import { AccountingHistoryRedux } from './types'

export interface TransactionState {
  recentTransactions: AccountingHistoryRedux
  total: number
  count: number
  limit: number
  isLoading: boolean
  selectedAsset: any
  filters: {
    [label: string]: any
  }
  lastUpdated: {
    [pageNumber: string]: number | undefined
  }
  visibleColumns: string[]
}

const initialState: TransactionState = {
  recentTransactions: {},
  total: 0,
  limit: 0,
  count: 0,
  isLoading: false,
  selectedAsset: null,
  lastUpdated: {},
  filters: {},
  visibleColumns: [
    'txHash',
    'date',
    'credit',
    'debit',
    'balance',
    'reference',
    'type',
    'purpose',
  ],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addTransactions, (state, action) => {
      const { allTxs, page, walletId, count, limit, total } = action.payload
      const updatedState = multipleSet(
        [
          {
            field: 'recentTransactions',
            value: allTxs,
            options: { safe: true },
          },
          {
            field: `lastUpdated.${`${walletId}${page}`}`,
            value: Date.now(),
          },
          {
            field: 'total',
            value: total,
          },
          {
            field: 'count',
            value: count,
          },
          {
            field: 'limit',
            value: limit,
          },
        ],
        state,
      )
      return updatedState
    })
    .addCase(setLoadingTransactions, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    })
    .addCase(updateFilters, (state, action) => {
      const newState = set(state, 'filters', {
        ...state.filters,
        ...action.payload,
      })
      return newState
    })
    .addCase(updateSelectedAsset, (state, action) => {
      const newState = set(state, 'selectedAsset', action.payload)
      return newState
    })
    .addCase(updateVisibleColumns, (state, action) => {
      const newState = set(state, 'visibleColumns', action.payload)
      return newState
    }),
)
