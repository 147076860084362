import { useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { fetchWalletMonthlyStatements } from '../../services/GringottsClient'
import useToast from '../../services/toast'
import { AppState } from '../index'
import { useGetAccessToken } from '../user/hooks'
import { addReport, updateReportUrl, loadingReports } from './actions'
import { useSelectSelectedGroup } from '../group/hooks'

export type PeriodType = 'monthly' | 'quarterly' | 'annual'
export type DocumentType = 'pdf' | 'csv'

export function useGetMonthlySummary(): ({
  walletId,
  period,
  documentType,
}: {
  walletId: string
  period?: PeriodType
  documentType?: DocumentType
}) => Promise<void> {
  const getAccessToken = useGetAccessToken()
  const selectedGroup = useSelectSelectedGroup()
  const toast = useToast()
  const dispatch = useDispatch()
  return useCallback(
    async ({
      walletId,
      period = 'monthly',
      documentType = 'pdf',
    }: {
      walletId: string
      period?: PeriodType
      documentType?: DocumentType
    }) => {
      if (!selectedGroup) return
      const accessToken = await getAccessToken()
      dispatch(loadingReports(true))
      try {
        const reports = await fetchWalletMonthlyStatements(
          accessToken,
          walletId,
          {
            params: {
              period,
              documentType,
            },
          },
          selectedGroup,
        )
        batch(() => {
          reports.forEach((report: any) => {
            report.walletId = walletId
            dispatch(addReport(report))
          })
        })
        dispatch(loadingReports(false))
        return reports
      } catch (err: any) {
        console.log(err)
        dispatch(loadingReports(false))
        toast('error', `Unable to fetch summary reports. Please try again.`)
      }
    },
    [dispatch, getAccessToken, toast, selectedGroup],
  )
}

export function useUpdateReportUrl(): (url: string) => any {
  const dispatch = useDispatch()
  return useCallback(
    (url: string) => {
      dispatch(updateReportUrl(url))
    },
    [dispatch],
  )
}

export function useSelectReports(): any {
  const reports = useSelector<AppState, AppState['report']['reports']>(
    (state) => state.report.reports,
  )
  return reports
}

export function useSelectIsReportsLoading(): any {
  const isLoading = useSelector<AppState, AppState['report']['isLoading']>(
    (state) => state.report.isLoading,
  )
  return isLoading
}

export function useSelectReportsByWalletIdArray(walletId: string): any {
  const reports = useSelectReports()
  return Object.values(reports)
    .filter((report: any) => report.walletId === walletId)
    .sort((a: any, b: any) => {
      const aDate = new Date(a.startDate)
      const bDate = new Date(b.startDate)
      return bDate.getTime() - aDate.getTime()
    })
}

export function useSelectReportByKey(key: string): any {
  const reports = useSelectReports()
  return reports[key]
}
