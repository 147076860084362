import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Modal, RowBetween } from '@bitaccess/bitaccess-ui'
import { Repeat } from 'react-feather'
import { useSelectTxById } from '../../state/transaction/hooks'
import { useSelectAsset } from '../../state/asset/hooks'
import { getFormattedDate, getTimezoneISOString } from '../../utils'
import DataRows from '../../components/DataRows'

export default function TransactionDetailModal({
  parentRoute,
}: {
  parentRoute: string
}) {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(true)
  const { txId } = useParams<{ txId: string }>()
  const tx = useSelectTxById(txId)
  const asset = useSelectAsset(tx?.assetId)

  const handleOnClose = () => {
    history.push(parentRoute)
  }

  const rows: { label: string; data: any }[] = [
    { label: 'Line Number:', data: String(tx?.sortIndex) },
    { label: 'Reference ID:', data: tx?.reference },
    { label: 'Wallet ID:', data: tx?.walletId },
    { label: 'Type:', data: tx?.type },
    { label: 'Purpose:', data: tx?.purpose },
    { label: 'Hash:', data: tx?.txHash },
    { label: 'Network:', data: tx?.networkType },
    { label: 'Address:', data: tx?.address },
    { label: 'Created Date:', data: getTimezoneISOString(tx?.createdAt) },
    { label: 'Confirmed Date:', data: getTimezoneISOString(tx?.confirmedAt) },
    { label: 'Block Height:', data: String(tx?.blockHeight) },
    { label: 'Debit:', data: String(tx?.debit) },
    { label: 'Credit:', data: String(tx?.credit) },
    { label: 'Balance:', data: String(tx?.balance) },
  ]

  return (
    <Modal
      id={'transaction-history-modal'}
      title={
        <RowBetween className="pr-8 items-center">
          <div className="flex flex-wrap justify-start items-center">
            <div className="bg-yellow-100 p-2 inline-block mr-3">
              <Repeat className="h-5 w-5 flex text-bitaccess-orange" />
            </div>
            <div className="text-left">
              <div className="capitalize">{tx?.type}</div>
              <div className="text-xs text-gray-500">
                {getFormattedDate(tx?.createdAt, 'words')}
              </div>
            </div>
          </div>
          <div className="text-left text-base text-gray-900">
            {parseFloat(String(tx?.debit)) > 0 ? (
              <span className="bg-red-100 text-red-700 px-2 py-1">
                -{String(tx?.debit)} {asset?.symbol}
              </span>
            ) : (
              <span className="bg-green-100 text-green-700 px-2 py-1">
                +{String(tx?.credit)} {asset?.symbol}
              </span>
            )}
          </div>
        </RowBetween>
      }
      modalClassName="max-w-screen-2xl md:w-1/2 w-screen h-10/12 m-0"
      body={
        <div className="mt-8">
          <DataRows rows={rows} />
        </div>
      }
      showCloseButton={true}
      footer={<></>}
      open={open}
      setOpen={setOpen}
      onClose={handleOnClose}
    />
  )
}
