import _ from 'lodash'
import { SearchIcon, CheckIcon } from '@heroicons/react/solid'
import { InputGroup } from "@bitaccess/bitaccess-ui"
import styled from 'styled-components'
import Fuse from 'fuse.js'
import { useCallback, useMemo, useState } from 'react'
import { useSelectedGroupWalletsArray } from '../../state/wallet/hooks'

const List = styled.ul`
  max-height: 250px;
`

export default function WalletListSearch({
  onSelect,
  inputGroupClass,
  inputClass,
  selectedWallet,
  showLabel = true,
}: {
  onSelect: (wallet: any) => void
  inputGroupClass?: string
  inputClass?: string
  showLabel?: boolean
  selectedWallet?: any
}) {
  const wallets = useSelectedGroupWalletsArray()
  const [searchResults, setSearchResults] = useState<any>(wallets)
  const [searchValue, setSearchValue] = useState<string>('')
  const options = useMemo(
    () => ({
      includeScore: true,
      keys: ['label', 'asset.name', 'asset.symbol'],
      threshold: 0.2,
    }),
    [],
  )
  const handleSelect = (wallet: any) => {
    onSelect(wallet)
    handleSearch({ target: { value: '' } })
  }
  const fuse = useMemo(() => new Fuse(wallets, options), [wallets, options])
  const handleSearch = useCallback(
    (e: any) => {
      const value = e.target.value
      setSearchValue(value)
      const result = fuse.search(value)
      const items = result.length > 0 ? result.map((r) => r.item) : wallets
      setSearchResults(items)
    },
    [`${wallets}`, fuse],
  )
  return (
    <>
      <div className={inputGroupClass || 'px-0 bg-gray-50 border-b py-2'}>
        <InputGroup
          id="search-wallets"
          placeHolder="Wallet or asset name..."
          onChange={handleSearch}
          label={showLabel ? 'Filter Wallets' : ''}
          onEnter={() => console.log('enter')}
          inputStyles={`${inputClass || ''} mt-2`}
          value={searchValue}
          InputIcon={<SearchIcon />}
        />
      </div>
      <List className="overflow-y-scroll mb-0">
        {searchResults.length > 0 ? (
          searchResults.map((wallet: any) => {
            const selected = wallet?.id === selectedWallet?.id
            return (
              <li
                className={`${
                  selected ? 'text-yellow-900 bg-yellow-100' : 'text-gray-900'
                } hover:text-yellow-900 hover:bg-yellow-100 no-underline block cursor-pointer select-none relative border-b border-gray-100`}
                onClick={() => handleSelect(wallet)}
                key={wallet.id}
              >
                <div className="py-2 px-4">
                  <img
                    className="inline-block mr-3 relative"
                    style={{ top: -1 }}
                    src={wallet.asset?.logoUrl}
                    height={22}
                    width={22}
                  />
                  <span
                    className={`${
                      !selected
                        ? 'text-gray-800 '
                        : 'text-yellow-900 font-medium'
                    }`}
                  >
                    {_.truncate(wallet.label, { length: 30 })}
                  </span>
                  {selected ? (
                    <span
                      className={
                        'text-yellow-600 absolute inset-y-0 right-4 flex items-center pl-3'
                      }
                    >
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </div>
              </li>
            )
          })
        ) : (
          <li
            className={`bg-white hover:bg-gray-100 cursor-pointer border-b border-gray-100`}
          >
            <div className="py-2 px-4 text-gray-500">
              No wallets found for this group.
            </div>
          </li>
        )}
      </List>
    </>
  )
}
