/* eslint-disable react/display-name */
import { useEffect, useMemo } from 'react'
import Table from '../Table'
import {
  useGetSherlockReports,
  useSelectActivityPagination,
  useSelectIsLoadingLogs,
  useSelectLogsByPage,
} from '../../state/activity/hooks'
import { getTimezoneISOString } from '../../utils'
import { EllipsizedText } from "@bitaccess/bitaccess-ui"
import { Maximize2 } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { useSelectSelectedGroup } from '../../state/group/hooks'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export default function ActivityTable({ walletId }: { walletId: string }) {
  const isLoading = useSelectIsLoadingLogs()
  const getSherlockReports = useGetSherlockReports()
  const { search } = useLocation()
  const selectedGroup = useSelectSelectedGroup()
  const pagination = useSelectActivityPagination()

  const [page, setPage] = useQueryParam('page', withDefault(StringParam, '1'))
  const logs = useSelectLogsByPage(walletId, parseInt(page))

  const handleGetSherlockReports = async () => {
    await getSherlockReports({
      walletId,
      query: { jurisdiction: walletId },
      page: parseInt(page),
      limit: 20,
    })
    window.scroll({ top: 0 })
  }

  useEffect(() => {
    handleGetSherlockReports()
  }, [])

  const handleCreateTableRows: any = useMemo((): any => {
    return (
      logs &&
      logs?.map((log: any) => {
        return {
          date: log.time,
          type: log.type,
          user: log.user,
          identifier: log.identifier,
          interface: log.interface,
          expand: { id: log.id, walletId: log.walletId },
        }
      })
    )
  }, [logs])

  return (
    <Table
      id="activity-table"
      columns={[
        {
          Header: 'Date',
          accessor: 'date',
          disableSortBy: true,
          width: 110,
          Cell: (e: any) => {
            return <>{getTimezoneISOString(e.value)}</>
          },
        },
        {
          Header: 'Action',
          accessor: 'type',
          width: 110,
          disableSortBy: true,
          Cell: (e: any) => {
            return <>{e.value}</>
          },
        },
        {
          Header: 'User',
          accessor: 'user',
          width: 110,
          disableSortBy: true,
          Cell: (e: any) => {
            return <>{e.value}</>
          },
        },
        {
          Header: 'Identifier',
          accessor: 'identifier',
          width: 140,
          disableSortBy: true,
          Cell: (e: any) => {
            return (
              <EllipsizedText
                canCopy={true}
                padding={12}
                text={e.value}
                id={e.value}
              />
            )
          },
        },
        {
          Header: 'Interface',
          accessor: 'interface',
          width: 110,
          disableSortBy: true,
          Cell: (e: any) => {
            return <>{e.value}</>
          },
        },
        {
          Header: '',
          accessor: (d: any) => d.expand?.id,
          id: 'expand',
          maxWidth: 0,
          Cell: (e: any) => (
            <div className="expand-col invisible">
              <Link
                to={{
                  pathname: `/wallets/${selectedGroup}/${e.cell.row.original?.expand.walletId}/activity/${e.cell.row.original?.expand.id}`,
                  search,
                }}
                replace={true}
                className="no-underline float-right"
              >
                <div className="group w-8 h-8 flex items-center justify-center border border-gray-200 text-center">
                  <Maximize2 className="group-hover:text-gray-900 w-3.5 h-3.5 text-gray-500" />
                </div>
              </Link>
            </div>
          ),
          disableSortBy: true,
          show: true,
        },
      ]}
      data={handleCreateTableRows}
      searchPlaceholder="Filter logs..."
      showSearch={true}
      isLoading={isLoading}
      pagination={true}
      totalItems={pagination.total}
      itemsPerPage={pagination.limit}
      currentPage={parseInt(page)}
      onChangePage={(page: string) => setPage(page)}
    />
  )
}
