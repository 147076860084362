/* eslint-disable react/display-name */
import { ChevronDownIcon } from '@heroicons/react/solid'
import React, { useEffect, useRef, useState, forwardRef } from 'react'
import * as S from './styles'
import { Transition, FocusTrap } from '@headlessui/react'

export enum DropdownType {
  DEFAULT,
  TEXT,
}

/**
 * Dropdown UI component
 */
export const Dropdown = forwardRef((props: DropdownProps, ref: any): JSX.Element => {
  const [showDropdownList, setShowDropdownList] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref) {
      ref.current = (value: boolean) => setShowDropdownList(value)
    }
    return () => {
      if (ref) {
        ref.current = null
      }
    }
  })

  let StyledDropdown
  switch (props.dropdownType) {
    case DropdownType.TEXT: {
      StyledDropdown = S.StyledTextDropdown
      break
    }
    default: {
      StyledDropdown = S.StyledDefaultDropdown
    }
  }

  function generateDropdownText(): JSX.Element {
    if (props.secondaryText) {
      return (
        <S.TextArea>
          {props.text}
          <S.SelectedText>{props.secondaryText}</S.SelectedText>
        </S.TextArea>
      )
    }

    return <span>{props.text}</span>
  }

  function onDropdownClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    setShowDropdownList(!showDropdownList)
  }

  function closeDropdown() {
    setShowDropdownList(false)
  }

  useEffect(() => {
    function onOutsideClick(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [wrapperRef])

  return (
    <S.DropdownWrapper ref={wrapperRef}>
      <StyledDropdown
        id={props.id}
        className={props.extraStyles}
        onClick={onDropdownClick}
      >
        {props.Icon && (
          <S.StyledDropdownIcon>{props.Icon}</S.StyledDropdownIcon>
        )}
        {generateDropdownText()}
        <S.ScreenReader>Open options</S.ScreenReader>
        <S.StyledChevronDownIcon
          className={showDropdownList ? 'transform rotate-180' : ''}
        >
          <ChevronDownIcon className="" />
        </S.StyledChevronDownIcon>
      </StyledDropdown>
      <Transition show={showDropdownList}>
        <FocusTrap>
          <Transition.Child
            appear={true}
            enter="transition ease-out duration-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <S.DropdownList
              id={`${props.id}_list`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
              className={
                props.dropdownPosition === 'left' ? 'left-0' : 'right-0'
              }
            >
              {props.children}
            </S.DropdownList>
          </Transition.Child>
        </FocusTrap>
      </Transition>
    </S.DropdownWrapper>
  )
})

export interface DropdownProps {
  id: string
  dropdownType?: DropdownType
  extraStyles?: string
  dropdownPosition?: 'left' | 'right'
  text?: string
  secondaryText?: string
  Icon?: React.SVGProps<SVGSVGElement>
  children: React.ReactNode
  hasCloseIcon?: boolean
}

Dropdown.defaultProps = {
  dropdownType: DropdownType.DEFAULT,
  dropdownPosition: 'left',
}

