export default function CoinIcon({
  src,
  size = 6,
  className,
}: {
  src: string
  size?: number
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={className}>
      <img src={src} className={`w-${size} h-${size} relative`} />
    </div>
  )
}
