import { createReducer } from '@reduxjs/toolkit'
import { setLoadingAppData, setLoadingText, updateNetwork } from './actions'
import set from 'immutable-set'

export interface ApplicationState {
  test: string
  returnTo: string
  isLoading: boolean
  loadingText: string
  network: string
}

const initialState: ApplicationState = {
  test: '',
  returnTo: '/auth/callback',
  isLoading: false,
  loadingText: '',
  network: 'mainnet',
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setLoadingAppData, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    })
    .addCase(setLoadingText, (state, action) => {
      const newState = set(state, 'loadingText', action.payload)
      return newState
    })
    .addCase(updateNetwork, (state, action) => {
      const newState = set(state, 'network', action.payload)
      return newState
    }),
)
