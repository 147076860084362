import { useAuth0 } from '@auth0/auth0-react'
import React, { createContext, useContext } from 'react'
import { SUPERADMIN_PERMS } from '../constants'

type Props = {
  children: any
}

type PermissionContextType = {
  isAllowedTo: (permissions: string[]) => boolean
}
const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
}

export const PermissionContext =
  createContext<PermissionContextType>(defaultBehaviour)

export const useHasPermission = (permissions: string[]) => {
  const { isAllowedTo } = useContext(PermissionContext)
  return isAllowedTo(permissions)
}

export const useUsersPermissions: () => string[] = () => {
  const { user } = useAuth0()
  return (
    user?.['https://bitaccess.com/app_metadata']?.authorization?.permissions ??
    []
  )
}

export const useUsersRoles: () => string[] = () => {
  const { user } = useAuth0()
  return (
    user?.['https://bitaccess.com/app_metadata']?.authorization?.roles ??
    []
  )
}

export const useIsUserAllowedToAccess: () => (permissions: string[]) => boolean =
  () => {
    const permissions = useUsersPermissions()
    const roles = useUsersRoles()
    return (requestedPermissions: string[]) =>
      roles.some((role) => SUPERADMIN_PERMS.includes(role))
      || requestedPermissions.every((perm) => permissions.includes(perm))
  }

export const PermissionProvider: React.FunctionComponent<Props> = ({
  children,
}: Props) => {
  const isAllowedTo = useIsUserAllowedToAccess()
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider
