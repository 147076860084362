/* eslint-disable react/display-name */
import SelectMenu from '../SelectMenu'
import { Button, EllipsizedText, Tooltip, DateRangeSelector, afterToday, QuestionHelper, TextField } from "@bitaccess/bitaccess-ui"
import styled from 'styled-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { DownloadCloud, Loader, Maximize2 } from 'react-feather'
import {
  useQueryParams,
  withDefault,
  ArrayParam,
  StringParam,
} from 'use-query-params'
import {
  useGetRecentTransactions,
  useGetTransactionCSV,
  useSelectIsLoadingTransactionHistory,
  useSelectRecentTransactionData,
  useSelectTransactionHistoryVisibleColumns,
  useUpdateTableFilters,
  useUpdateVisibleColumns,
  useSelectAccountingFilters,
} from '../../state/transaction/hooks'
import {
  useSelectedGroupWalletsArray,
  useSelectWalletById,
} from '../../state/wallet/hooks'
import { getDateXMonthsAgo, getFormattedDate, getEnhancedFormattedDate } from '../../utils'
import EllipsizedLink from '../EllipisizedLink'
import Table from '../Table'
import WalletDropdown from '../WalletDropdown'
import TableSettingsModal from '../TableSettingsModal'
import { CogIcon } from '@heroicons/react/outline'
import { useSelectSelectedGroup } from '../../state/group/hooks'
import BalanceAmount from '../BalanceAmount'
import PermissionRestricted from '../PermissionRestricted'
import { fieldList } from './fieldlist'
import FilterDropdown from '../FilterDropdown'
import { ReactComponent as SendIcon } from '../../assets/sent-icon.svg'
import { ReactComponent as NetworkFeeIcon } from '../../assets/network-fee-icon.svg'
import { ReactComponent as ReceiveIcon } from '../../assets/receive-icon.svg'
import { ReactComponent as InternalIcon } from '../../assets/internal-icon.svg'

type DateType = [(Date | undefined)?, (Date | undefined)?]
const DEFAULT_DATE_RANGE: any = []
const DEFAULT_CSV_DATE_RANGE: any = [getDateXMonthsAgo(1), new Date()]

const DateRangeCustomStyles = styled.div`
  .rs-picker-toggle {
    border-radius: 0 !important;
    height: 34px;
  }
`

export default function TransactionHistoryTable({
  selectedWalletId,
  showSelectWalletDropdown = false,
  selectWallet,
}: {
  selectedWalletId: string
  showSelectWalletDropdown?: boolean
  selectWallet?: any
}) {
  const { search } = useLocation()
  const getTransactionHistoryCSV = useGetTransactionCSV()
  const getRecentTransactions = useGetRecentTransactions()
  const isLoading = useSelectIsLoadingTransactionHistory()
  const updateVisibleColumns = useUpdateVisibleColumns()
  const defaultColumns = useSelectTransactionHistoryVisibleColumns()
  const [isDownloadingCSV, setIsDownloadingCSV] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const selectedGroup = useSelectSelectedGroup()
  const selectedWallet = useSelectWalletById(selectedWalletId)
  const wallets = useSelectedGroupWalletsArray()
  const updateTableFilters = useUpdateTableFilters()
  const reduxFilters = useSelectAccountingFilters()

  const convertDates = (dates: any[]) => {
    return dates.map((date) => date?.toDateString())
  }

  const [query, setQuery] = useQueryParams({
    walletId: withDefault(StringParam, ''),
    dateRange: withDefault(ArrayParam, convertDates(DEFAULT_DATE_RANGE)),
    page: withDefault(StringParam, '1'),
    txHash: withDefault(StringParam, undefined),
    address: withDefault(StringParam, undefined),
    purpose: withDefault(StringParam, undefined),
    type: withDefault(StringParam, undefined),
    reference: withDefault(StringParam, undefined),
  })

  const [txHashFilter, setTxHashFilter] = useState(query.txHash)
  const [addressFilter, setAddressFilter] = useState(query.address)
  const [purposeFilter, setPurposeFilter] = useState(query.purpose)
  const [typeFilter, setTypeFilter] = useState(query.type)
  const [referenceFilter, setReferenceFilter] = useState(query.reference)

  const onClearFilters = useCallback(() => {
    setQuery({
      txHash: undefined,
      address: undefined,
      purpose: undefined,
      type: undefined,
      reference: undefined,
    })
    updateTableFilters({})
    setTxHashFilter(undefined)
    setAddressFilter(undefined)
    setPurposeFilter(undefined)
    setTypeFilter(undefined)
    setReferenceFilter(undefined)
  }, [setQuery, updateTableFilters])

  const data = useSelectRecentTransactionData(
    selectedWalletId,
    parseInt(query.page),
  )

  const purposeItems: any[] = data.txs?.map((tx: any) => {
    if (tx?.purpose === null) tx.purpose = 'unknown'
    return tx?.purpose
  })

  const typeItems: any[] = data.txs?.map((tx: any) => {
    return tx?.type
  })

  const purposeItemsSet = [...new Set(purposeItems)]
  const typeItemsSet = [...new Set(typeItems)]

  const filters = useMemo(
    () => [
      {
        label: 'Tx Hash',
        component: (
          <TextField
            id="tr-hash-filter"
            value={txHashFilter}
            placeHolder="Tx Hash"
            extraStyles="h-8 mt-2 pl-2"
            onChange={(e) => {
              const value = e.target.value
              setTxHashFilter(value)
            }}
          />
        ),
        saveFunc: (checked: boolean) => {
          const value = checked ? txHashFilter : undefined
          updateTableFilters({ txHash: value })
          setQuery({ txHash: value })
        },
        defaultSelected: Boolean(txHashFilter),
      },
      {
        label: 'Address',
        component: (
          <TextField
            id="address-filter"
            value={addressFilter}
            placeHolder="Address"
            extraStyles="h-8 mt-2 pl-2"
            onChange={(e) => {
              const value = e.target.value
              setAddressFilter(value)
            }}
          />
        ),
        saveFunc: (checked: boolean) => {
          const value = checked ? addressFilter : undefined
          updateTableFilters({ address: value })
          setQuery({ address: value })
        },
        defaultSelected: Boolean(addressFilter),
      },
      {
        label: 'Purpose',
        component: (
          <SelectMenu
            onClick={(e) => {
              setPurposeFilter(e)
            }}
            items={purposeItemsSet}
            id="purpose-select"
            buttonClass="primary"
            text="Purpose"
          />
        ),
        saveFunc: (checked: boolean) => {
          let value = checked ? purposeFilter : undefined
          if (value === 'unknown') value = 'null'
          updateTableFilters({ purpose: value })
          setQuery({ purpose: value })
        },
        setInitialValue: () => {
          setPurposeFilter(purposeItemsSet[0] || '')
        },
        defaultSelected: Boolean(purposeFilter),
      },
      {
        label: 'Type',
        component: (
          <SelectMenu
            onClick={(e) => {
              setTypeFilter(e)
            }}
            items={typeItemsSet}
            id="type-select"
            buttonClass="primary"
            text="Type"
          />
        ),
        saveFunc: (checked: boolean) => {
          const value = checked ? typeFilter : undefined
          updateTableFilters({ type: value })
          setQuery({ type: value })
        },
        setInitialValue: () => {
          setTypeFilter(typeItemsSet[0] || '')
        },
        defaultSelected: Boolean(typeFilter),
      },
      {
        label: 'Reference ID',
        component: (
          <TextField
            id="reference-id-filter"
            value={referenceFilter}
            placeHolder="Reference ID"
            extraStyles="h-8 mt-2 pl-2"
            onChange={(e) => setReferenceFilter(e.target.value)}
          />
        ),
        saveFunc: (checked: boolean) => {
          const value = checked ? referenceFilter : undefined
          updateTableFilters({ reference: value })
          setQuery({ reference: value })
        },
        defaultSelected: Boolean(referenceFilter),
      },
    ],
    [
      addressFilter,
      purposeFilter,
      referenceFilter,
      setQuery,
      txHashFilter,
      updateTableFilters,
      purposeItems,
      typeItems,
      typeFilter,
    ],
  )

  const handleGetCSV = useCallback(async () => {
    setIsDownloadingCSV(true)
    await getTransactionHistoryCSV({
      walletId: selectedWalletId,
      fromDate: query.dateRange[0] || convertDates(DEFAULT_CSV_DATE_RANGE)[0]!,
      toDate: query.dateRange[1] || convertDates(DEFAULT_CSV_DATE_RANGE)[1]!,
    })
    setIsDownloadingCSV(false)
  }, [getTransactionHistoryCSV, `${query.dateRange}`, query.walletId])

  const handleCreateTableRows: any = useMemo((): any => {
    if (isLoading) return []
    return (
      data.txs &&
      data.txs?.map((tx: any, i: number) => {
        return {
          date: tx?.confirmedAt,
          reference: tx?.reference,
          purpose: tx?.purpose,
          type: tx?.type,
          txHash: { txurl: tx?.txUrl, blockchainid: tx?.txHash },
          credit: tx?.credit ?? 0,
          debit: tx?.debit ?? 0,
          balance: {
            balance: tx?.balance,
            showHelpText:
              i === 0 &&
              parseInt(query.page) == 1 &&
              Object.values(reduxFilters).every((el) => !el),
          },
          address: { address: tx?.address, addressurl: tx?.addressUrl },
          expand: { txId: tx?.id, walletId: tx?.walletId },
        }
      })
    )
  }, [isLoading, data.txs])

  const handleDateChange = (dates: DateType) => {
    setQuery({ dateRange: convertDates(dates) })
  }

  const handleDateClean = () => {
    setQuery({ dateRange: [undefined, undefined] })
  }

  const handleSelectWallet = (wallet: any) => {
    selectWallet(wallet)
    setQuery({ walletId: wallet.id })
  }

  const handleChangePage = useCallback(
    (page: string) => {
      setQuery({ page })
    },
    [setQuery],
  )

  const selectedDates: any = useMemo(() => {
    return query.dateRange.map((date: any) => {
      return new Date(date)
    })
  }, [query.dateRange])

  const handleGetRecentTransactions = useCallback(async () => {
    await getRecentTransactions({
      walletId: selectedWalletId,
      params: {
        fromDate: query.dateRange[0] || DEFAULT_DATE_RANGE[0]!,
        toDate: query.dateRange[1] || DEFAULT_DATE_RANGE[1]!,
        page: parseInt(query.page),
        limit: 50,
        networkType: selectedWallet?.networkType,
        txHash: query.txHash,
        reference: query.reference,
        purpose: query.purpose,
        address: query.address,
        type: query.type,
      },
    })
    typeof window !== 'undefined' && window.scrollTo(0, 0)
  }, [
    query.dateRange,
    query.page,
    query.address,
    query.purpose,
    query.type,
    query.reference,
    selectedWalletId,
    selectedWallet?.networkType,
    query.txHash,
  ])

  useEffect(() => {
    if (!query.walletId && wallets.length > 0 && showSelectWalletDropdown) {
      setQuery({ walletId: wallets[0].id })
    }
  }, [`${wallets}`, query.walletId, showSelectWalletDropdown])

  useEffect(() => {
    if (wallets.length > 0) {
      handleGetRecentTransactions()
    }
    updateTableFilters({
      address: query.address,
      txHash: query.txHash,
      reference: query.reference,
      purpose: query.purpose,
      type: query.type,
    })
  }, [])

  const TypeIcon = (props: any) => {
    const { icon: Icon, alt, tooltipText } = props
    return (
      <div className="ml-1">
        <Tooltip text={tooltipText}>
          <Icon
            alt={alt}
            className="fill-current -rotate-90 text-gray-500 h-6 mt-0.5 hover:text-orange-500"
          />
        </Tooltip>
      </div>
    )
  }

  const rightTableHeader = useMemo(
    () => (
      <PermissionRestricted perms={['export:wallet']}>
      <div className="flex flex-direction-row items-center">
        <Tooltip
          placement="top"
          text="At most 1 month of data can be exported at a time. Use the date selector to choose your range."
        >
          <Button
            className={`${
              isDownloadingCSV
                ? 'opacity-60 pointer-events-none'
                : 'opacity-100'
            } justify-center w-44 text-small mt-2 lg:mt-0 text-gray-600 font-medium bg-gray-50 shadow-sm`}
            // @ts-expect-error: ignore type error for now
            onClick={handleGetCSV}
            disabled={isDownloadingCSV}
            Icon={
              !isDownloadingCSV ? (
                <DownloadCloud className="h-5 w-5 text-gray-600 self-center" />
              ) : (
                <Loader className="h-5 w-5 text-gray-600 self-center loading-75 animate-spin" />
              )
            }
            id="download-csv"
            type="custom"
            text={isDownloadingCSV ? 'Downloading...' : 'Download CSV'}
          />
        </Tooltip>
      </div>
    </PermissionRestricted>
  
    ),
    [isDownloadingCSV]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (e: any) => {
          switch (e.value) {
            case 'send':
              return (
                <TypeIcon
                  icon={SendIcon}
                  tooltipText={e.value}
                  alt="Send type transaction"
                />
              )
            case 'network fee':
              return (
                <TypeIcon
                  icon={NetworkFeeIcon}
                  tooltipText={e.value}
                  alt="Network fee type transaction"
                />
              )
            case 'receive':
              return (
                <TypeIcon
                  icon={ReceiveIcon}
                  tooltipText={e.value}
                  alt="Receive type transaction"
                />
              )
            case 'internal':
              return (
                <TypeIcon
                  icon={InternalIcon}
                  tooltipText={e.value}
                  alt="Internal type transaction"
                />
              )
            default:
              return <span className="capitalize">{e.value}</span>
          }
        },
        disableSortBy: true,
        maxWidth: 72,
        minWidth: 72,
        show: defaultColumns?.includes('type'),
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (e: any) => (
          <>{getEnhancedFormattedDate(e.value, 'mediumDate', 'shortTime')}</>
        ),
        disableSortBy: true,
        show: defaultColumns?.includes('date'),
      },
      {
        Header: 'Ref ID',
        accessor: 'reference',
        minWidth: 160,
        Cell: (e: any) => (
          <>
            {e.value ? (
              <EllipsizedText
                text={e.value}
                canCopy={true}
                padding={4}
                id={`copy-ref-${e.value}`}
              />
            ) : (
              '-'
            )}
          </>
        ),
        disableSortBy: true,
        show: defaultColumns?.includes('reference'),
      },
      {
        Header: 'Purpose',
        accessor: 'purpose',
        Cell: (e: any) => {
          if (e.value === 'unknown') return <i>unknown</i>
          return e.value
        },
        disableSortBy: true,
        show: defaultColumns?.includes('purpose'),
      },
      {
        Header: 'Tx Id',
        accessor: (d: any) => d.txHash?.blockchainid,
        minWidth: 170,
        id: 'txHash',
        Cell: (e: any) => (
          <>
            {e.value ? (
              <>
                <EllipsizedLink
                  href={e.cell.row.original?.txHash?.txurl}
                  id={e.cell.row.original?.txHash?.blockchainid}
                  text={e.cell.row.original?.txHash?.blockchainid}
                  padding={4}
                  canCopy={false}
                  showTooltip={true}
                />
              </>
            ) : (
              '-'
            )}
          </>
        ),
        disableSortBy: true,
        show: defaultColumns?.includes('txHash'),
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: (d: any) => d.address?.address,
        Cell: (e: any) => (
          <>
            {e.value ? (
              <EllipsizedLink
                href={e.cell.row.original?.address?.addressurl}
                id={e.cell.row.original?.address?.address}
                text={e.cell.row.original?.address?.address}
                padding={4}
                canCopy={false}
                showTooltip={true}
              />
            ) : (
              '-'
            )}
          </>
        ),
        disableSortBy: true,
        show: defaultColumns?.includes('address'),
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        id: 'credit',
        maxWidth: 95,
        disableSortBy: true,
        Cell: (e: any) => <>{e.value.toString()}</>,
        show: defaultColumns?.includes('credit'),
      },
      {
        Header: 'Debit',
        accessor: 'debit',
        maxWidth: 95,
        Cell: (e: any) => <>{e.value.toString()}</>,
        disableSortBy: true,
        show: defaultColumns?.includes('debit'),
      },
      {
        Header: 'Balance',
        id: 'balance',
        accessor: (d: any) => d.balance?.balance,
        disableSortBy: true,
        Cell: (e: any) => {
          return (
            <>
              <BalanceAmount
                amount={e.cell.row.original?.balance?.balance}
              />
              {e.cell.row.original?.balance?.showHelpText && (
                <QuestionHelper
                  id="transaction-history"
                  style={{ top: 1 }}
                  className="ml-1 inline-block align-middle text-gray-400"
                  helpText="The balance of this most recent entry may be from up to 6 blocks ago."
                />
              )}
            </>
          )
        },
        show: defaultColumns?.includes('balance'),
      },
      {
        Header: '',
        accessor: (d: any) => d.expand?.txId,
        id: 'expand',
        maxWidth: 0,
        Cell: (e: any) => (
          <div className="expand-col invisible">
            <Link
              to={{
                pathname: `/wallets/${selectedGroup}/${e.cell.row.original?.expand.walletId}/transactions/${e.cell.row.original?.expand.txId}`,
                search,
              }}
              replace={true}
              className="no-underline float-right"
            >
              <div className="group w-8 h-8 flex items-center justify-center border border-gray-200 text-center">
                <Maximize2 className="group-hover:text-gray-900 w-3.5 h-3.5 text-gray-500" />
              </div>
            </Link>
          </div>
        ),
        disableSortBy: true,
        show: true,
      },
    ],
    [defaultColumns]
  )

  const lastThComponent = useMemo(
    () => (
      <Tooltip placement="top" text="Edit visible columns">
        <CogIcon
          onClick={() => setOpen(!isOpen)}
          style={{ right: 7, top: 7 }}
          className="h-4 w-4 ml-3 cursor-pointer text-gray-400 hover:text-gray-600 absolute"
        />
      </Tooltip>
    ),
    [isOpen]
  )

  const tableHeader = useMemo(
    () => (
      <>
        {showSelectWalletDropdown && (
          <WalletDropdown
            onSelect={handleSelectWallet}
            selectedWallet={selectedWallet}
          />
        )}
        <div className="flex flex-row items-center gap-3">
          <FilterDropdown filters={filters} onClear={onClearFilters} />
          <DateRangeCustomStyles>
            <DateRangeSelector
              onOk={handleDateChange}
              defaultValue={DEFAULT_DATE_RANGE}
              onClean={handleDateClean}
              value={selectedDates}
              className="pr-5 w-64"
              ranges={[]}
              placement="auto"
              disabledDate={afterToday()}
            />      
          </DateRangeCustomStyles>
        </div>
      </>  
    ),
    [showSelectWalletDropdown, selectedWallet, filters, selectedDates, handleDateChange, handleDateClean, handleSelectWallet, onClearFilters]
  )

  return (
    <div className="relative">
      <Table
        id="transaction-history-table"
        columns={columns}
        data={handleCreateTableRows}
        onChangePage={handleChangePage}
        currentPage={parseInt(query.page)}
        totalItems={data?.total || 0}
        itemsPerPage={data?.limit || 0}
        pagination={true}
        showSearch={false}
        lastThComponent={lastThComponent}
        isLoading={isLoading}
        rightTableHeader={rightTableHeader}
        tableHeader={tableHeader}
      />
      <>
        <TableSettingsModal
          fields={fieldList}
          defaultSelected={defaultColumns}
          tableName="Transaction History"
          isOpen={isOpen}
          setOpen={setOpen}
          updateAction={updateVisibleColumns}
        />
      </>
    </div>
  )
}
