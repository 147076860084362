import { useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { InputGroup } from "@bitaccess/bitaccess-ui"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid'
import classNames from 'class-names'
import { SearchIcon } from '@heroicons/react/outline'
import * as S from './styles'

export enum DropdownListType {
  DEFAULT,
  GROUP,
}

/**
 * Dropdown UI component
 */
const SelectMenu = (props: SelectMenuProps): JSX.Element => {
  const {
    selectKey,
    buttonClass,
    listBoxClass,
    id,
    onClick,
    items,
    handleSearch,
    searchValue,
    showSearch,
    emptyListMessage,
    label,
    disabled,
  } = props
  const selectKeyDefault = selectKey || 0
  const [selected, setSelected] = useState(
    selectKey ? props.items[selectKeyDefault] : items[0],
  )

  const [isListEmpty, setIsListEmpty] = useState<boolean>(false)

  const message = emptyListMessage ? emptyListMessage : 'No items on the list'

  useEffect(() => {
    if (items.length === 0) {
      setIsListEmpty(true)
    }
  }, [items])

  const handleOnChange = (value: any) => {
    onClick(value)
    setSelected(value)
  }

  return (
    <>
      <S.LabelWrapper>
        <div className="flex">
          <S.StyledLabel>{label}</S.StyledLabel>
        </div>
      </S.LabelWrapper>
      <Listbox disabled={disabled} value={selected} onChange={handleOnChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={`${buttonClass} text-left w-full mt-1.5 px-2 py-1 border border-gray-300 rounded-md`}
              >
                {isListEmpty ? (
                  <div className="text-gray-400">{message}</div>
                ) : (
                  <div className="flex justify-between">
                    <span className="inline-block align-middle truncate mr-2 font-normal">
                      {selected}
                    </span>
                    <span className="inline-block align-middle items-center pointer-events-none">
                      {open ? (
                        <ChevronUpIcon
                          className="h-5 w-5 text-gray"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                )}
              </Listbox.Button>
              {isListEmpty ? null : (
                <Transition
                  show={open}
                  as={'div'}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div style={{ width: 300 }}>
                    {showSearch ? (
                      <div
                        style={{ width: 300 }}
                        className="px-0 bg-gray-50 border-b pb-2 pt-1 fixed z-10"
                      >
                        <InputGroup
                          id="search-groups"
                          placeHolder="Organization name..."
                          onChange={handleSearch}
                          label="Filter Organizations"
                          onEnter={() => console.log('enter')}
                          inputStyles="rounded mt-2"
                          value={searchValue}
                          InputIcon={<SearchIcon />}
                          autoFocus={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Listbox.Options
                      style={{ width: 300 }}
                      className={`${listBoxClass} z-10 absolute mt-1 bg-white shadow-lg max-h-60 rounded-sm border border-gray-100 overflow-auto focus:outline-none sm:text-sm`}
                    >
                      <div style={{ marginTop: 0 }}>
                        {props.items.map((item: any, i) => {
                          const value = item || ''
                          return (
                            <Listbox.Option
                              key={`${id}-${i}`}
                              onClick={onClick}
                              className={({ active, selected }) =>
                                classNames(
                                  selected
                                    ? 'text-yellow-900 bg-yellow-100'
                                    : active
                                    ? 'text-yellow-900 bg-yellow-100'
                                    : 'text-gray-900',
                                  'cursor-pointer select-none relative py-2 pl-3 pr-12 border-b border-gray-100',
                                )
                              }
                              value={value}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected ? 'font-medium' : 'font-normal',
                                      'block truncate',
                                    )}
                                  >
                                    {value}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        'absolute text-yellow-900 bg-yellow-100 inset-y-0 right-0 flex items-center pr-4',
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          )
                        })}
                      </div>
                    </Listbox.Options>
                  </div>
                </Transition>
              )}
            </div>
          </>
        )}
      </Listbox>
    </>
  )
}

export interface SelectMenuProps {
  id: string
  onClick: (e: any) => React.MouseEventHandler<HTMLLIElement> | void
  items: any[]
  text?: string
  secondaryText?: string
  Icon?: React.SVGProps<SVGSVGElement>
  showSearch?: boolean
  selectKey?: number
  buttonClass?: string
  handleSearch?: (e: any) => void
  searchValue?: string
  listBoxClass?: string
  emptyListMessage?: string
  label?: string
  disabled?: boolean
}

SelectMenu.defaultProps = {
  items: [],
  onClick: () => {
    return
  },
  handleSearch: () => {
    return
  },
  dropdownPosition: 'left',
  disabled: false,
}

export default SelectMenu
