import { FunctionComponent, useState } from 'react'
import NewWalletDetails from '../../components/NewWalletDetails'
import NewWalletType from '../../components/NewWalletType'
import NewWalletBackup from '../../components/NewWalletBackup'
import PageLayout from '../../components/PageLayout'
import NewWalletSteps from '../../components/NewWalletSteps'
import NewWalletComplete from '../../components/NewWalletComplete'
import NewWalletWatchOnly from '../../components/NewWalletWatchOnly'
import { useGetWalletSetupStep } from '../../state/wallet/hooks'
import ErrorBoundary from '../ErrorBoundary'

const NewWallet: FunctionComponent = () => {
  const walletSetupStep = useGetWalletSetupStep()
  const [activeStep, setActiveStep] = useState(walletSetupStep)

  return (
    <ErrorBoundary redirectUrl="/wallets">
      <div className="w-full min-h-full">
        <PageLayout pageTitle={``}>
          <div>
            <NewWalletSteps
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </div>
          <div>
            {activeStep === 1 && (
              <NewWalletType setActiveStep={setActiveStep} />
            )}
            {activeStep === 2 && (
              <NewWalletDetails setActiveStep={setActiveStep} />
            )}
            {activeStep === 3 && <NewWalletWatchOnly />}
            {/* {activeStep === 3 && (
            <NewWalletBackup setActiveStep={setActiveStep} />
          )}
          {activeStep === 4 && <NewWalletComplete />} */}
          </div>
        </PageLayout>
      </div>
    </ErrorBoundary>
  )
}

export default NewWallet
