import { useState, useEffect } from 'react'
import { Modal, Checkbox, Button } from "@bitaccess/bitaccess-ui"

export default function TableSettingsModal({
  isOpen,
  setOpen,
  updateAction,
  fields,
  defaultSelected,
}: {
  isOpen: boolean
  setOpen: any
  tableName?: string
  updateAction: (columns: string[]) => void
  defaultSelected: string[]
  fields?: { name: string; description: string }[]
}) {
  const [selected, setSelected] = useState<string[]>(defaultSelected)
  const [disabled, setDisabled] = useState(selected.length > 7)

  useEffect(() => {
    if (selected.length > 8) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [selected, setDisabled])

  const handleCheckbox = (name: string, isSelected: boolean) => {
    let currentSelected = [...selected]
    if (isSelected) {
      currentSelected = currentSelected.filter((i) => i !== name)
    } else {
      currentSelected.push(name)
    }
    setSelected(currentSelected)
  }

  const handleSave = () => {
    if (disabled) return
    updateAction(selected)
    setOpen(false)
  }

  return (
    <>
      <Modal
        id="table-settings-modal"
        open={isOpen}
        setOpen={setOpen}
        title={<div className="pb-3">Table Preferences</div>}
        showCloseButton={true}
        body={
          <div className="mt-1">
            <fieldset className="space-y-1">
              <legend className="text-sm font-medium text-gray-800 mb-2 ml-3">
                Visible Columns:
              </legend>
              {fields?.map((field, i) => {
                const { name, description } = field
                const formattedName = name
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, function (str) {
                    return str.toUpperCase()
                  })
                const checked = selected?.includes(name)
                return (
                  <div
                    key={name}
                    onClick={() => handleCheckbox(name, checked)}
                    className={`${
                      checked ? 'bg-yellow-100' : 'bg-white'
                    } relative flex hover:bg-yellow-100 items-center mr-4 px-2 py-2 ml-2 cursor-pointer`}
                  >
                    <div className="text-sm min-w-0 flex-1 text-sm ml-1">
                      <label
                        htmlFor={`table-settings-checkbox-${name}`}
                        className={`${
                          checked ? 'text-yellow-800' : 'text-gray-700'
                        } font-semibold`}
                      >
                        {formattedName}
                      </label>
                      <p
                        className={
                          checked ? 'text-yellow-700' : 'text-gray-700'
                        }
                      >
                        {description}
                      </p>
                    </div>
                    <div className="flex items-center h-5">
                      <Checkbox
                        checked={checked}
                        onChange={() => handleCheckbox(name, checked)}
                        name={`table-settings-checkbox-${name}`}
                        id={`table-settings-checkbox-${name}`}
                      />
                    </div>
                  </div>
                )
              })}
            </fieldset>
          </div>
        }
        footer={
          <Button
            className="w-full justify-center mt-5 h-11 disabled:pointer-events-none"
            id="table-settings-save-button"
            text={
              disabled
                ? 'Only can add up to 8 columns at a time'
                : 'Update Preferences'
            }
            type="secondary"
            onClick={handleSave}
            disabled={disabled}
          />
        }
        scrollBody={true}
      />
    </>
  )
}
