import { CopyText, Tooltip, Skeleton, Button, Checkbox } from '@bitaccess/bitaccess-ui'
import Switch from '../../components/Switch'
import { useSelectWalletById } from '../../state/wallet/hooks'
import { useCallback, useState, useEffect } from 'react'
import {
  useGetWalletPolicy,
  useSelectIsLoadingPolicy,
} from '../../state/policy/hooks'
import tw from 'twin.macro'
import PolicyAddApprovedAdminModal from '../../pages/PolicyAddApprovedAdminModal'
import PolicyWhitelistTable from '../../components/PolicyWhitelistTable'
import CoinIcon from '../../components/CoinIcon'
import { InformationCircleIcon } from '@heroicons/react/solid'

export const StyledLabel = tw.label`
  block 
  text-xs 
  font-semibold 
  text-gray-800 
`

export const CheckboxLabel = tw.label`
  flex
`

export const LabelWrapper = tw.div`
  flex 
  justify-between 
  pb-0
  mb-1
`

export const PolicyRow = tw.div`
  p-4 
  sm:px-6
`

export const PolicyRowTitle = tw.h2`
  text-lg 
  leading-6 
  font-medium 
  text-gray-900
`

export const PolicyRowSubtitle = tw.p`
  mt-1 
  text-sm 
  text-gray-500
`

export const LimitSpan = tw.span`
  mx-1
`

export const LimitValue = tw.span`
  mx-1
  font-bold
  border-b
  border-black
  text-sm
  border-dashed	
`

export const LimitValueEmpty = tw.span`
  mx-1
  border-b
  border-black
  text-sm
  border-dashed	
  min-w-[40px]
  font-medium
  opacity-50
`

export const InputField = tw.input`
  focus:outline-none
  border 
  border-gray-300 
  px-1.5
  py-1
  rounded
  inline
  w-32
  text-sm
`

export default function Policy({ walletId }: { walletId: string }) {
  const getPolicyForWallet = useGetWalletPolicy()
  const wallet = useSelectWalletById(walletId)
  const isLoading = useSelectIsLoadingPolicy()
  const [admins, setAdmins] = useState<any[]>([])
  const [volumeLimit, setVolumeLimit] = useState<number>()
  const [volumeLimitInterval, setVolumeLimitInterval] = useState<string>()
  const [volumeLimitIntervalKey, setVolumeLimitIntervalKey] = useState<number>(0)
  const [volumeLimitIntervalCount, setVolumeLimitIntervalCount] =
    useState<number>()
  const [requiredTxApprovals, setRequiredTxApprovals] = useState<number>(0)
  const [requiredManualApprovals, setRequiredManualApprovals] = useState<boolean>(false)
  const [addAdminModalOpen, setAddAdminModalOpen] = useState<boolean>(false)
  const [volumeLimitIntervalItems, setVolumeLimitIntervalItems] = useState<
    string[]
  >(['second', 'minute', 'hour'])
  const [whitelistedDestinations, setWhiteListedDestinations] = useState<
    string[]
  >([])
  const [isWhitelistingEnabled, setIsWhitelistingEnabled] =
    useState<boolean>(false)

  const handleGetRecentTransactions = useCallback(async () => {
    const res = await getPolicyForWallet({ walletId: walletId })
    if (res) {
      const {
        admins,
        volumeLimit,
        volumeLimitInterval,
        volumeLimitIntervalCount,
        requiredTxApprovals,
        whitelistedDestinations,
        isWhitelistingEnabled,
      } = res
      setAdmins(admins)
      setVolumeLimit(volumeLimit)
      setVolumeLimitInterval(volumeLimitInterval)
      setVolumeLimitIntervalCount(volumeLimitIntervalCount)
      setRequiredTxApprovals(requiredTxApprovals)
      setWhiteListedDestinations(whitelistedDestinations)
      const volumeLimitIntervalKey =
        volumeLimitIntervalItems.indexOf(volumeLimitInterval) || 0
      setVolumeLimitIntervalKey(volumeLimitIntervalKey)
      setIsWhitelistingEnabled(isWhitelistingEnabled)
      requiredTxApprovals > 0 ? setRequiredManualApprovals(true) : ''
    }
  }, [walletId])

  useEffect(() => {
    handleGetRecentTransactions()
  }, [])

  const handleSwitch = (isEnabled: boolean) => {
    // TO DO: Add a POST request to update the "isWhitelistingEnabled" in DB
    // setIsWhitelistingEnabled(isEnabled)
  }
  const handleManualApprovals = () => {
    console.log('handle manual approvals')
  }

  const handleRequiredTxApprovals = () => {
    console.log('handle required approvals')
  }

  return (
    <div className="pt-2 divide-y divide-gray-200">
      <PolicyRow>
        <PolicyRowTitle>Admins</PolicyRowTitle>
        <PolicyRowSubtitle>
          Approved admins for making changes to your wallet and for approving
          transactions.
        </PolicyRowSubtitle>
        <div className="my-4">
          {isLoading ? (
            <Skeleton containerStyles="mt-2" id="approval-subtitle-skeleton">
              <Skeleton.Text />
            </Skeleton>
          ) : (
            <div>
              {admins.map((v) => (
                <CopyText
                  id={`${v.name}-admin`}
                  key={`${v.name}-admin`}
                  toCopy={v.name}
                >
                  {v.name}
                </CopyText>
              ))}
            </div>
          )}
        </div>
        <div className="my-2">
          <Button
            className="block mt-5 h-8 font-medium bg-bitaccess-orange border-0 rounded-none hover:bg-bitaccess-orange-dark"
            id="add-admin-button"
            text={'Add Another Admin'}
            type="secondary"
            onClick={() => setAddAdminModalOpen(true)}
            disabled={true}
          />
          <PolicyAddApprovedAdminModal
            isOpen={addAdminModalOpen}
            setOpen={setAddAdminModalOpen}
          />
        </div>
      </PolicyRow>
      <PolicyRow>
        <PolicyRowTitle>Manual Transaction Approval</PolicyRowTitle>
        <PolicyRowSubtitle>
          Must have at least <strong> (2) </strong>admin(s) to enable this
          feature.
        </PolicyRowSubtitle>
        {isLoading ? (
          <Skeleton containerStyles="mt-2" id="approval-subtitle-skeleton">
            <Skeleton.Text />
          </Skeleton>
        ) : (
          <>
            <div className="my-4 flex items-center">
              <CheckboxLabel>
                <Checkbox
                  checked={requiredManualApprovals}
                  onChange={() => handleManualApprovals()}
                  id="approval-checkbox"
                  name="approvalCheckbox"
                  disabled={true}
                />
                <span className="text-sm text-gray-900">
                  Require admin approval on all outgoing transactions
                </span>
              </CheckboxLabel>
            </div>
            {requiredTxApprovals > 0 && (
              <div className="bg-gray-50 p-4 rounded-md">
                <LabelWrapper>
                  <StyledLabel>Required Approvals</StyledLabel>
                </LabelWrapper>
                <InputField
                  value={requiredTxApprovals}
                  type="number"
                  onChange={() => handleRequiredTxApprovals()}
                  disabled={true}
                />
                <span className="mx-2">
                  <Tooltip
                    placement="top"
                    text="Max approvals are calculated by the total number of admins minus one (1)"
                  >
                    <div className="flex">
                      <span>
                        Max approvals: <strong>{admins.length - 1}</strong>
                      </span>
                      <InformationCircleIcon className="w-4 mx-2" />
                    </div>
                  </Tooltip>
                </span>
              </div>
            )}
          </>
        )}
        <Button
          className="block mt-5 h-8 font-medium bg-bitaccess-orange border-0 rounded-none hover:bg-bitaccess-orange-dark"
          id="update-required-approvals"
          text={'Update Approvals'}
          type="secondary"
          disabled={true}
        />
      </PolicyRow>
      <PolicyRow>
        <PolicyRowTitle>Volume Limits</PolicyRowTitle>
        <PolicyRowSubtitle>
          Manage volume limits and volume limit intervals.
        </PolicyRowSubtitle>
        <div className="my-4">
          {isLoading ? (
            <Skeleton
              containerStyles="mt-2"
              id="approval-volume-input-skeleton"
            >
              <Skeleton.Input />
            </Skeleton>
          ) : (
            <>
              <div className="flex flex-wrap items-center bg-gray-50 p-4 rounded-md">
                {!volumeLimit &&
                !volumeLimitInterval &&
                !volumeLimitIntervalCount ? (
                  <span className="text-black">No volume limits are set.</span>
                ) : (
                  <>
                    <LimitSpan>Limit total sent amount to at most</LimitSpan>
                    {volumeLimit ? (
                      <LimitValue>{volumeLimit?.toString()}</LimitValue>
                    ) : (
                      <LimitValueEmpty>{'<limit>'}</LimitValueEmpty>
                    )}
                    <span className="ml-1">
                      <CoinIcon
                        className="mr-1"
                        src={wallet?.asset?.logoUrl ?? ''}
                        size={5}
                      />
                    </span>
                    <LimitSpan>every</LimitSpan>
                    {volumeLimitIntervalCount ? (
                      <LimitValue>
                        {volumeLimitIntervalCount?.toString()}
                      </LimitValue>
                    ) : (
                      <LimitValueEmpty>{'<interval>'}</LimitValueEmpty>
                    )}
                    {volumeLimitInterval ? (
                      <LimitValue>
                        {volumeLimitIntervalItems[volumeLimitIntervalKey]}(s)
                      </LimitValue>
                    ) : (
                      <LimitValueEmpty>{'<time>'}</LimitValueEmpty>
                    )}
                  </>
                )}
              </div>
              <Button
                className="block mt-5 h-8 font-medium bg-bitaccess-orange border-0 rounded-none hover:bg-bitaccess-orange-dark"
                id="edit-volume-limits"
                text={'Edit Volume Limits'}
                type="secondary"
                disabled={true}
              />
            </>
          )}
        </div>
      </PolicyRow>
      <PolicyRow>
        <PolicyRowTitle>Enable Destination Whitelist</PolicyRowTitle>
        <PolicyRowSubtitle>
          Manage manual sending from this wallet. If Enabled, only whitelisted destinations are allowed.
        </PolicyRowSubtitle>
        <div className="my-4 flex justify-start items-center">
          <Switch
            checked={isWhitelistingEnabled}
            id="enable-whitelisting-switch"
            onChange={(isEnabled) => handleSwitch(isEnabled)}
          />
          <div className="text-gray-500 cursor-pointer ml-1.5 inline-block align-middle font-medium text-xs">
            {isWhitelistingEnabled ? 'Whitelisting Enabled' : 'Whitelisting Disabled'}
          </div>
        </div>
      </PolicyRow>
      {isWhitelistingEnabled && (
        <PolicyRow>
          <PolicyRowTitle>Whitelist</PolicyRowTitle>
          <PolicyRowSubtitle>
            Manage the list of approved destinations for this wallet. Add
            destinations below.
          </PolicyRowSubtitle>
          <div className="my-4">
            <PolicyWhitelistTable
              whitelistedDestinations={whitelistedDestinations}
              isLoading={isLoading}
            />
          </div>
          <div className="my-2">
            <Button
              className="block mt-5 h-8 font-medium bg-bitaccess-orange border-0 rounded-none hover:bg-bitaccess-orange-dark"
              id="add-admin-button"
              text={'Add Whitelist'}
              type="secondary"
              disabled={true}
            />
          </div>
        </PolicyRow>
      )}
    </div>
  )
}
