export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const GRINGOTTS_URL = process.env.REACT_APP_GRINGOTTS_URL
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
export const AUTH0_CALLBACK_URL = `${window.location.origin}${process.env.REACT_APP_AUTH0_CALLBACK_URL}`
export const AUTH0_NAMESPACE = process.env.REACT_APP_AUTH0_NAMESPACE
export const IS_DEV = process.env.REACT_APP_ENV === 'development'
export const IS_PROD = process.env.REACT_APP_ENV === 'production'
export const APP_DOMAIN = window.location.origin
export const SUPERADMIN_PERMS = ['ba-admin', 'wallet-superadmin']
