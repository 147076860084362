import { ExternalLink } from '../../theme'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { EllipsizedText } from "@bitaccess/bitaccess-ui"

export default function EllipsizedLink({
  id,
  href,
  text,
  padding,
  canCopy = true,
  showTooltip = true,
}: {
  href: string
  id: string
  text: any
  padding?: number
  canCopy?: boolean
  showTooltip?: boolean
}) {
  return (
    <ExternalLink href={href}>
      <div className="flex items-center break-all">
        <div className="inline-block align-middle mr-2">
          <ExternalLinkIcon className="text-gray-400 h-4 w-4" />
        </div>
        <div className="inline-block align-middle">
          <EllipsizedText
            id={id}
            text={text}
            padding={padding}
            canCopy={canCopy}
            showTooltip={showTooltip}
          />
        </div>
      </div>
    </ExternalLink>
  )
}
