import { truncate as trunc } from 'lodash'
import { WalletAttributes } from '../../state/wallet/types'
import CoinIcon from '../CoinIcon'
import { Badge } from '@bitaccess/bitaccess-ui'

export default function WalletLabel({
  wallet,
  labelClassName,
  iconSize = 6,
  truncate,
  truncateLength,
}: {
  wallet: WalletAttributes
  labelClassName?: string
  iconSize?: number
  truncate?: boolean
  truncateLength?: number
}) {
  const walletLabel = truncate
    ? trunc(wallet?.label, { length: truncateLength })
    : wallet.label
  return (
    <div className="flex flex-row items-center">
      <div>
        <CoinIcon
          className="mx-2 w-full"
          src={wallet?.asset?.logoUrl ?? ''}
          size={iconSize}
        />
      </div>
      <span className={labelClassName}>
        {walletLabel}
        {wallet?.networkType === 'testnet' && (
          <span className="ml-3">
            <Badge
              text="TESTNET"
              backgroundColor="bg-gray-100"
              textColor="text-yellow-600"
            />
          </span>
        )}
      </span>
    </div>
  )
}
