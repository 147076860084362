/* eslint-disable @typescript-eslint/no-empty-interface */
import { createAction } from '@reduxjs/toolkit'
import { AccountingHistoryRedux } from './types'

export const addTransactions = createAction<{
  allTxs: AccountingHistoryRedux
  page: number
  walletId: string
  count: number
  total: number
  limit: number
}>('transactions/addTransactions')
export const setLoadingTransactions = createAction<boolean>(
  'transactions/loading',
)
export const updateSelectedAsset = createAction<boolean>(
  'transactions/updateSelectedAsset',
)
export const updateVisibleColumns = createAction<string[]>(
  'transactions/updateVisibleColumns',
)

export const updateFilters = createAction<{ [label: string]: any }>(
  'transactions/updateFilters',
)
