import set from 'immutable-set'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function removeEmpty(obj: Record<string, unknown>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export function convertDateString(dateString: string): string {
  return new Date(Date.parse(dateString)).toLocaleString()
}

export function ellipsizeString(string: string, chars = 4): string {
  return `${string.substring(0, chars + 2)}...${string.substring(
    string.length - chars,
  )}`
}

export const renameKeys = (keysMap: { [oldKey: string]: string }, o: any) =>
  Object.keys(o).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: o[key] },
    }),
    {},
  )

export const handleEnterKeyPress = (
  e: React.KeyboardEvent<HTMLInputElement>,
  onEnter: () => void,
) => {
  if (e.key === 'Enter') {
    onEnter()
  }
}

export function generatePageRange(currentPage: number, lastPage: number) {
  const delta = 3
  const range = []
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(lastPage - 1, currentPage + delta);
    i += 1
  ) {
    range.push(i)
  }

  if (currentPage - delta > 2) {
    range.unshift('...')
  }
  if (currentPage + delta < lastPage - 1) {
    range.push('...')
  }

  range.unshift(1)
  if (lastPage !== 1) range.push(lastPage)

  return range
}

export function matchAndMoveToFrontOfArray(
  a: any[],
  fn: (e: any, i: any, a: any) => boolean,
) {
  const non_matches: any[] = []
  const matches = a.filter(function (e, i, a) {
    const match = fn(e, i, a)
    if (!match) non_matches.push(e)
    return match
  })
  return matches.concat(non_matches)
}

export function isTimeDifferenceInMinutesGreaterThan(
  time: number,
  differenceInMinutes: number,
): boolean {
  return (Date.now() - time) / 60000 > differenceInMinutes
}

export function capitalizeFirstLetter(string: string) {
  return string
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function getDateXMonthsAgo(monthsAgo: number) {
  const today = new Date()
  today.setMonth(today.getMonth() - monthsAgo)
  return today
}

export function sortArrayByDate(array: any[], property: string) {
  return array.sort(function (a, b) {
    return new Date(b[property]).getTime() - new Date(a[property]).getTime()
  })
}

export function clearAllLocalStorage() {
  localStorage.clear()
}

export function getFormattedDate(
  dateString: Date | string,
  type:
    | 'numbers'
    | 'words'
    | 'dayMonthHourMinute'
    | 'monthYear'
    | 'dayMonthYearHourMinute',
) {
  if (!dateString) return ''
  const date: Date = new Date(dateString)
  const hourAndMinute = `${date.getHours()}:${date.getMinutes()}`
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  switch (type) {
    case 'numbers':
      return month + '-' + day + '-' + year
    case 'words':
      return `${monthNames[parseInt(month) - 1]} ${day}, ${year}`
    case 'dayMonthHourMinute':
      return `${day} ${monthNames[parseInt(month) - 1]}, ${hourAndMinute}`
    case 'monthYear':
      return `${monthNames[parseInt(month) - 1]}, ${year}`
    case 'dayMonthYearHourMinute':
      return `${day} ${
        monthNames[parseInt(month) - 1]
      } ${year}, ${hourAndMinute}`
    default:
      return month + '-' + day + '-' + year
  }
}

export function getEnhancedFormattedDate(
  dateString: Date | string,
  dateStyle?: 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate',
  timeStyle?: 'shortTime' | 'mediumTime' | 'longTime' | 'fullTime',
  language?: 'en-CA' | 'en-US' | 'en-GB',
) {
  const date = new Date(dateString)
  language = language || 'en-CA'
  let dateOptions = {}
  // retrieve date/time style from parameter (i.e short | medium | long | full)
  const formattedDateStyle = dateStyle ? dateStyle.slice(0, -4) : undefined
  const formattedTimeStyle = timeStyle ? timeStyle.slice(0, -4) : undefined
  dateOptions = {
    dateStyle: formattedDateStyle,
    timeStyle: formattedTimeStyle,
  }
  return new Intl.DateTimeFormat(language, dateOptions).format(date)
}

export function getTimezoneISOString(dateString: string | Date): string {
  const tzoffset = new Date().getTimezoneOffset() * 60000
  const localISOTime = new Date(new Date(dateString).getTime() - tzoffset)
    .toISOString()
    .slice(0, -5)
  return localISOTime
}

export function addDayToDate(dateString: string, numOfDays = 1): Date {
  const date = new Date(dateString)
  date.setDate(date.getDate() + numOfDays)
  return date
}

export const multipleSet = (
  sets: { field: string; value: any; options?: any }[],
  currentState: any,
) => {
  let state = currentState
  sets.forEach((item) => {
    state = set(state, item.field, item.value)
  })
  return state
}

export function assertType(typeCodec: any, value: any, description = 'type') {
  const validation = typeCodec.decode(value)
  if (validation.isLeft()) {
    console.error(`Invalid ${description}`)
    return value
  }
  return validation.value
}
