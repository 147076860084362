/* eslint-disable react/display-name */
import { useEffect, useMemo, useState } from 'react'
import {
  DocumentType,
  PeriodType,
  useGetMonthlySummary,
  useSelectIsReportsLoading,
  useSelectReportsByWalletIdArray,
} from '../../state/report/hooks'
import { addDayToDate, capitalizeFirstLetter, getFormattedDate } from '../../utils'
import { ExternalLink } from '../../theme'
import Table from '../Table'
import { DownloadCloud } from 'react-feather'
import { Dropdown } from '../Dropdown'
import SelectedRow from '../SelectedRow'
import { groupBy } from 'lodash'

const REPORT_PERIODS: PeriodType[] = [
  'monthly',
  // { label: 'Quarterly Statement', documentType: 'pdf', period: 'quarterly' },
  // { label: 'Annual Statement', documentType: 'pdf', period: 'annual' },
]

export default function ReportsTable({ walletId }: { walletId: string }) {
  const getMonthlySummary = useGetMonthlySummary()
  const reports = useSelectReportsByWalletIdArray(walletId)
  const isLoading = useSelectIsReportsLoading()
  const [selectedReportPeriod, setSelectedReportPeriod] = useState(
    REPORT_PERIODS[0],
  )

  useEffect(() => {
    const handleGetReports = async () => {
      await Promise.all([
        getMonthlySummary({
          walletId,
          documentType: 'pdf',
          period: selectedReportPeriod,
        }),
        getMonthlySummary({
          walletId,
          documentType: 'csv',
          period: selectedReportPeriod,
        }),
      ])
    }
    handleGetReports()
  }, [selectedReportPeriod, walletId])

  const handleCreateTableRows: any = useMemo((): any => {
    return (
      reports &&
      Object.values(groupBy(reports, (b) => b.startDate))?.map(
        (groupedReports: any) => {
          return {
            column_1: {
              reports: groupedReports,
            },
            column_2: {
              reports: groupedReports,
            },
          }
        },
      )
    )
  }, [reports])
  return (
    <Table
      id="reports-table"
      columns={[
        {
          Header: 'Report Date',
          accessor: 'column_1',
          disableSortBy: true,
          width: 100,
          Cell: (e: any) => {
            // doing this to mitigate against timezone differences,
            // we typically show the reports in [Month, Year] format so it affects nothing 
            const reportDate = addDayToDate(e.value.reports[0].startDate,5)
            const date = getFormattedDate(reportDate, 'monthYear')
            return (
              <div className="flex flex-row gap-1.5">
                <div className="flex flex-row items-center">
                  <span>{date}</span>
                </div>
              </div>
            )
          },
        },
        {
          Header: '',
          accessor: 'column_2',
          disableSortBy: true,
          Cell: (e: any) => {
            return (
              <div className="flex flex-row gap-1.5 justify-end">
                {e.value.reports
                  .sort((a: any, b: any) =>
                    a.documentType == 'pdf'
                      ? -1
                      : b.documentType == 'pdf'
                      ? 1
                      : 0,
                  )
                  .map((report: any, i: number) => {
                    return (
                      <div
                        key={report.id}
                        className="flex flex-row items-center"
                      >
                        {i === 0 && (
                          <DownloadCloud className="h-4 w-4 text-gray-500 mr-2" />
                        )}
                        <ExternalLink href={report.fileUrl}>
                          {report.documentType === 'pdf'
                            ? 'Monthly Summary'
                            : 'Transaction Data'}
                        </ExternalLink>
                        {i === 0 && (
                          <span className="text-gray-300 ml-1.5">|</span>
                        )}
                      </div>
                    )
                  })}
              </div>
            )
          },
        },
      ]}
      data={handleCreateTableRows}
      tableHeader={true}
      rightTableHeader={
        <Dropdown
          text="Timespan:"
          secondaryText={capitalizeFirstLetter(selectedReportPeriod)}
          id="report-dropdown"
          dropdownPosition="right"
        >
          <div className="bg-white">
            {REPORT_PERIODS.map((period) => {
              const selected =
                period.toLowerCase() === selectedReportPeriod.toLowerCase()
              return (
                <div
                  key={period}
                  onClick={() => setSelectedReportPeriod(period)}
                >
                  <SelectedRow
                    value={capitalizeFirstLetter(period)}
                    selected={selected}
                  />
                </div>
              )
            })}
          </div>
        </Dropdown>
      }
      searchPlaceholder="Filter wallets..."
      showSearch={false}
      isLoading={isLoading}
      pagination={false}
    />
  )
}
