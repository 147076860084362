import { useEffect } from 'react'
import { useLogout } from '../../state/application/hooks'
import { Spinner } from '@bitaccess/bitaccess-ui'

export default function Logout() {
  const logout = useLogout()
  useEffect(() => {
    logout()
  }, [])
  return (
    <div className="h-full w-full">
      <div className="m-auto">
        <Spinner id="loading-general" />
      </div>
    </div>
  )
}
