/* eslint-disable react/display-name */
import { useEffect, useMemo } from 'react'
import ExternalLink from '../ExternalLink'
import {
  useGetBalanceBreakdown,
  useSelectBalanceByIdArray,
  useSelectIsLoadingBalanceBreakdown,
} from '../../state/balanceBreakdown/hooks'
import Table from '../Table'
import EllipsizedLink from '../EllipisizedLink'
import BalanceAmount from '../BalanceAmount'

export default function BalanceBreakdownTable({
  walletId,
}: {
  walletId: string
}) {
  const getBalanceBreakdown = useGetBalanceBreakdown()
  const balancesByAddress = useSelectBalanceByIdArray(walletId)
  const isLoading = useSelectIsLoadingBalanceBreakdown()

  useEffect(() => {
    const handleFetch = async () => {
      await getBalanceBreakdown(walletId)
    }
    handleFetch()
  }, [])

  const handleCreateTableRows: any = useMemo((): any => {
    if (isLoading) return []
    return (
      balancesByAddress &&
      balancesByAddress?.map(
        ({
          address,
          totalBalance,
          confirmedBalance,
          unconfirmedBalance,
          explorerUrl,
          asset,
        }: any) => {
          return {
            address: { address, explorerUrl },
            totalBalance: { totalBalance, asset },
            unconfirmedBalance: { unconfirmedBalance, asset },
            confirmedBalance: { confirmedBalance, asset },
          }
        },
      )
    )
  }, [isLoading, balancesByAddress])

  return (
    <Table
      id="balance-breakdown-table"
      columns={[
        {
          Header: 'Address',
          id: 'address',
          accessor: (d: any) => d.address?.address,
          Cell: (e: any) => (
            <div className="break-all">
              {e.value ? (
                <EllipsizedLink
                  href={e.cell.row.original?.address?.explorerUrl}
                  id={e.cell.row.original?.address?.address}
                  text={e.cell.row.original?.address?.address}
                  padding={25}
                  canCopy={false}
                />
              ) : (
                '-'
              )}
            </div>
          ),
          disableSortBy: false,
        },
        {
          Header: 'Total Balance',
          id: 'totalBalance',
          accessor: (d: any) => d.totalBalance?.totalBalance,
          Cell: (e: any) => {
            return (
              <BalanceAmount
                symbol={e.cell.row.original?.totalBalance?.asset?.symbol}
                decimals={e.cell.row.original?.totalBalance?.asset?.decimals}
                amount={e.cell.row.original?.totalBalance?.totalBalance}
              />
            )
          },
          disableSortBy: false,
          maxWidth: 60,
        },
        {
          Header: 'Unconfirmed Balance',
          id: 'unconfirmedBalance',
          accessor: (d: any) => d.unconfirmedBalance?.unconfirmedBalance,
          Cell: (e: any) => {
            return (
              <BalanceAmount
                symbol={e.cell.row.original?.unconfirmedBalance?.asset?.symbol}
                decimals={
                  e.cell.row.original?.unconfirmedBalance?.asset?.decimals
                }
                amount={
                  e.cell.row.original?.unconfirmedBalance?.unconfirmedBalance
                }
              />
            )
          },
          disableSortBy: false,
          maxWidth: 60,
        },
        {
          Header: 'Confirmed Balance',
          id: 'confirmedBalance',
          accessor: (d: any) => d.confirmedBalance?.confirmedBalance,
          Cell: (e: any) => {
            return (
              <BalanceAmount
                symbol={e.cell.row.original?.confirmedBalance?.asset?.symbol}
                decimals={
                  e.cell.row.original?.confirmedBalance?.asset?.decimals
                }
                amount={e.cell.row.original?.confirmedBalance?.confirmedBalance}
              />
            )
          },
          disableSortBy: false,
          maxWidth: 60,
        },
      ]}
      data={handleCreateTableRows}
      pagination={false}
      showSearch={true}
      searchPlaceholder="Search addresses..."
      isLoading={isLoading}
    />
  )
}
