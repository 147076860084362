import { CheckIcon } from '@heroicons/react/outline'
export default function SelectedRow({
  value,
  selected,
}: {
  value: string
  selected: boolean
}) {
  return (
    <div
      className={`${
        selected ? 'text-yellow-900 bg-yellow-100' : 'text-gray-900'
      } hover:text-yellow-900 hover:bg-yellow-100 no-underline cursor-pointer select-none relative py-2 pl-4 pr-4 border-b border-gray-100 flex justify-start`}
    >
      <span
        className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
      >
        {value}
      </span>
      {selected ? (
        <span
          className={`${selected ? 'text-yellow-600' : 'text-yellow-600'}
    absolute inset-y-0 right-4 flex items-center pl-3`}
        >
          <CheckIcon className="w-5 h-5" aria-hidden="true" />
        </span>
      ) : null}
    </div>
  )
}
