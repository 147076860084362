import { useHasPermission } from '../../contexts/Permissions'

export default function PermissionRestricted({
  perms,
  children,
  fallback = <></>,
}: {
  perms: string[]
  children: any
  fallback?: React.ReactNode
}) {
  const allowed = useHasPermission(perms)
  if (allowed) {
    return <>{children}</>
  }
  return <>{fallback}</>
}
