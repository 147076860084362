import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import {
  updateGroup,
  updateSelectedGroup,
  updateLoadingGroups,
  updateGroupWallets,
  clearWalletIds,
  addGroups,
} from './actions'
import { GroupRedux } from './types'

export interface GroupState {
  groups: GroupRedux
  selectedGroup: string
  lastUpdated: number | null
  isLoading: boolean
}

const initialState: GroupState = {
  groups: {},
  selectedGroup: '',
  lastUpdated: null,
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addGroups, (state, action) => {
      const group = action.payload
      let newState = set(state, 'groups', group)
      newState = set(newState, 'lastUpdated', Date.now())
      return newState
    })
    .addCase(updateGroup, (state, action) => {
      const group = action.payload.group
      const groupName = group.name
      let newState = set(
        state,
        `groups.${groupName}`,
        { ...state.groups[groupName], ...group },
        { safe: true },
      )
      newState = set(newState, 'lastUpdated', Date.now())
      return newState
    })
    .addCase(updateSelectedGroup, (state, action) => {
      const newState = set(state, 'selectedGroup', action.payload)
      return newState
    })
    .addCase(updateLoadingGroups, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    })
    .addCase(updateGroupWallets, (state, action) => {
      const { group, walletId } = action.payload
      const groupName = group.name
      const groupWallets = state.groups[groupName]?.walletIds || []
      if (groupWallets.indexOf(walletId) < 0) {
        const newState = set(state, `groups.${groupName}.walletIds`, [
          ...groupWallets,
          walletId,
        ])
        return newState
      }
    })
    .addCase(clearWalletIds, (state, action) => {
      const newState = set(state, `groups.${action.payload}.walletIds`, [])
      return newState
    }),
)
