import React from 'react'
import { Switch as SwitchComponent } from '@headlessui/react'

const Switch = (props: SwitchProps) => {
  const handleSwitch = (value: boolean) => {
    props.onChange(value)
  }

  return (
    <SwitchComponent.Group>
      <div className='flex items-center'>
        {props.label && (
          <SwitchComponent.Label className='mr-4'>
            Enable notification
          </SwitchComponent.Label>
        )}
        <SwitchComponent
          checked={props.checked}
          onChange={handleSwitch}
          className={`${
            props.checked ? 'bg-green-400' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
        >
          <span
            className={`${
              props.checked ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </SwitchComponent>
      </div>
    </SwitchComponent.Group>
  )
}

export interface SwitchProps {
  id: string
  onChange: (isEnabled: boolean) => void
  label?: React.ReactNode
  checked: boolean
}

Switch.defaultProps = {
  disabled: false,
}

export default Switch
