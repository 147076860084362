import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { setLoadingPolicy } from './actions'

export interface PolicyState {
  isLoading: boolean
}

const initialState: PolicyState = {
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setLoadingPolicy, (state, action) => {
    const newState = set(state, 'isLoading', action.payload)
    return newState
  }),
)
