import { CheckIcon } from '@heroicons/react/solid'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  useUpdateSetupStep,
  useGetNewWalletData,
} from '../../state/wallet/hooks'

interface WalletProgressSteps {
  id: number
  name: string
}

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`

const StepContainer = styled.div<{ width?: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #fff1e6;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #d97706;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const StepStyle = styled.div<{ step?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${({ step }) => (step === 'completed' ? '#D97706' : '#fff1e6')};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 19px;
  color: #fff1e6;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #d97706;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`

let stepsData: WalletProgressSteps[] = []

const NewWalletSteps: FunctionComponent<{
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
}> = ({ activeStep, setActiveStep }) => {
  const [steps, setSteps] = useState<WalletProgressSteps[]>(stepsData)
  const totalSteps = steps.length
  const updateSetupStep = useUpdateSetupStep()
  const newWalletData = useGetNewWalletData()

  const updatedStep = (id: number) => {
    updateSetupStep(id)
    setActiveStep(id)
  }

  useEffect(() => {
    if (newWalletData.walletType === 'basic') {
      stepsData = [
        { id: 1, name: 'Type' },
        { id: 2, name: 'Details' },
        { id: 3, name: 'Backup' },
        { id: 4, name: 'Complete' },
      ]
    } else {
      stepsData = [
        { id: 1, name: 'Type' },
        { id: 2, name: 'Details' },
        { id: 3, name: 'Complete' },
      ]
    }
    setSteps(stepsData)
  }, [activeStep])

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`

  return (
    <>
      <nav className="md:hidden -mt-24 mb-2">
        <MainContainer>
          <StepContainer width={width}>
            {steps.map(({ id, name }) => (
              <StepWrapper key={id}>
                <div className="absolute -mt-8 flex justify-center items-center w-[95%] text-gray-800 text-sm">
                  {name}
                </div>
                <StepStyle step={activeStep >= id ? 'completed' : 'incomplete'}>
                  {activeStep > id ? (
                    <div onClick={() => updatedStep(id)} className="mb-1">
                      <CheckMark>L</CheckMark>
                    </div>
                  ) : (
                    <StepCount>{id}</StepCount>
                  )}
                </StepStyle>
              </StepWrapper>
            ))}
          </StepContainer>
        </MainContainer>
      </nav>
      <nav
        aria-label="Progress"
        className="hidden md:block mx-auto justify-center"
      >
        <ol
          role="list"
          className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
        >
          {steps.map(({ id, name }, stepIdx) => (
            <li key={id} className="relative md:flex-1 md:flex">
              {activeStep > id ? (
                <div
                  className="group flex items-center w-full cursor-pointer"
                  onClick={() => updatedStep(id)}
                >
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-yellow-600 rounded-full group-hover:bg-yellow-700">
                      <CheckIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      {name}
                    </span>
                  </span>
                </div>
              ) : activeStep === id ? (
                <div
                  className="px-6 py-4 flex items-center text-sm font-medium"
                  aria-current="step"
                >
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-yellow-600 rounded-full">
                    <span className="text-yellow-600">{id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-yellow-600">
                    {name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="hidden md:block absolute top-0 right-0 h-full w-5"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}

export default NewWalletSteps
