import { createAction } from '@reduxjs/toolkit'
import { WalletAttributes, WalletRedux, NewWalletAttributes } from './types'

export const addWallets = createAction<WalletRedux>('wallets/add')
export const updateWallet = createAction<WalletAttributes>('wallets/update')
export const setLoadingWallets = createAction<boolean>('wallets/loading')
export const setHideWalletBalances = createAction<boolean>(
  'wallets/setHideWalletBalances',
)
export const setWalletSetupStep = createAction<number>(
  'wallets/setWalletSetupStep',
)
export const setNewWalletData = createAction<NewWalletAttributes>(
  'wallets/setNewWalletData',
)
