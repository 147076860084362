import BitaccessWheel from '../../assets/bitaccess-wheel.png'
import BitaccessName from '../../assets/bitaccess-name.png'
import { useSelectLoadingText } from '../../state/application/hooks'

export default function LoadingFullscreen() {
  const loadingText = useSelectLoadingText()
  return (
    <div
      style={{ zIndex: 999999 }}
      className="h-screen w-screen flex justify-center align-center absolute bg-bitaccess-blue"
    >
      <div className="m-auto">
        <div className="m-0">
          <img
            style={{ width: 37 }}
            src={BitaccessWheel}
            alt="Bitaccess Logo"
            className="pointer-events-none inline-block rotateAnimation"
          />
          <img
            style={{ width: 112, marginLeft: 2.5 }}
            src={BitaccessName}
            alt="Bitaccess Logo"
            className="pointer-events-none inline"
          />
          <div className="text-white text-center pt-5 opacity-40">
            <span>{loadingText}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
