import { useAuth0 } from '@auth0/auth0-react'
import React, { ReactElement, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

export default function AuthenticatedRenderRoute({
  ...args
}: RouteProps): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: '/wallets',
        },
      })
    })()
  }, [isLoading, isAuthenticated, loginWithRedirect])

  return isAuthenticated ? (
    <Route {...args} />
  ) : (
    <div>Redirecting you to the login page.</div>
  )
}
