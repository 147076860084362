import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DataRows from '../../components/DataRows'
import { Modal } from '@bitaccess/bitaccess-ui'
import { useSelectLogById } from '../../state/activity/hooks'
import ReactJson from 'react-json-view'

export default function ActivityDetailModal({parentRoute}: {parentRoute: string}) {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(true)
  const { id } = useParams<{ id: string }>()
  const log = useSelectLogById(id)

  const handleOnClose = () => {
    history.push(parentRoute)
  }

  return (
    <Modal
      id={'transaction-history-modal'}
      title={'Activity Log'}
      modalClassName="max-w-screen-2xl md:w-1/2 w-screen h-10/12 m-0"
      body={
        <div style={{ maxHeight: 450 }} className="mt-8">
          <ReactJson src={log} />
        </div>
      }
      showCloseButton={true}
      footer={<></>}
      open={open}
      setOpen={setOpen}
      onClose={handleOnClose}
      scrollBody={true}
    />
  )
}
