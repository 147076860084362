import {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Button } from '@bitaccess/bitaccess-ui'
import {
  useGetNewWalletData,
  useUpdateNewWalletData,
  useUpdateSetupStep,
} from '../../state/wallet/hooks'
import { NewWalletAttributes } from '../../state/wallet/types'

const NewWalletDetails: FunctionComponent<{
  setActiveStep: Dispatch<SetStateAction<number>>
}> = ({ setActiveStep }) => {
  const updateNewWalletData = useUpdateNewWalletData()
  const updateSetupStep = useUpdateSetupStep()
  const newWalletData = useGetNewWalletData()
  const [detailsBody, setDetailsBody] =
    useState<NewWalletAttributes>(newWalletData)
  const [isValid, setIsValid] = useState<boolean>(false)

  const detailsDataChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    setDetailsBody({ ...detailsBody, [name]: value })
  }

  useEffect(() => {
    if (detailsBody.label) {
      setIsValid(true)
    }
  }, [detailsBody])

  useEffect(() => {
    const defaultValues: NewWalletAttributes = {
      asset: 'BTC',
      org: 'personal',
    }
    setDetailsBody({ ...detailsBody, ...defaultValues })
  }, [])

  return (
    <div className="w-full">
      <form className="space-y-8">
        <div className="flex space-y-8 items-center justify-center">
          <div className="pt-8 w-[95%] md:w-[70%] lg:w-[40%] xl:w-[30%]">
            <div className="w-full text-center">
              <h3 className="text-xl leading-6 font-bold text-gray-900">
                Wallet Details
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Enter details for your new wallet
              </p>
            </div>
            <div className="mt-6 space-y-4 mb-10">
              <div>
                <label
                  htmlFor="wallet-label"
                  className="block text-sm font-medium text-gray-700"
                >
                  Label
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="label"
                    id="wallet-label"
                    value={detailsBody.label || ''}
                    onChange={detailsDataChangeHandler}
                    onBlur={detailsDataChangeHandler}
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="wallet-group"
                  className="block text-sm font-medium text-gray-700"
                >
                  Organization
                </label>
                <div className="mt-1">
                  <select
                    id="wallet-group"
                    name="org"
                    value={detailsBody.org}
                    onChange={detailsDataChangeHandler}
                    onBlur={detailsDataChangeHandler}
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="personal">Personal</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="wallet-asset"
                  className="block text-sm font-medium text-gray-700"
                >
                  Network
                </label>
                <div className="mt-1">
                  <select
                    id="walletAsset"
                    name="asset"
                    autoComplete="wallet-asset"
                    value={detailsBody.asset}
                    onChange={detailsDataChangeHandler}
                    onBlur={detailsDataChangeHandler}
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="BTC">Bitcoin</option>
                    <option value="ETH">Ethereum</option>
                  </select>
                </div>
              </div>

              <div className="flex space-x-3 my-6 justify-center items-center">
                <Checkbox
                  name={`networkType`}
                  id={`new-wallet-testnet-checkbox`}
                  defaultChecked={detailsBody.networkType === 'testnet'}
                  value={
                    detailsBody.networkType === 'testnet'
                      ? 'mainnet'
                      : 'testnet'
                  }
                  onChange={detailsDataChangeHandler}
                />
                <label
                  htmlFor="blockchain-network"
                  className="block text-sm font-medium text-gray-700"
                >
                  Use testnet?
                </label>
              </div>

              <div className="flex justify-center space-x-6 pt-10">
                <Link to={`/wallets`}>
                  <Button
                    id="new-wallet-cancel-button"
                    type="custom"
                    text="Cancel"
                    onClick={() => {
                      updateNewWalletData({})
                      updateSetupStep(1)
                    }}
                    className="inline-flex bg-white items-center px-4 py-2 border shadow-sm text-sm font-bold rounded-sm text-gray-700 hover:bg-gray-50 focus:outline-none"
                  />
                </Link>
                <Button
                  id="new-wallet-continue-button"
                  type="custom"
                  disabled={!isValid}
                  onClick={() => {
                    setActiveStep(3)
                    updateSetupStep(3)
                    updateNewWalletData(detailsBody)
                  }}
                  text="Continue"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-bold rounded-sm text-white bg-yellow-600 hover:bg-yellow-600/90 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewWalletDetails
