import { useCallback, useMemo } from 'react'
import { batch, useSelector } from 'react-redux'
import { addSupportedAssets, setAssetLoadingState } from './actions'
import { useGetAccessToken } from '../user/hooks'
import { fetchSupportedAssets } from '../../services/GringottsClient'
import { AppState, useThunkDispatch } from '../index'
import { isTimeDifferenceInMinutesGreaterThan } from '../../utils'
import useToast from '../../services/toast'
import { AssetRedux } from './types'
import { AssetAttributes } from '@bitaccess/gringotts-common'

export function useGetSupportedAssets(): () => Promise<void> {
  const dispatch = useThunkDispatch()
  const shouldUpdateAssets = useShouldUpdateAssets()
  const getAccessToken = useGetAccessToken()
  const toast = useToast()
  return useCallback(async () => {
    if (!shouldUpdateAssets(10)) {
      return
    }
    const accessToken = await getAccessToken()
    dispatch(setAssetLoadingState(true))
    try {
      const supportedAssets = await fetchSupportedAssets(accessToken)
      batch(async () => {
        const allAssets: AssetRedux = {}
        supportedAssets.forEach((asset) => {
          allAssets[asset.id] = asset
        })
        await dispatch(addSupportedAssets(allAssets))
        dispatch(setAssetLoadingState(false))
      })
    } catch (err) {
      toast('error', 'Unable to load supported assets.')
      dispatch(setAssetLoadingState(false))
      return
    }
  }, [dispatch, getAccessToken, shouldUpdateAssets, toast])
}

export function useSelectAssets(): AssetRedux {
  const assets = useSelector<AppState, AppState['asset']['supportedAssets']>(
    (state) => state.asset.supportedAssets,
  )
  return assets
}

export function useSelectAssetsLastUpdated(): number | undefined {
  const lastUpdated = useSelector<AppState, AppState['asset']['lastUpdated']>(
    (state) => state.asset.lastUpdated,
  )
  return lastUpdated
}

export function useShouldUpdateAssets(): (minutes: number) => boolean {
  const lastUpdated = useSelectAssetsLastUpdated()
  return useCallback(
    (minutes: number) => {
      if (!lastUpdated) return true
      return isTimeDifferenceInMinutesGreaterThan(lastUpdated, minutes)
    },
    [lastUpdated],
  )
}

export function useSelectAssetsIsLoading(): boolean {
  const isLoading = useSelector<AppState, AppState['asset']['isLoading']>(
    (state) => state.asset.isLoading,
  )
  return isLoading
}

export function useSelectAssetsArray() {
  const assets = useSelectAssets()
  return useMemo(() => Object.values(assets).filter((a) => a.logoUrl), [assets])
}

export function useSelectAsset(id: number): AssetAttributes {
  const assets = useSelectAssets()
  const asset = assets[id]
  return asset
}

export function useSelectAssetBySymbol(): (
  symbol: string,
) => AssetAttributes | undefined {
  const assets = useSelectAssetsArray()
  return useCallback(
    (symbol: string) => {
      const asset = assets.find((a) => a.symbol === symbol)
      return asset
    },
    [assets],
  )
}

export function useSelectAssetCallback(): (id: number) => AssetAttributes {
  const assets = useSelectAssets()
  return useCallback(
    (id: number) => {
      const asset = assets[id]
      return asset
    },
    [assets],
  )
}

export function useExtendAsset(): (object: any, property: string) => any {
  const getAssetById = useSelectAssetCallback()
  const getAssetBySymbol = useSelectAssetBySymbol()
  return useCallback(
    (object: any, property: string) => {
      const asset =
        getAssetById(object?.[property]) || getAssetBySymbol(object?.[property])
      return {
        ...object,
        asset,
      }
    },
    [getAssetById, getAssetBySymbol],
  )
}

export function useExtendAssetArray(): (
  array: any[],
  property: string,
) => any[] {
  const extendAsset = useExtendAsset()
  return useCallback(
    (array: any[] = [], property: string) => {
      return array.map((item: any) => {
        return extendAsset(item, property)
      })
    },
    [extendAsset],
  )
}
