import { createAction } from '@reduxjs/toolkit'
import { BalanceBreakdownAddress } from './types'

export const addBalanceBreakdown = createAction<{
  walletId: string
  balances: BalanceBreakdownAddress
}>('balanceBreakdown/add')
export const setLoadingBalances = createAction<boolean>(
  'balanceBreakdown/loading',
)
