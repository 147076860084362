/* eslint-disable react/display-name */
/* eslint-disable */
// @ts-nocheck

import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react'
import {
  useTable,
  useRowSelect,
  Hooks,
  HeaderProps,
  CellProps,
  useFlexLayout,
  useSortBy,
  useGlobalFilter,
} from 'react-table'
import * as S from './styles'
import { ReactComponent as SortAscendingIcon } from '../../assets/sort-ascending-icon.svg'
import { ReactComponent as SortDescendingIcon } from '../../assets/sort-descending-icon.svg'
import { Spinner, Checkbox } from "@bitaccess/bitaccess-ui"
/**
 * Table UI component
 */
const Table = forwardRef((props: TableProps, ref: any): JSX.Element => {
  const selectionHook = (hooks: Hooks<any>) => {
    hooks.allColumns.push((columns) => [
      {
        id: '_selector',
        disableResizing: true,
        disableGroupBy: true,
        width: 10,
        Header: ({
          toggleAllRowsSelected,
          isAllRowsSelected,
        }: HeaderProps<any>) => (
          <S.StyledTableCheckboxWrapper>
            <Checkbox
              id={`${props.id}_header_checkbox`}
              className={props.parentHeaderCheckboxRef ? 'hidden' : ''}
              checked={isAllRowsSelected}
              ref={props.headerCheckboxRef}
              onChange={() => {
                toggleAllRowsSelected(
                  props.parentHeaderCheckboxRef?.current?.checked,
                )
              }}
            />
          </S.StyledTableCheckboxWrapper>
        ),
        Cell: ({ row }: CellProps<any>) => {
          return (
            <S.StyledTableCheckboxWrapper>
              <Checkbox
                id={`${props.id}_${row.index}_checkbox`}
                checked={row.isSelected}
                onChange={() => {
                  const ref = props.parentHeaderCheckboxRef
                  if (ref && ref.current) {
                    ref.current.checked = false
                  }
                  row.toggleRowSelected()
                }}
              />
            </S.StyledTableCheckboxWrapper>
          )
        },
      },
      ...columns,
    ])
  }

  let tableHooks: any[] = [useFlexLayout, useGlobalFilter, useSortBy]
  if (props.useRowSelect) {
    tableHooks = [...tableHooks, useRowSelect, selectionHook]
  }

  const instance = useTable(
    {
      ...props,
      columns: props.columns,
      data: props.data,
      autoResetSelectedRows: false,
      initialState: {
        hiddenColumns: props.columns
          .filter((col) => {
            return col.show === false
          })
          .map((col) => {
            return col.id || col.accessor
          }),
      },
    },
    ...tableHooks,
  )

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    isAllRowsSelected,
  } = instance

  useImperativeHandle(ref, () => instance)

  const setSelectedRows = useMemo(() => {
    return props.setSelectedRows
  }, [props.setSelectedRows])

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original))
    }

    if (isAllRowsSelected) {
      const ref = props.parentHeaderCheckboxRef
      if (ref && ref.current) {
        ref.current.checked = true
      }
    }
  }, [
    selectedFlatRows,
    setSelectedRows,
    props.parentHeaderCheckboxRef,
    isAllRowsSelected,
  ])

  useEffect(() => {
    // When additional data is loaded to the table and the selectAll checkbox
    // is enabled, we need toggleAllRowsSelected(true) so that the newly
    // added rows are selected as well
    if (
      props.parentHeaderCheckboxRef?.current?.checked ||
      props.headerCheckboxRef?.current?.checked
    ) {
      toggleAllRowsSelected(true)
    }
  }, [
    props.data,
    toggleAllRowsSelected,
    props.parentHeaderCheckboxRef,
    props.headerCheckboxRef,
  ])

  return (
    <S.StyledTable id={props.id} {...getTableProps()}>
      <S.StyledTableHeader id={`${props.id}_table_header`}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <S.StyledHeaderCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span className="justify-end">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <S.SortIcon>
                        <SortDescendingIcon />
                      </S.SortIcon>
                    ) : (
                      <S.SortIcon>
                        <SortAscendingIcon />
                      </S.SortIcon>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </S.StyledHeaderCell>
            ))}
            {props.lastThComponent && props.lastThComponent}
          </tr>
        ))}
      </S.StyledTableHeader>
      <S.StyledTableBody id={`${props.id}_table_body`} {...getTableBodyProps()}>
        {props.isLoading ? (
          <Spinner id={`${props.id}_table_spinner`} />
        ) : rows.length === 0 ? (
          <tr>
            <S.StyledBodyCell>No results found</S.StyledBodyCell>
          </tr>
        ) : (
          rows.map((row) => {
            prepareRow(row)
            return (
              <tr className="rt-row" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <S.StyledBodyCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </S.StyledBodyCell>
                  )
                })}
              </tr>
            )
          })
        )}
      </S.StyledTableBody>
    </S.StyledTable>
  )
})

export interface TableProps {
  id: string
  columns: any[]
  data: Object[]
  isLoading?: boolean
  useRowSelect?: boolean
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>
  globalSearchQuery?: string
  headerCheckboxRef?: React.RefObject<HTMLInputElement>
  parentHeaderCheckboxRef?: React.RefObject<HTMLInputElement>
  lastThComponent?: React.ReactNode
}

Table.defaultProps = {
  columns: [],
  data: [],
  isLoading: false,
}

export default Table
