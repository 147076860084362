import React from 'react'
import Breadcrumbs from '../Breadcrumbs'

export default function PageLayout({
  pageTitle,
  headerContent,
  children,
  headingBottomMargin = 8,
  breadcrumbs,
}: {
  pageTitle: React.ReactNode
  headerContent?: React.ReactNode
  children: React.ReactNode
  headingBottomMargin?: number
  breadcrumbs?: { name: string; href: string }[]
}) {
  return (
    <>
      <div className="flex flex-col w-full px-4 md:px-16">
        {breadcrumbs && (
          <div className="pb-6">
            <Breadcrumbs pages={breadcrumbs} />
          </div>
        )}
        <div
          className={`flex flex-wrap flex-1 md:justify-between mt-0 md:mt-0 items-center px-3 md:px-0 mb-4 md:mb-${headingBottomMargin}`}
        >
          <div className="w-full md:w-auto">
            <h1
              className={`text-3xl mb-4 md:mb-0 w-full font-bold text-gray-800`}
            >
              {pageTitle}
            </h1>
          </div>
          <div>{headerContent}</div>
        </div>
        {children}
      </div>
    </>
  )
}
