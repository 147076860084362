import _ from 'lodash'
import { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { addBalanceBreakdown, setLoadingBalances } from './actions'
import { useGetAccessToken } from '../user/hooks'
import { fetchWalletBalanceBreakdown } from '../../services/GringottsClient'
import { AppState, useThunkDispatch } from '../index'
import { assertType, isTimeDifferenceInMinutesGreaterThan } from '../../utils'
import {
  Address,
  BalanceBreakdownAddress,
  BalanceBreakdownRedux,
} from './types'
import { toBigNumber } from '../../utils/BigNumber'
import { useExtendAsset } from '../asset/hooks'
import { useSelectNetwork } from '../application/hooks'
import { useSelectSelectedGroup } from '../group/hooks'

export function useGetBalanceBreakdown(): (walletId: string) => Promise<void> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useThunkDispatch()
  const networkType = useSelectNetwork()
  const selectedGroup = useSelectSelectedGroup()
  return useCallback(
    async (walletId: string) => {
      if (!selectedGroup) return
      try {
        dispatch(setLoadingBalances(true))
        const accessToken = await getAccessToken()
        const result = await fetchWalletBalanceBreakdown(
          accessToken,
          walletId,
          {
            params: { networkType, returnUtxos: false, addressBreakdown: true },
          },
          selectedGroup,
        )
        const addresses = result.addresses
        for (const key in addresses) {
          addresses[key].address = key
          addresses[key] = assertType(Address, addresses[key])
        }
        dispatch(addBalanceBreakdown({ walletId, balances: addresses }))
        dispatch(setLoadingBalances(false))
      } catch (err) {
        dispatch(setLoadingBalances(false))
        console.log('error fetching balances', err)
      }
    },
    [dispatch, getAccessToken, networkType, selectedGroup],
  )
}

export function useSelectBalances(): BalanceBreakdownRedux {
  const balances = useSelector<
    AppState,
    AppState['balanceBreakdown']['balances']
  >((state) => state.balanceBreakdown.balances, shallowEqual)
  return balances
}

export function useSelectBalanceById(
  walletId: string,
): BalanceBreakdownAddress {
  const balances = useSelectBalances()
  return balances[walletId] ?? {}
}

export function useSelectBalanceByIdArray(walletId: string): Address[] {
  const balancesById = useSelectBalanceById(walletId)
  const balances = Object.values(balancesById) || []
  const combineBalances = useCombineBalances()
  return combineBalances(balances) || []
}

export function useSelectBalancesLastUpdated(): number | undefined {
  const lastUpdated = useSelector<
    AppState,
    AppState['balanceBreakdown']['lastUpdated']
  >((state) => state.balanceBreakdown.lastUpdated)
  return lastUpdated
}

export function useShouldUpdateBalance(): (minutes: number) => boolean {
  const lastUpdated = useSelectBalancesLastUpdated()
  return useCallback(
    (minutes: number) => {
      if (!lastUpdated) return true
      return isTimeDifferenceInMinutesGreaterThan(lastUpdated, minutes)
    },
    [lastUpdated],
  )
}

export function useSelectIsLoadingBalanceBreakdown(): boolean {
  const isLoading = useSelector<
    AppState,
    AppState['balanceBreakdown']['isLoading']
  >((state) => state.balanceBreakdown.isLoading)
  return isLoading
}

export function useCombineBalances(): (balances: any) => any {
  const extendAsset = useExtendAsset()
  return useCallback(
    (balances: any) => {
      return balances?.map((balance: any) => {
        const totalBalance = toBigNumber(balance?.confirmedBalance)
          .plus(balance?.unconfirmedBalance)
          .toNumber()
        return extendAsset(
          {
            totalBalance,
            ...balance,
          },
          'assetSymbol',
        )
      })
    },
    [extendAsset],
  )
}

export function useExtendTotalBalances(): (balances: []) => any {
  const combineBalance = useCombineBalances()
  return useCallback(
    (balances: any) => {
      return combineBalance(balances)
    },
    [combineBalance],
  )
}
