import React from 'react'
import _ from 'lodash'
import { Dropdown } from '../Dropdown'
import WalletListSearch from '../WalletListSearch'
import { useSelectAreWalletsLoading } from '../../state/wallet/hooks'
import { Spinner } from "@bitaccess/bitaccess-ui"

export default function WalletDropdown({
  onSelect,
  selectedWallet,
}: {
  onSelect: (asset: any) => void
  selectedWallet: any
}) {
  const isLoading = useSelectAreWalletsLoading()
  return (
    <Dropdown
      id={'wallet-dropdown'}
      text={isLoading ? 'Loading...' : 'Wallet:'}
      secondaryText={
        isLoading
          ? ''
          : selectedWallet && selectedWallet?.label
          ? `${_.truncate(selectedWallet?.label, { length: 20 })}`
          : 'None Selected'
      }
    >
      <div className="bg-white">
        {isLoading ? (
          <Spinner id="wallet-dropdown-spinner" />
        ) : (
          <WalletListSearch
            onSelect={onSelect}
            selectedWallet={selectedWallet}
          />
        )}
      </div>
    </Dropdown>
  )
}
