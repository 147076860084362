import { Component, ErrorInfo } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

interface IProps {
  redirectUrl?: string
}
class ErrorBoundary extends Component<IProps> {
  state = {
    hasError: false,
    redirect: false,
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    ReactGA.event({
      category: 'Error Boundary',
      action: `${Error}`,
    })
    setTimeout(() => this.setState({ redirect: true }), 10000)
    console.error('ErrorBoundary caught an error', error, info)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.props.redirectUrl || '/'} />
    } else if (this.state.hasError) {
      return (
        <h2>
          There was an error.{' '}
          <Link to={this.props.redirectUrl || '/'}>Click here</Link> to go back
          to the homepage. You will be automatically redirected after 10
          seconds.
        </h2>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
