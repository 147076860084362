import { useEffect, useState } from 'react'
import PageLayout from '../../components/PageLayout'
import { useParams, useHistory } from 'react-router-dom'
import {
  useSelectSelectedGroup,
  useUpdateUserSelectedGroup,
} from '../../state/group/hooks'
import { useSelectedGroupWalletsArray } from '../../state/wallet/hooks'
import TransactionHistoryTable from '../../components/TransactionHistoryTable'

export default function RecentTransaction() {
  const history = useHistory()
  const selectedGroup = useSelectSelectedGroup()
  const updateSelectedGroup = useUpdateUserSelectedGroup()
  const { groupId } = useParams<{ groupId: string }>()
  const wallets = useSelectedGroupWalletsArray()
  const [selectedWallet, setSelectedWallet] = useState<any>(wallets[0])

  useEffect(() => {
    if (groupId !== selectedGroup && groupId) {
      updateSelectedGroup(groupId)
    }
  }, [])

  useEffect(() => {
    if (!groupId && selectedGroup) {
      history.replace({
        pathname: `/transactions/${selectedGroup}`,
        search: history.location.search,
      })
    }
  }, [groupId, history, selectedGroup])

  return (
    <PageLayout pageTitle="Transaction History">
      <TransactionHistoryTable
        selectedWalletId={selectedWallet.id}
        showSelectWalletDropdown={true}
        selectWallet={setSelectedWallet}
      />
    </PageLayout>
  )
}
