import tw from 'twin.macro'
import styled from 'styled-components'

export const StyledTable = tw.table`
  w-full
  divide-y
  divide-gray-200
  overflow-y-scroll
  overflow-x-visible
`

export const StyledTableHeader = tw.thead`
  from-gray-100 
  to-gray-50 
  bg-gradient-to-tl
  sticky
  top-0
  z-10
`

export const StyledHeaderCell = tw.th`
  px-5
  pt-2
  pb-1.5
  text-left
  text-xs
  font-semibold
  text-gray-700
  uppercase
  tracking-wider
  flex
  select-none
  items-center
`

export const StyledTableBody = tw.tbody`
  bg-white
  divide-y
  divide-gray-100
`

export const StyledBodyRow = styled.tr`
  &:nth-child(even) {
    background: #f9fafb;
  }
`

export const StyledBodyCell = tw.td`
  px-5
  py-2.5
  text-sm
  text-gray-700
  tracking-wide
  self-center
`

export const StyledTableCheckboxWrapper = tw.div`
  flex
  items-center
  h-full
`

export const TableCheckbox = tw.input`
  border-gray-400
  bg-gray-50
  shadow-sm
  border
  border-opacity-75
  text-yellow-500
  focus:ring
  focus:ring-transparent
`

export const HeaderCheckbox = tw.input`
  hidden
`

export const SortIcon = tw.svg`
  h-3
  w-3
  mt-0.5
  ml-0.5
  text-gray-400
`
