import PageLayout from '../../components/PageLayout'
import { useSelectWalletById } from '../../state/wallet/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Spinner, Button } from "@bitaccess/bitaccess-ui"
import {
  ArrowCircleDownIcon,
  ChevronLeftIcon,
  ArrowCircleUpIcon,
} from '@heroicons/react/outline'
import SendReceiveModal from '../../components/SendReceiveModal'
import TransactionHistoryTable from '../../components/TransactionHistoryTable'
import classNames from 'class-names'
import {
  useGetWalletBalanceByGroup,
  useSelectBalanceById,
} from '../../state/balance/hooks'
import { Link } from 'react-router-dom'
import {
  useSelectGroupIds,
  useSelectSelectedGroup,
  useUpdateUserSelectedGroup,
} from '../../state/group/hooks'
import BalanceBreakdownTable from '../../components/BalanceBreakdownTable'
import HideBalanceSwitch from '../../components/HideBalanceSwitch'
import BalanceAmount from '../../components/BalanceAmount'
import ReportsTable from '../../components/ReportsTable'
import ActivityTable from '../../components/ActivityTable'
import SelectBox from '../../components/SelectBox'
import WalletLabel from '../../components/WalletLabel'
import PermissionRestricted from '../../components/PermissionRestricted'
import Policy from '../../pages/Policy'
import { useIsUserAllowedToAccess } from '../../contexts/Permissions'

const tabs = [
  {
    name: 'Transaction History',
    route: `transactions`,
    permissions: ['view:wallet-transactions'],
  },
  {
    name: 'Balance Breakdown',
    route: `balance-breakdown`,
    permissions: ['view:wallet-address'],
  },
  { name: 'Reports', route: `reports`, permissions: ['export:wallet'] },
  { name: 'Activity Log', route: `activity` },
  { name: 'Policy', route: `policy` },
]

export default function WalletDetail() {
  const { walletId, groupId, tab } = useParams<{
    groupId: string
    walletId: string
    tab: string
  }>()
  const history = useHistory()
  const selectedGroup = useSelectSelectedGroup()
  const urlPrefix = `/wallets/${selectedGroup}/${walletId}`
  const [isSend, setIsSend] = useState<boolean | undefined>(undefined)
  const [isOpen, setOpen] = useState(false)
  const balance = useSelectBalanceById(walletId)
  const updateSelectedGroup = useUpdateUserSelectedGroup()
  const groupIds = useSelectGroupIds()
  const getBalancesByGroup = useGetWalletBalanceByGroup()
  const wallet = useSelectWalletById(walletId)
  const isUserAllowedToAccess = useIsUserAllowedToAccess()
  const allowedToViewTabs = tabs.filter((tab) =>
    isUserAllowedToAccess(tab.permissions ?? []),
  )
  const routerTab = useMemo(
    () => allowedToViewTabs.find((t: any) => t.route === tab),
    [tab, allowedToViewTabs],
  )
  const [selectedTab, setSelectedTab] = useState(
    routerTab?.name || allowedToViewTabs[0].name,
  )
  const handleModal = (isSend: boolean) => {
    setIsSend(isSend)
    setOpen(true)
  }

  useEffect(() => {
    if (groupId !== selectedGroup && groupId && groupIds?.includes(groupId)) {
      updateSelectedGroup(groupId)
    }
  }, [])

  useEffect(() => {
    const handleGetBalances = async () => {
      await getBalancesByGroup(groupId)
    }
    handleGetBalances()
  }, [])

  useEffect(() => {
    if (
      (!groupId && selectedGroup) ||
      (!groupIds?.includes(groupId) && selectedGroup)
    ) {
      history.replace({
        pathname: `/wallets/${selectedGroup}`,
        search: history.location.search,
      })
    }
  }, [groupId, `${groupIds}`, selectedGroup])

  const handleTabSelection = (value: string) => {
    const selectedTab = tabs.find((tab) => tab.name === value)
    history.push(selectedTab?.route ?? '')
    setSelectedTab(value)
  }

  return (
    <>
      <div className="self-start px-8 md:pl-16">
        <Link to={`/wallets/${groupId}`} className="no-underline">
          <Button
            type="custom"
            className="group mb-4 text-gray-500 hover:text-gray-900 transition-none shadow-none border-0 p-0 focus:ring-0"
            id="back-detail"
            Icon={
              <ChevronLeftIcon className="text-gray-500 group-hover:text-gray-900" />
            }
            text="Back"
          />
        </Link>
      </div>
      <PageLayout
        pageTitle={
          wallet?.asset && !isNaN(balance?.totalBalance) ? (
            <div className="mb-0">
              <div className="mt-1.5">
                <WalletLabel
                  wallet={wallet}
                  labelClassName="font-bold text-xl text-gray-900 m-0 p-0"
                />
              </div>
              <div className="flex flex-row flex-wrap items-center mt-3">
                <div className="pr-5">
                  <BalanceAmount
                    amount={balance?.totalBalance}
                    symbol={wallet?.asset?.symbol}
                    className="text-3xl relative text-gray-900 font-normal"
                  />
                </div>
              </div>
              <HideBalanceSwitch />
            </div>
          ) : (
            <Spinner id="wallet-detail-spinner" noLabel={true} />
          )
        }
        headerContent={
          <div className="flex flex-row items-center mt-0 md:mt-4 gap-2">
            {/* <Button
              className="w-44 text-base h-11 justify-center"
              onClick={() => handleModal(true)}
              Icon={<ArrowCircleUpIcon className="w-5 h-5 mr-2 text-white" />}
              id="send"
              type="secondary"
              text="Send"
            /> */}
            <PermissionRestricted perms={['view:wallet-address']}>
              <Button
                className="w-44 text-base h-11 justify-center"
                onClick={() => handleModal(false)}
                Icon={
                  <ArrowCircleDownIcon className="w-5 h-5 mr-2 text-white" />
                }
                id="receive"
                type="secondary"
                text="Receive"
              />
            </PermissionRestricted>
          </div>
        }
      >
        <div className="sm:hidden mb-3 mt-4">
          <h5 className="text-gray-600 font-semibold">Current Tab</h5>
          <SelectBox
            onSelect={(value) => handleTabSelection(value)}
            options={allowedToViewTabs.map((tab) => tab.name)}
          />
        </div>
        <div className="hidden sm:block mb-6 mt-2 border-b">
          <nav className="flex space-x-4" aria-label="Tabs">
            {allowedToViewTabs.map((tab) => {
              const current = tab.name === selectedTab
              return (
                <Link
                  key={tab.name}
                  as="div"
                  replace={true}
                  to={`${urlPrefix}/${tab.route}`}
                  className={classNames(
                    current
                      ? 'bg-white text-bitaccess-orange border-b border-bitaccess-orange'
                      : 'text-gray-500 hover:text-bitaccess-orange-dark',
                    'px-3 py-2 font-medium text-sm no-underline cursor-pointer',
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  {tab.name}
                </Link>
              )
            })}
          </nav>
        </div>
        {selectedTab === 'Transaction History' ? (
          <>
            <TransactionHistoryTable selectedWalletId={wallet?.id ?? ''} />
          </>
        ) : selectedTab === 'Reports' ? (
          <ReportsTable walletId={walletId} />
        ) : selectedTab === 'Balance Breakdown' ? (
          <BalanceBreakdownTable walletId={walletId} />
        ) : selectedTab === 'Activity Log' ? (
          <ActivityTable walletId={walletId} />
        ) : selectedTab === 'Policy' ? (
          <Policy walletId={walletId} />
        ) : (
          'None'
        )}
        <SendReceiveModal
          walletId={walletId}
          isOpen={isOpen}
          isSend={isSend}
          setOpen={setOpen}
        />
      </PageLayout>
    </>
  )
}
