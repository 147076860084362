import * as S from './styles'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { XIcon } from '@heroicons/react/outline'

/**
 * Modal UI component
 */
const Modal = (props: ModalProps): JSX.Element => {
  const {
    open,
    setOpen,
    showCloseButton,
    id,
    onClose,
    modalClassName,
    scrollBody,
  } = props
  const ConvertedFragment = Fragment as unknown as any

  const handleClose = () => {
    setOpen(!open)
    onClose()
  }

  return (
    <Transition.Root show={open} as={'div'}>
      <Dialog
        as="div"
        id={id}
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={handleClose}
      >
        <S.ModalContainer>
          <Transition.Child
            as={ConvertedFragment}
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={ConvertedFragment}
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <S.OuterContainer className={modalClassName}>
              <S.CloseContainer showCloseButton={showCloseButton}>
                <S.CloseButton type="button" onClick={handleClose}>
                  <span className="sr-only">Close</span>
                  <span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </S.CloseButton>
              </S.CloseContainer>
              <S.ContentContainer>
                <S.BodyContainer>
                  <S.BodyContent>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-center text-gray-900"
                    >
                      {props.title}
                    </Dialog.Title>
                    <S.BodySection scrollBody={scrollBody}>
                      {props.body}
                    </S.BodySection>
                  </S.BodyContent>
                </S.BodyContainer>
                {props.footer}
              </S.ContentContainer>
            </S.OuterContainer>
          </Transition.Child>
        </S.ModalContainer>
      </Dialog>
    </Transition.Root>
  )
}

export interface ModalProps {
  id: string
  open: boolean
  setOpen: (state: boolean) => void
  title: React.ReactNode
  body: React.ReactNode
  footer?: React.ReactNode
  showCloseButton: boolean
  onClose: () => void
  modalClassName?: string
  scrollBody: boolean
}

Modal.defaultProps = {
  showCloseButton: true,
  onClose: () => {
    return
  },
  modalClassName: '',
  scrollBody: true,
}

export default Modal
