import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { setAssetLoadingState, addSupportedAssets } from './actions'
import { AssetRedux } from './types'

export interface AssetState {
  supportedAssets: AssetRedux
  lastUpdated: number | undefined
  isLoading: boolean
}

const initialState: AssetState = {
  supportedAssets: {},
  lastUpdated: undefined,
  isLoading: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addSupportedAssets, (state, action) => {
      const assets = action.payload
      let newState = set(state, 'supportedAssets', assets)
      newState = set(newState, 'lastUpdated', Date.now())
      return newState
    })
    .addCase(setAssetLoadingState, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    }),
)
