import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@bitaccess/bitaccess-ui'
import GroupDropdown from '../GroupDropdown'

const WalletEmpty: FunctionComponent = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-center lg:mt-12">
        <div className="text-center space-y-8 lg:space-y-16 mt-6">
          <div>
            <h3 className="mt-2 text-xl font-bold text-gray-900">
              Don&rsquo;t have a wallet yet?
            </h3>
            <p className="mt-3 text-sm text-gray-600">
              You currently do not have any wallets.
              <br />
              Create or import your first wallet.
            </p>
            <div className="mt-6">
              <Link to={`/wallets/add`}>
                <Button
                  id="new-wallet-button"
                  type="custom"
                  text="Add Wallet"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-bold rounded-sm text-white bg-yellow-600 hover:bg-yellow-600/90 focus:outline-none"
                />
              </Link>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-gray-900">
              Member of an organization?
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              Contact an admin from your organization <br /> to request an
              invite.
            </p>
            <div className="mt-3">
              <GroupDropdown
                border={`border-2 border-gray-300`}
                textColor={`text-black`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletEmpty
