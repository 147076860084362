import { FunctionComponent, useEffect, ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@bitaccess/bitaccess-ui'
import {
  useGetNewWalletData,
  useUpdateNewWalletData,
  useUpdateSetupStep,
  useCreateWatchOnlyWallet,
} from '../../state/wallet/hooks'
import { NewWalletAttributes } from '../../state/wallet/types'

const NewWalletWatchOnly: FunctionComponent = () => {
  const updateNewWalletData = useUpdateNewWalletData()
  const updateSetupStep = useUpdateSetupStep()
  const newWalletData = useGetNewWalletData()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [detailsBody, setDetailsBody] =
    useState<NewWalletAttributes>(newWalletData)
  const createWatchOnly = useCreateWatchOnlyWallet()

  const detailsDataChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target
    setDetailsBody({ ...detailsBody, [name]: value })
  }

  const handleCreateWatchOnlyWallet = () => {
    setIsValid(false)
    createWatchOnly(detailsBody)
      .catch((err) => {
        console.log('Unable to create wallet', err)
      })
      .finally(() => {
        setIsValid(true)
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    updateNewWalletData(detailsBody)
    return () => {
      abortController.abort()
    }
  }, [detailsBody])

  useEffect(() => {
    if (detailsBody.hdKey) {
      setIsValid(true)
    }
  }, [detailsBody])

  useEffect(() => {
    const defaultValues: NewWalletAttributes = {
      addressType: 'p2wpkh',
    }
    setDetailsBody({ ...detailsBody, ...defaultValues })
  }, [])

  return (
    <div className="w-full">
      <form className="space-y-8">
        <div className="flex space-y-8 items-center justify-center">
          <div className="flex flex-col pt-8 w-[95%] md:w-[70%] lg:w-[45%] xl:w-[35%] items-center justify-center">
            <div className="w-full text-center space-y-5">
              <h3 className="text-xl leading-6 font-bold text-gray-900">
                Enter Wallet Details
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Enter your wallet details to create your watch-only wallet
              </p>
            </div>
            <div className="w-full mt-6 space-y-4 mb-10">
              <div>
                <label
                  htmlFor="wallet-label"
                  className="block text-sm font-medium text-gray-700"
                >
                  Public Address
                </label>
                <div className="mt-1">
                  <textarea
                    name="hdKey"
                    id="wallet-watchonly-wallet-xpub"
                    value={detailsBody.hdKey || ''}
                    onChange={(e) => {
                      setIsValid(e.target.value.trim() !== '')
                      detailsDataChangeHandler(e)
                    }}
                    onBlur={(e) => {
                      setIsValid(e.target.value.trim() !== '')
                      detailsDataChangeHandler(e)
                    }}
                    className="w-full h-32 md:h-24 md:tracking-wider shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="wallet-group"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Type
                </label>
                <div className="mt-1">
                  <select
                    id="wallet-group"
                    name="addressType"
                    value={detailsBody.addressType}
                    onChange={detailsDataChangeHandler}
                    onBlur={detailsDataChangeHandler}
                    className="shadow-sm focus:outline-none focus:ring-1 focus:ring-yellow-600 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="p2wpkh">Native Segwit</option>
                    <option value="p2sh-p2wpkh">Wrapped Segwit</option>
                    <option value="p2pkh">Legacy</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-center space-x-6 pt-10">
                <Link to={`/wallets`}>
                  <Button
                    id="new-wallet-cancel-button"
                    type="custom"
                    text="Cancel"
                    onClick={() => {
                      updateNewWalletData({})
                      updateSetupStep(1)
                    }}
                    className="inline-flex bg-white items-center px-4 py-2 border shadow-sm text-sm font-bold rounded-sm text-gray-700 hover:bg-gray-50 focus:outline-none"
                  />
                </Link>
                <Button
                  id="new-wallet-continue-button"
                  type="custom"
                  disabled={!isValid}
                  onClick={() => {
                    handleCreateWatchOnlyWallet()
                  }}
                  text="Create"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-bold rounded-sm text-white bg-yellow-600 hover:bg-yellow-600/90 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewWalletWatchOnly
