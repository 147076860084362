import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { multipleSet } from '../../utils'
import { addBalances, setLoadingBalances } from './actions'
import { AccountingBalanceRedux } from './types'

export interface BalanceState {
  balances: AccountingBalanceRedux
  isLoading: boolean
  lastUpdated: number | undefined
}

const initialState: BalanceState = {
  balances: {},
  isLoading: false,
  lastUpdated: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addBalances, (state, action) => {
      const balances = action.payload
      const newState = multipleSet(
        [
          { field: 'balances', value: balances },
          { field: 'lastUpdated', value: Date.now() },
        ],
        state,
      )
      return newState
    })
    .addCase(setLoadingBalances, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    }),
)
