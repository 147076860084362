import { fetchPolicyByWallet } from '../../services/GringottsClient'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { setLoadingPolicy } from './actions'
import { useGetAccessToken } from '../user/hooks'
import useToast from '../../services/toast'
import { Policy as IPolicy } from '../policy/types'
import { AppState } from '..'

export function useGetWalletPolicy(): ({
  walletId,
}: {
  walletId: string
}) => Promise<IPolicy | void> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useDispatch()
  return useCallback(
    async ({ walletId }: { walletId: string }) => {
      const accessToken = await getAccessToken()
      try {
        const policy = await fetchPolicyByWallet(accessToken, walletId)
        dispatch(setLoadingPolicy(false))
        return policy
      } catch (err) {
        console.log('err', err)
        dispatch(setLoadingPolicy(false))
        //toast('error', `Unable to fetch policy. Please try again.`)
      }
    },
    [dispatch, getAccessToken],
  )
}

export function useSelectIsLoadingPolicy(): boolean {
  const isLoading = useSelector((state: AppState) => state.policy.isLoading)
  return isLoading
}
