import { createReducer } from '@reduxjs/toolkit'
import set from 'immutable-set'
import { multipleSet } from '../../utils'
import { addLogs, loadingActivity } from './actions'

export interface ActivityState {
  logs: {
    [id: string]: any
  }
  page: number
  total: number
  isLoading: boolean
  limit: number
}

const initialState: ActivityState = {
  logs: {},
  isLoading: false,
  page: 1,
  total: 0,
  limit: 50,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addLogs, (state, action) => {
      const { logs, page, total, limit } = action.payload
      const updatedState = multipleSet(
        [
          {
            field: `logs`,
            value: logs,
            options: { safe: true },
          },
          {
            field: 'total',
            value: total,
          },
          {
            field: 'limit',
            value: limit,
          },
          {
            field: 'page',
            value: page,
          },
        ],
        state,
      )
      return updatedState
    })
    .addCase(loadingActivity, (state, action) => {
      const newState = set(state, 'isLoading', action.payload)
      return newState
    }),
)
