import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { Button } from '@bitaccess/bitaccess-ui'
import { PlusIcon, UserCircleIcon } from '@heroicons/react/solid'
import {
  useUpdateUserSelectedGroup,
  useSelectSelectedGroup,
  useSelectGroupsArray,
  useIsLoadingGroups,
  useIsPersonalSelectedGroup,
} from '../../state/group/hooks'
import { Dropdown, DropdownType } from '../Dropdown'
import { useGetUserWallets } from '../../state/wallet/hooks'
import { GroupAttributes } from '../../state/group/types'
import DropdownSearch from '../DropdownSearch'
import SelectedRow from '../SelectedRow'
import { useSelectNetwork } from '../../state/application/hooks'

export default function GroupDrowndown({
  border,
  textColor,
}: {
  border?: string
  textColor?: string
}) {
  const selectedGroup = useSelectSelectedGroup()
  const groups = useSelectGroupsArray()
  const getGroupsWallets = useGetUserWallets()
  const isLoading = useIsLoadingGroups()
  const updateUserSelectedGroup = useUpdateUserSelectedGroup()
  const dropdownRef = useRef<any>()
  const selectedNetwork = useSelectNetwork()
  const setShowDropdownList = (value: boolean) => dropdownRef.current(value)
  const personalGroup = useIsPersonalSelectedGroup()
  const { user } = useAuth0()
  const history = useHistory()

  const handleDontHaveSelectedGroup = useCallback(() => {
    const firstGroup = groups[0]
    if (!selectedGroup && firstGroup) {
      updateUserSelectedGroup(firstGroup?.name)
    }
  }, [`${groups}`, selectedGroup])

  const handleGetGroupsWallets = useCallback(async () => {
    if (!selectedGroup) return
    await getGroupsWallets([selectedGroup])
    return
  }, [selectedGroup, selectedNetwork])

  useEffect(() => {
    handleDontHaveSelectedGroup()
  }, [])

  useEffect(() => {
    handleDontHaveSelectedGroup()
  }, [`${groups}`])

  useEffect(() => {
    handleGetGroupsWallets()
  }, [handleGetGroupsWallets])

  const handleOnSelectSearchResult = (value: string) => {
    updateUserSelectedGroup(value)
    setShowDropdownList(false)
  }

  const handlePersonalGroupSelection = () => {
    const personalGroupName = `user|${user?.sub}`
    updateUserSelectedGroup(personalGroupName)
    history.replace(`/wallets`)
  }

  const ItemComponent = ({
    item,
    selected,
  }: {
    item: any
    selected: boolean
  }) => {
    return (
      <Link className="no-underline" to={`/wallets/${item?.name}`}>
        <SelectedRow value={item?.name} selected={selected} />
      </Link>
    )
  }

  return (
    <div className="relative z-10">
      <Dropdown
        id="group-settings"
        dropdownType={DropdownType.TEXT}
        text={
          !selectedGroup || (selectedGroup && personalGroup)
            ? 'Personal'
            : selectedGroup && !isLoading
            ? selectedGroup
            : 'Loading...'
        }
        extraStyles="text-white text-sm hover:bg-white opacity:100 hover:bg-opacity-10 px-3 transition-colors duration-300"
        ref={dropdownRef}
      >
        <Menu>
          <div className="text-center">
            <Menu.Items static>
              <Menu.Item>
                <a
                  onClick={() => handlePersonalGroupSelection()}
                  className={`${
                    personalGroup
                      ? 'text-yellow-900 bg-yellow-100'
                      : 'text-gray-900'
                  } group flex items-center px-4 py-2 text-gray-600 hover:text-yellow-900 hover:bg-yellow-100 no-underline cursor-pointer select-none relative py-2 pl-4 pr-4 border-b border-gray-100 flex justify-start`}
                >
                  <UserCircleIcon
                    className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span
                    className={`${
                      personalGroup ? 'font-medium' : 'font-normal'
                    } block truncate`}
                  >
                    Personal
                  </span>
                </a>
              </Menu.Item>
              <Menu.Item>
                <div className="flex p-1 border-t border-gray-200">
                  <span className="flex-1">
                    <p className="flex justify-start text-gray-700 text-sm px-4 py-1 font-semibold cursor-default">
                      GROUPS
                    </p>
                  </span>
                  {groups.length > 1 && (
                    <span>
                      <a
                        href="#"
                        className="group flex items-center px-4 pt-1 w-auto text-sm text-bitaccess-orange hover:text-bitaccess-orange-dark justify-end cursor-pointer"
                      >
                        <PlusIcon
                          className="mr-2 h-5 w-5 text-bitaccess-orange group-hover:text-bitaccess-orange-dark "
                          aria-hidden="true"
                        />
                        New Group
                      </a>
                    </span>
                  )}
                </div>
              </Menu.Item>
              {groups.length > 1 ? (
                <DropdownSearch
                  onSelect={handleOnSelectSearchResult}
                  idSelector={(item: GroupAttributes) => item.id}
                  valueSelector={(item: GroupAttributes) => item.name}
                  records={groups}
                  searchKeys={['name']}
                  inputPlaceholder="Group name"
                  inputLabel="Filter groups"
                  defaultValue={selectedGroup}
                  ItemComponent={ItemComponent}
                />
              ) : (
                <Menu.Item>
                  <div className="h-auto w-full">
                    <div className="flex items-center justify-center mx-auto mt-2 mb-6 w-11/12">
                      <div className="flex flex-col items-center justify-center space-y-4">
                        <div>
                          <p className="text-gray-600 text-sm">
                            Use groups to share wallets and manage an
                            organization with multiple users.
                          </p>
                        </div>
                        <div>
                          <Button
                            id="new-group-button"
                            type="custom"
                            text="Create your first group"
                            className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs font-bold rounded-sm text-white bg-yellow-600 hover:bg-yellow-600/90 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu.Item>
              )}
            </Menu.Items>
          </div>
        </Menu>
      </Dropdown>
    </div>
  )
}
