import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  addWallets,
  setHideWalletBalances,
  setLoadingWallets,
  setWalletSetupStep,
  setNewWalletData,
} from './actions'
import { useGetAccessToken } from '../user/hooks'
import {
  useAddWalletIdsToGroups,
  useGetSelectedGroupWalletIds,
  useCreateUserGroup,
  useUpdateUserSelectedGroup,
} from '../group/hooks'
import {
  fetchWallets,
  fetchWalletSummary,
  createWatchOnlyWallet,
} from '../../services/GringottsClient'
import { AppState, useThunkDispatch } from '../index'
import { useExtendAsset, useExtendAssetArray } from '../asset/hooks'
import useToast from '../../services/toast'
import { WalletAttributes, WalletRedux, NewWalletAttributes } from './types'
import { useSelectNetwork } from '../application/hooks'
import { useSelectSelectedGroup } from '../group/hooks'

const defaultArray: any[] = []

export function useGetUserWallets(): (
  groups: string[],
) => Promise<WalletAttributes[] | undefined> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useThunkDispatch()
  const addWalletIdsToGroups = useAddWalletIdsToGroups()
  return useCallback(
    async (groups: string[]) => {
      dispatch(setLoadingWallets(true))
      try {
        const accessToken = await getAccessToken()
        const wallets = await fetchWallets(accessToken, groups, groups[0])
        batch(async () => {
          const allWallets: WalletRedux = {}
          wallets.map((wallet) => {
            allWallets[wallet.id] = wallet
          })
          await dispatch(addWallets(allWallets))
          await addWalletIdsToGroups(wallets)
          await dispatch(setLoadingWallets(false))
        })
        return wallets
      } catch (err) {
        dispatch(setLoadingWallets(false))
        console.log('error fetching wallets', err)
      }
    },
    [addWalletIdsToGroups, dispatch, getAccessToken],
  )
}

export function useUpdateHideBalances(): (hide: boolean) => void {
  const dispatch = useDispatch()
  return useCallback(
    (hide: boolean) => {
      dispatch(setHideWalletBalances(hide))
    },
    [dispatch],
  )
}

export function useUpdateSetupStep(): (step: number) => void {
  const dispatch = useDispatch()
  return useCallback(
    (step: number) => {
      dispatch(setWalletSetupStep(step))
    },
    [dispatch],
  )
}

export function useGetWalletSetupStep(): number {
  const walletSetupStep = useSelector<
    AppState,
    AppState['wallet']['walletSetupStep']
  >((state) => state.wallet.walletSetupStep, shallowEqual)
  return walletSetupStep
}

export function useUpdateNewWalletData(): (
  payload: NewWalletAttributes,
) => void {
  const dispatch = useDispatch()
  return useCallback(
    (payload: NewWalletAttributes) => {
      dispatch(setNewWalletData(payload))
    },
    [dispatch],
  )
}

export function useGetNewWalletData(): NewWalletAttributes {
  const newWalletData = useSelector<
    AppState,
    AppState['wallet']['newWalletData']
  >((state) => state.wallet.newWalletData, shallowEqual)
  return newWalletData
}

export function useSelectHideWalletBalance(): boolean {
  const hideWalletBalance = useSelector<
    AppState,
    AppState['wallet']['hideWalletBalance']
  >((state) => state.wallet.hideWalletBalance, shallowEqual)
  return hideWalletBalance
}

export function useSelectWallets(): WalletRedux {
  const wallets = useSelector<AppState, AppState['wallet']['wallets']>(
    (state) => state.wallet.wallets,
    shallowEqual,
  )
  return wallets
}

export function useSelectAreWalletsLoading(): boolean {
  const isLoading = useSelector<AppState, AppState['wallet']['isLoading']>(
    (state) => state.wallet.isLoading,
  )
  return isLoading
}

export function useSelectWalletById(id: string): WalletAttributes | undefined {
  const wallets = useSelectWallets()
  const extendWallet = useExtendAsset()
  return useMemo(() => {
    const wallet = wallets[id]
    return wallet ? extendWallet(wallets[id], 'networkAssetId') : undefined
  }, [extendWallet, id, wallets])
}

export function useSelectWalletByIdCallback(): (
  id: string,
) => WalletAttributes | undefined {
  const wallets = useSelectWallets()
  const extendWallet = useExtendAsset()
  return useCallback(
    (id: string) => {
      const wallet = wallets[id]
      return wallet ? extendWallet(wallets[id], 'networkAssetId') : undefined
    },
    [extendWallet, wallets],
  )
}

export function useSelectWalletsBySelectedGroup(): WalletRedux {
  const wallets = useSelectWallets()
  const selectedGroupWalletIds = useGetSelectedGroupWalletIds()
  return _.pick(wallets, selectedGroupWalletIds)
}

export function useSelectedGroupWalletsArray(): WalletAttributes[] {
  const groupWallets = useSelectWalletsBySelectedGroup()
  const extendWalletArray = useExtendAssetArray()
  return useMemo(
    () => extendWalletArray(Object.values(groupWallets), 'networkAssetId'),
    [extendWalletArray, groupWallets],
  )
}

export function useSelectWalletByAssetAndGroup(
  assetId: number,
): WalletAttributes | undefined {
  const groupWallets = useSelectedGroupWalletsArray()
  return useMemo(
    () => groupWallets.find((wallet: any) => wallet.id === assetId),
    [assetId, groupWallets],
  )
}

export function useSelectWalletsArray(): WalletAttributes[] {
  const wallets = useSelectWallets()
  return Object.values(wallets) || defaultArray
}

export function useSelectWalletsExtendedArray(): WalletAttributes[] {
  const wallets = useSelectWalletsArray()
  const extendWalletArray = useExtendAssetArray()
  return useMemo(
    () => extendWalletArray(wallets, 'networkAssetId'),
    [extendWalletArray, wallets],
  )
}

export function useCreateWatchOnlyWallet(): (
  payload: NewWalletAttributes,
) => Promise<WalletAttributes | undefined> {
  const getAccessToken = useGetAccessToken()
  const dispatch = useThunkDispatch()
  const selectedGroup = useSelectSelectedGroup()
  const updateNewWalletData = useUpdateNewWalletData()
  const updateSetupStep = useUpdateSetupStep()
  const history = useHistory()
  // Create user group with auth0 user id
  const createUserGroup = useCreateUserGroup()
  const updateUserSelectedGroup = useUpdateUserSelectedGroup()
  return useCallback(async (payload: NewWalletAttributes) => {
    dispatch(setLoadingWallets(true))
    try {
      const accessToken = await getAccessToken()
      const userGroup = await createUserGroup()
      const watchOnlyRequestPayload: NewWalletAttributes = {
        group: userGroup.name,
        asset: payload.asset,
        hdKey: payload.hdKey,
        addressType: payload.addressType,
        networkType: payload.networkType,
        label: payload.label,
      }
      await createWatchOnlyWallet(
        accessToken,
        watchOnlyRequestPayload,
        selectedGroup,
      )
      updateNewWalletData({})
      updateSetupStep(1)
      updateUserSelectedGroup(userGroup?.name)
      history.replace(`/wallets`)
    } catch (err) {
      dispatch(setLoadingWallets(false))
      console.log('error creating watch only wallet', err)
      return Promise.reject(err)
    }
  }, [])
}
