import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { version } from '../../package.json'
import { useDispatch } from 'react-redux'

import application from './application/reducer'
import user from './user/reducer'
import transaction from './transaction/reducer'
import asset from './asset/reducer'
import wallet from './wallet/reducer'
import group from './group/reducer'
import balance from './balance/reducer'
import balanceBreakdown from './balanceBreakdown/reducer'
import report from './report/reducer'
import activity from './activity/reducer'
import policy from './policy/reducer'

const PERSISTED_KEYS: string[] = [
  'application',
  'activity',
  'user',
  'transaction',
  'asset',
  'group',
  'wallet',
  'balance',
  'report',
  'balanceBreakdown',
  'policy',
]

const store = configureStore({
  reducer: {
    activity,
    application,
    user,
    transaction,
    asset,
    wallet,
    group,
    balance,
    report,
    balanceBreakdown,
    policy,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    save({ states: PERSISTED_KEYS, namespace: `_v${version}` }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS, namespace: `_v${version}` }),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useThunkDispatch = () => useDispatch<AppDispatch>()
