import { useState, useMemo, useCallback, useEffect } from 'react'
import Modal from '../../components/Modal'
import SelectMenu from '../../components/SelectMenu'
import { InputGroup, CopyText, Button } from "@bitaccess/bitaccess-ui"
import QRCode from 'qrcode.react'
import { useSelectWalletById } from '../../state/wallet/hooks'
import { useSelectPrimaryAddressOfWallet } from '../../state/balance/hooks'
import { useGetWalletPolicy } from '../../state/policy/hooks'

export default function SendReceiveModal({
  isOpen,
  setOpen,
  isSend = true,
  walletId,
}: {
  isOpen: boolean
  setOpen: any
  isSend?: boolean
  walletId: string
}) {
  const [currentStep, setCurrentStep] = useState<0 | 1>(0)
  const [amount, setAmount] = useState<string>('')
  const [sendToAddress, setSendToAddress] = useState<string>('')
  const [whitelistedAddresses, setWhitelistedAddresses] = useState<any[]>([])
  const [isWhitelistEnabled, setIsWhitelistEnabled] = useState<boolean>(false)

  const isWhitelistedListEmpty = whitelistedAddresses.length === 0 ? true : false
  
  const getPolicyForWallet = useGetWalletPolicy()

  const handleGetRecentTransactions = useCallback(async () => {
    const res = await getPolicyForWallet({ walletId: walletId })
    if (res) {
      const { isWhitelistingEnabled, whitelistedDestinations } = res
      const addresses = whitelistedDestinations.map((address) => {
        return address.value
      })
      setWhitelistedAddresses([])
      // setWhitelistedAddresses(addresses)
      setIsWhitelistEnabled(isWhitelistingEnabled)
    }
  }, [walletId, setWhitelistedAddresses, getPolicyForWallet])

  useEffect(() => {
    handleGetRecentTransactions()
  }, [])

  const wallet = useSelectWalletById(walletId)
  const selectedAsset = useMemo(() => {
    return wallet?.asset
  }, [wallet])

  const primaryAddress = useSelectPrimaryAddressOfWallet(wallet?.id ?? '')

  const steps = useMemo(
    () => [
      // {
      //   title: isSend ? 'Send' : 'Receive',
      //   body: (
      //     <WalletListSearch
      //       inputGroupClass="px-0 relative -top-3"
      //       inputClass="h-10"
      //       onSelect={()=>console.log('walletselect')}
      //       // onSelect={handleSelectWallet}
      //       showLabel={false}
      //     />
      //   ),
      // },
      {
        title: `Receive ${selectedAsset?.symbol}`,
        body: (
          <>
            <div className="text-center mt-8 mb-6">
              <img
                src={selectedAsset?.logoUrl}
                className="h-20 w-20 text-center inline"
              />
            </div>
            <div className="py-2">
              <h3 className="m-0 p-0 text-gray-600 text-xl text-center font-normal">
                Only send {selectedAsset?.name} ({selectedAsset?.symbol}) to
                this address
              </h3>
              <h5 className="mt-0 p-0 text-gray-500 text-sm text-center font-normal">
                Sending any other cryptocurrency will result in permanent loss.
              </h5>
            </div>
            <div className="px-3">
              <Button
                className="w-full justify-center h-11"
                id="receive-warning-button"
                text={'I understand'}
                type="secondary"
                onClick={() => setCurrentStep(1)}
              />
            </div>
          </>
        ),
      },
      {
        title: isSend
          ? `Send ${selectedAsset?.symbol}`
          : `Receive ${selectedAsset?.symbol}`,
        body: isSend ? (
          <>
            <div className={`mb-4 ${isWhitelistEnabled ? "px-3 mt-2" : ""}`}>
              {isWhitelistEnabled ? (
                <SelectMenu
                  onClick={(e) => {
                    setSendToAddress(e)
                  }}
                  items={whitelistedAddresses}
                  id="send-receive-address"
                  buttonClass={`primary h-10 ${isWhitelistedListEmpty ? "cursor-not-allowed" : ""}`}
                  listBoxClass="w-full"
                  emptyListMessage="Whitelist is empty. Add a destination in the Policy tab"
                  label="Whitelisted Destination"
                />
              ) : (
                <InputGroup
                  id="send-receive-address"
                  value={amount}
                  placeHolder={`${selectedAsset?.symbol} Address`}
                  label="Address"
                  onChange={(e) => setAmount(e.target.value)}
                  onEnter={() => alert('enter')}
                  type="text"
                  inputStyles="h-10 rounded-md"
                />
              )}
            </div>
            <InputGroup
              id="send-receive-amount"
              value={amount}
              placeHolder="0.0"
              label="Amount"
              onChange={(e) => setAmount(e.target.value)}
              onEnter={() => alert('enter')}
              type="number"
              inputStyles="px-3 mb-0.5 mt-1.5 rounded-md h-10"
            />
            <div className="px-3 ">
              <Button
                className="w-full justify-center mt-5 h-11"
                id="send-receive-button"
                text={`Send ${selectedAsset?.symbol}`}
                type="secondary"
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <QRCode
                className="mx-auto mt-8 mb-8"
                size={200}
                value={primaryAddress}
                renderAs="svg"
              />
            </div>
            <div className="w-full mx-auto flex justify-center border border-gray-200 px-2 py-2 text-center">
              <CopyText id="copy-address-receive" toCopy={primaryAddress}>
                <span className="text-gray-600 text-normal">
                  {primaryAddress}
                </span>
              </CopyText>
            </div>
          </>
        ),
        footer: (
          <Button
            className="w-full justify-center mt-5 h-11"
            id="send-receive-button"
            text={'Done'}
            type="secondary"
            onClick={() => setOpen(false)}
          />
        ),
      },
    ],
    [
      amount,
      isSend,
      selectedAsset?.logoUrl,
      selectedAsset?.name,
      selectedAsset?.symbol,
      primaryAddress,
      setOpen,
      whitelistedAddresses,
      isWhitelistEnabled,
    ],
  )
  return (
    <>
      <Modal
        id="send-receive-modal"
        open={isOpen}
        setOpen={setOpen}
        scrollBody={false}
        modalClassName="w-4/5 sm:w-8/12 md:w-7/12 lg:w-6/12 xl:w-4/12"
        title={
          <>
            <span className="block text-center">
              {steps[currentStep].title}
            </span>
          </>
        }
        body={steps[currentStep].body}
        footer={steps[currentStep].footer}
      />
    </>
  )
}
