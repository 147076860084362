import axios, { Method } from 'axios'
import { GRINGOTTS_URL } from '../constants'
import {
  AccountingAttributes,
  AssetAttributes,
} from '@bitaccess/gringotts-common'
import { AccountingBalance } from '../state/balance/types'
import { BalanceBreakdownResult } from '../state/balanceBreakdown/types'
import { GroupAttributes } from '../state/group/types'
import { AccountingHistoryResult } from '../state/transaction/types'
import { WalletAttributes, NewWalletAttributes } from '../state/wallet/types'
import { assertType } from '../utils'
import { DocumentType, PeriodType } from '../state/report/hooks'

export interface Payport {
  address: string
  extraId?: string | null | undefined
  signerAddress?: string | undefined
}

const createApiInstance = (options: {
  method: Method
  dateString: string
  contentType: string
  accessKey: string
  group: string | undefined
}) => {
  const api = axios.create({
    method: options.method,
    baseURL: `${GRINGOTTS_URL}/`,
    headers: {
      'x-date': options.dateString,
      'content-type': options.contentType,
      Authorization: `bearer ${options.accessKey}`,
      'X-Requested-With': 'dans app',
      'x-ba-group': options.group || '',
    },
  })

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      // handle api response error
      return Promise.reject(error)
    },
  )

  return api
}

export const authedRequest = async (
  url: string,
  accessKey: string,
  options?: {
    method?: Method
    params?: any
    data?: any
    paramsSerializer?: any
  },
  group?: string,
) => {
  const requestMethod = options?.method || 'get'
  const dateString = new Date(Date.now()).toISOString()
  const contentType = 'application/json'
  const apiOptions = {
    method: requestMethod,
    dateString: dateString,
    contentType: contentType,
    accessKey: accessKey,
    group: group,
  }

  const api = createApiInstance(apiOptions)

  return await api
    .request({
      url,
      params: options?.params,
      data: options?.data,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response?.status >= 400) {
        throw Error(`${error.response.data.message}`)
      }
    })
}

export const fetchRecentTransactions = async (
  accessToken: string,
  walletId: string,
  options: {
    params: {
      fromDate?: Date
      toDate?: Date
      page?: number
      limit?: number
      networkType?: string
      reference?: string
      address?: string
      txHash?: string
      purpose?: string
      type?: string
    }
  },
  group: string,
): Promise<AccountingHistoryResult> => {
  return await authedRequest(
    `/api/v1/accounting/${walletId}/history`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchTransactionHistoryCSV = async (
  accessToken: string,
  walletId: string,
  options: {
    params: {
      fromDate: Date
      toDate: Date
    }
  },
  group: string,
): Promise<any> => {
  return await authedRequest(
    `/api/v1/accounting/${walletId}/historyCsv`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchWalletSummary = async (
  accessToken: string,
  walletId: string,
  options: {
    params: {
      date: string
    }
  },
  group?: string,
): Promise<any> => {
  return await authedRequest(
    `/api/v1/accounting/${walletId}/summary`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchWalletMonthlyStatements = async (
  accessToken: string,
  walletId: string,
  options: {
    params: {
      period: PeriodType
      documentType: DocumentType
    }
  } = {
    params: {
      period: 'monthly',
      documentType: 'pdf',
    },
  },
  group?: string,
): Promise<any> => {
  return await authedRequest(
    `/api/v1/accounting/${walletId}/monthly-statements`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchArbitaryTransaction = async (
  accessToken: string,
  orgId: string,
  assetSymbol: string,
  txId: string,
  payport: Payport,
  group?: string,
) => {
  return await authedRequest(
    `/api/v1/transaction/arbitrary/${orgId}/${assetSymbol}/${payport.address}/${txId}`,
    accessToken,
    {},
    group,
  )
}

export const fetchAllAssets = async (
  accessToken: string,
  group?: string,
): Promise<AssetAttributes[]> => {
  const response: AssetAttributes[] = await authedRequest(
    '/api/v1/asset',
    accessToken,
    {},
    group,
  )
  return response.map((a) => assertType(AssetAttributes, a))
}

export const fetchSupportedAssets = async (
  accessToken: string,
  group?: string,
): Promise<AssetAttributes[]> => {
  const response: AssetAttributes[] = await authedRequest(
    '/api/v1/asset',
    accessToken,
    {
      params: { onlySupported: true },
    },
    group,
  )
  return response?.map((a) => assertType(AssetAttributes, a))
}

export const fetchGroups = async (
  accessToken: string,
  group?: string,
): Promise<GroupAttributes[]> => {
  return await authedRequest('/api/v1/group', accessToken, {}, group)
}

export const fetchWallets = async (
  accessToken: string,
  groups: string[],
  group?: string,
): Promise<WalletAttributes[]> => {
  return await authedRequest(
    '/api/v1/wallet',
    accessToken,
    {
      params: {
        group: groups,
      },
    },
    group,
  )
}

export const fetchOrgsByGroups = async (accessToken: string) => {
  return await authedRequest('/api/v1/org/groups', accessToken)
}

export const fetchBalancesByGroup = async (
  accessToken: string,
  group: string,
): Promise<AccountingBalance[]> => {
  return await authedRequest(
    `/api/v1/accounting/balance/group/${group}`,
    accessToken,
    {},
    group,
  )
}

export const fetchWalletBalanceBreakdown = async (
  accessToken: string,
  walletId: string,
  options: {
    params: {
      networkType: string
      addressBreakdown: boolean
      returnUtxos: false
    }
  } = {
    params: {
      networkType: 'mainnet',
      addressBreakdown: true,
      returnUtxos: false,
    },
  },
  group: string,
): Promise<BalanceBreakdownResult> => {
  return await authedRequest(
    `/api/v1/balance/wallet/${walletId}`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchSherlockReports = async (
  accessToken: string,
  query: { jurisdiction: string },
  page: number,
  limit: number,
  group: string,
): Promise<{
  docs: any[]
  total: number
  page: number
  limit: number
  group: string
}> => {
  return await authedRequest(
    '/api/v1/sherlock',
    accessToken,
    {
      method: 'POST',
      data: {
        query,
        page,
        limit,
      },
    },
    group,
  )
}

export const fetchAuthProfile = async (accessToken: string, group: string) => {
  return await authedRequest(
    '/api/v1/user/profile',
    accessToken,
    {
      method: 'post',
    },
    group,
  )
}

export const fetchNetworkConfigs = async (
  accessToken: string,
  orgId: string,
  networkType = 'mainnet',
  group: string,
) => {
  return await authedRequest(
    `/api/v1/payments/config/${orgId}/${networkType}`,
    accessToken,
    {},
    group,
  )
}

export const fetchMultisigHotWalletPayport = async (
  accessToken: string,
  orgId: string,
  assetId: number,
  m: number,
  otherOrgs?: string[],
  options: { params: { networkType: string } } = {
    params: { networkType: 'mainnet' },
  },
  group?: string,
) => {
  const orgParam = otherOrgs ? [orgId, ...otherOrgs].sort().join('+') : orgId
  return await authedRequest(
    `/api/v1/payport/${orgParam}/${assetId}/hotwallet/multisig/${m}`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchSingleSigHotWalletPayport = async (
  accessToken: string,
  orgId: string,
  assetSymbol: number,
  options: { params: { networkType: string } } = {
    params: { networkType: 'mainnet' },
  },
  group: string,
) => {
  return await authedRequest(
    `/api/v1/payport/${orgId}/${assetSymbol}/hotwallet`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const reserveDepositPayport = async (
  accessToken: string,
  orgId: string,
  assetSymbol: number,
  hdKey: string,
  group: string,
) => {
  // eslint-disable-next-line prettier/prettier
  return await authedRequest(
    `/api/v1/payport`,
    accessToken,
    {
      method: 'post',
      data: {
        orgId,
        assetSymbol,
        paymentsConfig: {
          hdKey,
        },
        networkType: 'mainnet',
      },
    },
    group,
  )
}

// export const standardizeAddress = async (
//   networkSymbol: string,
//   address: string,
//   options?: { format: string | undefined },
// ) => {
//   return await gringottsLite.standardizeAddress(networkSymbol, address, options)
// }

export const fetchMasterPayport = async (
  accessToken: string,
  orgId: string,
  assetSymbol: string,
  options: { params: { networkType: string } } = {
    params: { networkType: 'mainnet' },
  },
  group: string,
) => {
  return await authedRequest(
    `/api/v1/payport/master/${orgId}/${assetSymbol}`,
    accessToken,
    {
      ...options,
    },
    group,
  )
}

export const fetchPolicyByWallet = async (
  accessToken: string,
  walletId: string,
) => {
  return await authedRequest(`/api/v1/policy/${walletId}`, accessToken, {})
}

export const createWatchOnlyWallet = async (
  accessToken: string,
  createWalletPayload: NewWalletAttributes,
  group: string,
) => {
  return await authedRequest(
    `/api/v1/wallet-setup/singlesig`,
    accessToken,
    {
      method: 'post',
      data: {
        group: createWalletPayload.group,
        asset: createWalletPayload.asset,
        networkType: createWalletPayload.networkType,
        label: createWalletPayload.label,
        paymentsConfig: {
          hdKey: createWalletPayload.hdKey,
          addressType: createWalletPayload.addressType,
          networkType: createWalletPayload.networkType,
        },
      },
    },
    group,
  )
}

export const createUserGroup = async (
  accessToken: string,
  userId: string,
  group: string,
) => {
  return await authedRequest(
    `/api/v1/group/${userId}`,
    accessToken,
    {
      method: 'post',
    },
    group,
  )
}
