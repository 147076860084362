import { FunctionComponent, Dispatch, SetStateAction, useState } from 'react'
import ReactGA from 'react-ga'
import {
  useGetNewWalletData,
  useUpdateSetupStep,
  useUpdateNewWalletData,
} from '../../state/wallet/hooks'
import { NewWalletAttributes } from '../../state/wallet/types'
import { useFeatureToggle } from '../../contexts/FeatureToggle'

const NewWalletType: FunctionComponent<{
  setActiveStep: Dispatch<SetStateAction<number>>
}> = ({ setActiveStep }) => {
  const [isFeatureEnabled] = useFeatureToggle()
  const newWalletData = useGetNewWalletData()
  const updateSetupStep = useUpdateSetupStep()
  const updateNewWalletData = useUpdateNewWalletData()
  const [isBasicClicked, setIsBasicClicked] = useState<boolean>(false)
  const [detailsBody, setDetailsBody] =
    useState<NewWalletAttributes>(newWalletData)
  const walletTypeDataChangeHandler = (type: 'basic' | 'watch') => {
    setDetailsBody({ ...detailsBody, ...{ walletType: type } })
    updateNewWalletData({ ...detailsBody, ...{ walletType: type } })
    setActiveStep(2)
    updateSetupStep(2)
  }
  const trackBasicWalletClick = () => {
    //  track basic wallet clicks
    ReactGA.event({
      category: 'Wallet UI Onboarding',
      action: 'Basic Wallet Click',
    })
    setIsBasicClicked(true)
  }

  return (
    <div className="w-full">
      <form className="space-y-8">
        <div className="flex space-y-8 items-center justify-center">
          <div className="pt-8 w-[95%] md:w-[70%] lg:w-[45%] xl:w-[35%]">
            <div className="w-full text-center">
              <h3 className="text-xl leading-6 font-bold text-gray-900">
                Select wallet type
              </h3>
            </div>
            <div
              onClick={() => {
                walletTypeDataChangeHandler('watch')
              }}
              className="mt-8 space-y-4 mb-4"
            >
              <div
                className={`${
                  detailsBody.walletType === 'watch'
                    ? 'bg-gray-300'
                    : 'bg-gray-100'
                } h-32 w-4/5 border-gray-100 text-xs text-gray-700 hover:bg-gray-200 mx-auto cursor-pointer`}
              >
                <div className="p-4 space-y-3">
                  <h3 className="text-base font-bold text-gray-800">
                    Watch-only wallet
                  </h3>
                  <p className="text-sm">
                    Only view and create addresses. It does not give you the
                    permission to send the coins in that wallet.
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                isFeatureEnabled('basic_wallet')
                  ? walletTypeDataChangeHandler('basic')
                  : trackBasicWalletClick()
              }}
              className="mt-3 space-y-4 mb-10"
            >
              <div
                className={`h-32 w-4/5 bg-gray-100 border-gray-100 text-xs text-gray-700 hover:bg-gray-200 mx-auto ${
                  isBasicClicked
                    ? 'cursor-default opacity-70'
                    : 'cursor-pointer'
                } mx-auto`}
              >
                <div className="p-4 space-y-3">
                  <h3 className="text-base font-bold text-gray-800">
                    Basic wallet
                  </h3>
                  <p className="text-sm">
                    A basic wallet where you have the backup keys.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewWalletType
